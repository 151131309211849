import WpBaseComponent from '../../wp-base-component';
import Component from 'vue-class-component';
import { IPhoneConfirmationService, PhoneConfirmationSymbols } from 'web-platform-core-ui';
import { CaptchaDataFormat } from 'web-platform-core-ui/src/PhoneConfirm/Captcha';

@Component({
	template: require('./wp-captcha.html'),
})
export default class WpCaptcha extends WpBaseComponent {
	private _phoneService!: IPhoneConfirmationService;
	private currentCode?: CaptchaDataFormat | null = null;

	protected imageUrl = '';
	protected modelCaptcha = '';
	protected loading = true;

	// #region Hooks
	created() {
		this._phoneService = this.GlobalService.Get<IPhoneConfirmationService>(PhoneConfirmationSymbols.PhoneConfirmationService);
	}
	async mounted() {
		this._notificationService.NotificationSended.Subscribe(async (_sender, e) => {
			if (e.Data.SystemProcessKey === 'CaptchaRefresh') await this.refreshCode();
		});
		await this.refreshCode();
		this.loading = false;
	}
	// #endregion

	get rules() {
		return [this.InputRules.NotEmpty];
	}

	async refreshCode() {
		this.loading = true;

		let code = await this._phoneService.GetCaptcha();

		this.loading = false;

		if (!code.Success || !code.Data) return;
		this.modelCaptcha = '';
		this.currentCode = code.Data;
		this.handleChange();

		this.imageUrl = code.Data.Path;
	}

	handleChange() {
		if (!this.currentCode) return;
		let captcha: CaptchaDataFormat = { ...this.currentCode, Value: this.modelCaptcha };
		this._phoneService.SetCurrentCaptcha(captcha);
	}
}
