export enum OrderStatus {
	Default = 0, // When order is not initialised
	Reserved = 1,
	Locked = 2,
	Paid = 3,
	Canceled = 4,
	Refunded = 5,
	Error = 6,
	PartialRefund = 7,
}
