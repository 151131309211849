import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	AppSettings,
	ITariffService,
	TariffSymbols,
	Tariff,
	Cart,
	RewriteCartItem,
	ITariffCapacityService,
	TariffSale,
	ITagService,
	TagSymbols,
	Tag,
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { Watch } from 'vue-property-decorator';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

export const wpTariffRewriteItemProp = Vue.extend({
	props: {
		tariff: Object as Prop<Tariff>,
		Cart: Object as Prop<Cart>,
		Card: String,
		Date: String,
		CardType: Number,
		CardName: String,
	},
});

@Component({
	template: require('./wp-tariff-rewrite-item.html'),
})
export default class WpTariffRewriteItem extends mixins<WpBaseComponent<WpTariffRewriteItemDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpTariffRewriteItemProp,
	Vue
) {
	_tariffService!: ITariffService;
	protected _tariffCapacityService!: ITariffCapacityService;
	private _tariffLoading: boolean = true;
	TariffAvailable: boolean = false;
	protected TariffSale: TariffSale = new TariffSale();
	Settings!: AppSettings;
	Price: number = 0;
	tags: Tag[] = [];

	get TariffLoading(): boolean {
		return this._tariffLoading;
	}
	set TariffLoading(value: boolean) {
		this._tariffLoading = value;
		this.$emit('LoadingChange', this.tariff.Id, this._tariffLoading);
	}

	created() {
		this._tariffCapacityService = this.GlobalService.Get<ITariffCapacityService>(TariffSymbols.TariffCapacityService);

		this._notificationService.NotificationSended.Subscribe(async (_sender, args) => {
			if (args.Data.SystemProcessKey == 'tagsLoaded') {
				let tagService = this.GlobalService.Get<ITagService>(TagSymbols.TagService);
				let tags = await tagService.GetTagsByTariff(this.tariff.Id);
				if (tags.Success && tags.Data) this.tags = tags.Data;
			}
		});
	}

	async mounted() {
		this.Settings = GlobalService.GetSettings<AppSettings>();

		this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);
		if (this.Date != null && this.Date != '') this.GetTariffPrice();
	}

	@Watch('Date')
	DateChanged(val: string, _oldVal: string) {
		if (val == null || val == '') return;

		this.GetTariffPrice();
	}

	AddToCart(): void {
		this.$emit(
			'addtocart',
			new RewriteCartItem(this.tariff, this.Date, this.Price, this.Card, undefined, undefined, this.CardType, this.CardName)
		);
	}

	async GetTariffPrice(): Promise<any> {
		this.TariffLoading = true;

		let result = await this._tariffService.GetTariffPrice(this.Date, this.tariff.Id);
		if (result.Success) {
			this.Price = result.Data;
			this.TariffAvailable = true;
		} else {
			this.TariffAvailable = false;
		}

		if (this.tariff.ShowLeftTicketCount) {
			let tariffSale = await this._tariffService.GetSingleTariffSalesAsync(this.Date, this.tariff);
			if (tariffSale.Success && tariffSale.Data) this.TariffSale = tariffSale.Data;
		}

		this.$emit('AvailableChange', this.tariff.Id, this.TariffAvailable);
		this.TariffLoading = false;
	}
}

export class WpTariffRewriteItemDictionary extends BaseUILocalizationDictionary {
	GenericToCart: string = 'В корзину';
	TariffUnavailableForThisDate: string = 'Тариф недоступен на эту дату';
	GenericItemsLeft: string = 'осталось';
	GenericItemsOf: string = 'из';
	NoMoreTickets: string = 'Закончились билеты';
}
