import Component from 'vue-class-component';
import WpBaseComponent from '../../../wp-base-component';
import { Prop, Watch } from 'vue-property-decorator';
import GlobalService, { ActionResult, AppSettings, ITagService, Tag, TagSymbols, Tariff } from 'web-platform-core-ui';
import { TariffItem } from '../tariff-item';

@Component({
	template: require('./wp-tags.html'),
})
export default class WpTags extends WpBaseComponent {
	@Prop() tariffs?: Array<TariffItem | Tariff>;
	@Watch('tariffs')
	tariffsChanged() {
		console.log(this.tariffs);
	}

	private _tagService!: ITagService;
	protected tags: Tag[] = [];
	Settings: AppSettings = GlobalService.GetSettings<AppSettings>();
	model: Tag[] = [];

	async mounted() {
		if (!this.tariffs || !this.tariffs?.length) return;

		this._tagService = this.GlobalService.Get(TagSymbols.TagService);

		let ar = this.tariffs
			.map((t) => {
				let tariff = <Tariff>t;
				let tariffItem = <TariffItem>t;
				if (tariff.IsRewritable) return tariff.Id;
				else if (tariffItem.Data) return tariffItem.Data.Id;
			})
			.filter((e) => e != undefined);

		let tariffIds = [...new Set(ar)];
		// Next line causes TS compiler to fucking sperg out because of missing undefined check WHICH OBVIOUSLY EXISTS
		// @ts-ignore
		if (tariffIds) await this._tagService.GetTagsByTariffs(tariffIds);

		let promisesTags: Promise<ActionResult<Tag[]>>[] = [];

		this.tariffs?.forEach((tar) => {
			let tariff = <Tariff>tar;
			let tariffItem = <TariffItem>tar;
			if (tariff.IsRewritable) promisesTags.push(this._tagService.GetTagsByTariff(tariff.Id));
			else if (tariffItem.Data) promisesTags.push(this._tagService.GetTagsByTariff(tariffItem.Data.Id));
		});

		let promisesResult = await Promise.all(promisesTags);

		promisesResult.forEach((result) => {
			if (result.Success && result.Data) {
				result.Data.forEach((tag) => {
					let find = this.tags.find((t) => t.Id === tag.Id);
					if (!find) this.tags.push(tag);
				});
			}
		});

		this._notificationService.SendSystemNotification('tagsLoaded');
	}

	eventHandlerChange() {
		this.$emit('input', this.model);
	}
}
