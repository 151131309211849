import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { ISaleService, SaleSymbols, BaseSystemLocalizationDictionary, MLogger, OrderSale, OrderSaleState, OrderStatus } from 'web-platform-core-ui';
import { Watch } from 'vue-property-decorator';
import { DefaultProps } from 'vue/types/options';

export const wpOrderTimerProps = Vue.extend({
	props: {
		OrderId: Number,
	},
});

@Component({
	template: require('./wp-order-timer.html'),
})
export default class WpOrderTimer extends mixins<WpBaseComponent<WpOrderTimerDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpOrderTimerProps,
	Vue
) {
	private _refreshOrderFunc: any;
	private _saleService!: ISaleService;
	protected _logger!: MLogger;

	LeftTime: number = -1;

	created(): void {
		this._logger = new MLogger();
	}

	async mounted() {
		this._saleService = await this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);

		if (this.OrderId == 0) return;

		let leftTimeResult;
		try {
			leftTimeResult = await this._saleService.LeftTimeAsync(this.OrderId);
		} catch (e) {
			this._logger.say('Мы попытались получить оставшееся время, но получили ошибку:', 'error');
			console.error(e);
			return false;
		}

		if (leftTimeResult.Success && leftTimeResult.Data != null) {
			this.LeftTime = Math.round(leftTimeResult.Data?.asMinutes());

			if (this.LeftTime === 0) {
				if (this._refreshOrderFunc != null) clearInterval(this._refreshOrderFunc);
				this.$emit('Timeout');
				this.LeftTime = -1;
			}

			this.$emit('LeftTime', this.LeftTime);
		}
	}

	@Watch('OrderId')
	async OrderIdChanged() {
		if (this.OrderId == 0) return;
		if (this._saleService == null) this._saleService = this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);

		let getOrder = await this._saleService.GetOrderAsync(this.OrderId);
		let order = getOrder.Success && getOrder.Data ? getOrder.Data : undefined;

		let leftTimeResult = await this._saleService.LeftTimeAsync(this.OrderId);

		if (leftTimeResult.Success && leftTimeResult.Data != null) {
			this.LeftTime = Math.round(leftTimeResult.Data?.asMinutes());
			this.$emit('LeftTime', this.LeftTime);
		}

		// fix: https://redmine.moipass.ru/issues/13717
		// fix: https://redmine.moipass.ru/issues/13855
		// Order status is canceled or paid - no need to ask Time Left from the server every ten seconds
		if (order?.Status == OrderStatus.Reserved || order?.Status == OrderStatus.Locked) {
			let orderTimer = this;
			clearInterval(this._refreshOrderFunc);
			this._refreshOrderFunc = undefined;
			this._refreshOrderFunc = setInterval(async () => {
				if (orderTimer.OrderId == undefined || orderTimer.OrderId == 0) clearInterval(orderTimer._refreshOrderFunc);

				let leftTimeResult = await orderTimer._saleService.LeftTimeAsync(orderTimer.OrderId);

				if (leftTimeResult.Data != undefined) {
					orderTimer.LeftTime = Math.round(leftTimeResult.Data?.asMinutes());
					orderTimer.$emit('LeftTime', orderTimer.LeftTime);
				}

				if (orderTimer.LeftTime === 0) {
					clearInterval(orderTimer._refreshOrderFunc);
					orderTimer.$emit('Timeout');
					orderTimer.LeftTime = -1;
					orderTimer.$emit('LeftTime', orderTimer.LeftTime);
				}
			}, 10000);
		}
	}

	beforeDestroy() {
		clearInterval(this._refreshOrderFunc);
		this._refreshOrderFunc = undefined;
	}
}

export class WpOrderTimerDictionary extends BaseSystemLocalizationDictionary {
	// Nothing to translate yet...
}
