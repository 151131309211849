import { OrderStatus } from '../Sale';

export default class Order {
	Id: number = 0;
	UserPhone: string = '';
	UserName: string = '';
	Site: string = '';
	Organization: string = '';
	Date: Date = new Date();
	Status: OrderStatus = OrderStatus.Default;
	Sum: number = 0;
	RegistredDate?: string;
}
