import { injectable, inject } from 'inversify';

import UserPhoneConfirmBan from './user-phone-confirm-ban';
import IPhoneConfirmationProvider from './i-phone-confirmation-provider';

import { PhoneConfirmationSymbols } from './symbols';

import { ActionResult } from './../Common';

import { PhoneConfirmCode } from './';
import { IPhoneConfirmationService } from './';

import { IdentitySymbols } from '../Identity';
import { IContext } from '../Identity';

import { ProfileSymbols } from '../Profile';
import { IProfileProvider } from '../Profile';

import { CaptchaDataFormat, CaptchaSymbols } from './Captcha';
import { ICaptchaProvider } from './Captcha';

@injectable()
export default class PhoneConfirmationService implements IPhoneConfirmationService {
	private readonly _provider: IPhoneConfirmationProvider;
	private readonly _context: IContext;
	private readonly _profileProvider: IProfileProvider;
	private readonly _captchaProvider: ICaptchaProvider;

	constructor(
		@inject(PhoneConfirmationSymbols.PhoneConfirmationProvider) provider: IPhoneConfirmationProvider,
		@inject(IdentitySymbols.Context) context: IContext,
		@inject(ProfileSymbols.ProfileProvider) profileProvider: IProfileProvider,
		@inject(CaptchaSymbols.CaptchaProvider) captchaProvider: ICaptchaProvider
	) {
		this._provider = provider;
		this._context = context;
		this._profileProvider = profileProvider;
		this._captchaProvider = captchaProvider;
	}

	async GetCurrentBanAsync(): Promise<ActionResult<UserPhoneConfirmBan>> {
		return await this._provider.GetCurrentBanAsync();
	}
	async GetCurrentCodeAsync(): Promise<ActionResult<PhoneConfirmCode>> {
		return await this._provider.GetCurrentCodeAsync();
	}
	async SendCodeAsync(phone: string): Promise<ActionResult<PhoneConfirmCode>> {
		return await this._provider.SendCodeAsync(phone);
	}
	async ConfirmCodeAsync(code: string): Promise<ActionResult<PhoneConfirmCode>> {
		let confirmResult = await this._provider.ConfirmCodeAsync(code);

		if (
			confirmResult.Success &&
			this._context.CurrentIdentity != null &&
			this._context.CurrentIdentity.IsAuthentificated &&
			confirmResult.Data != null
		) {
			await this._profileProvider.SetPhoneAsync(confirmResult.Data.Phone);
			this._context.CurrentIdentity.Phone = confirmResult.Data.Phone;
		}

		return confirmResult;
	}
	async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {
		return await this._provider.CodeLeftTimeAsync(codeId);
	}
	async CancelCodeAsync(codeId: number): Promise<ActionResult<number>> {
		return await this._provider.CancelCodeAsync(codeId);
	}

	async GetCaptcha(): Promise<ActionResult<CaptchaDataFormat>> {
		return await this._captchaProvider.GetCode();
	}
	SetCurrentCaptcha(captcha: CaptchaDataFormat) {
		return this._captchaProvider.SetCurrentCaptcha(captcha);
	}
}
