import { injectable, inject } from 'inversify';

import IOrderService from './i-order-service';
import IOrderProvider from './i-order-provider';
import Order from './order';
import { OrderServiceSymbols } from './symbols';

import { ILocalizationService, LocalizationChangedEventArgs, LocalizationSymbols } from './../Localization';

import { ActionResult, FiltringResult } from './../Common';
import { OrderDetails, OrdersFilter } from '.';

@injectable()
export default class OrderService implements IOrderService {
	private readonly _provider: IOrderProvider;
	private readonly _localizationService: ILocalizationService;

	constructor(
		@inject(OrderServiceSymbols.OrderProvider) provider: IOrderProvider,
		@inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService
	) {
		this._provider = provider;
		this._localizationService = localizationService;
	}

	async GetOrderListAsync(filter: OrdersFilter): Promise<ActionResult<FiltringResult<Order>>> {
		return await this._provider.GetOrderListAsync(filter);
	}
	async GetOrderAsync(id: number): Promise<ActionResult<OrderDetails>> {
		return await this._provider.GetOrderAsync(id);
	}

	private LocalizationChange(sender: any, e: LocalizationChangedEventArgs) {}
}
