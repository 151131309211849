import Vue, { VueConstructor, PropOptions } from 'vue';
import { DefaultProps } from 'vue/types/options';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import {
	OrderSale,
	ISaleService,
	SaleSymbols,
	INotificationService,
	NotificationSymbols,
	OrderStatus,
	IMetricsService,
	MetricsSymbols,
	PaymentSystemType,
	TariffSeance,
	DiscountType,
} from 'web-platform-core-ui';
import { MetricsEvents } from '../../metrics-events-symbols';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import { __await } from 'tslib';
import { Brand, EcomActionField, EcomOrder, EcomProduct, EcomPurchase } from 'web-platform-core-ui/src/Metrics/i-ecom-data';

export const wpCompleteProps = Vue.extend({
	props: {
		OrderId: Number,
		SbpDialog: <PropOptions>{
			type: Boolean,
			default: false,
		},
	},
});

@Component({
	template: require('./wp-complete.html'),
})
export default class WpComplete extends mixins<WpBaseComponent<WpCompleteDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpCompleteProps,
	Vue
) {
	protected _refreshOrderFunc: any;
	protected _service!: ISaleService;
	protected _notificationService!: INotificationService;
	_metricsService!: IMetricsService;

	Order: OrderSale = new OrderSale();

	OrderLoading: boolean = true;
	CancelLoading: boolean = false;
	CancelDialog: boolean = false;
	BackLoading: boolean = false;
	ItemsPayments: Array<any> = [];
	ChangePaymentWindowActive = false;
	SelectedPaymentId: number = 0;
	WaitDialog: boolean = true;

	SignLink?: string = '';

	CardDiscountConditionError: boolean = false;

	timeoutBack?: ReturnType<typeof setTimeout>;

	async mounted() {
		this.OrderLoading = true;
		this.WaitDialog = !this.SbpDialog;
		this._service = await this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		this._metricsService.RegistrateEvent(MetricsEvents.WidgetReturnFromPayment, this.OrderId);
		await this.GetOrder();
		this.OrderLoading = false;
		this.BackLoading = false;
	}

	Back() {
		this._metricsService.RegistrateEvent(MetricsEvents.WidgetRestart, this.OrderId);
		this.$emit('Back');
	}

	Cancel() {
		this._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteWaitingCancel, this.OrderId);
		this.CancelDialog = false;
		this.CancelLoading = true;
		this.$emit('Cancel');
	}

	async GetOrder() {
		let order = await this._service.GetOrderAsync(this.OrderId);

		if (order.Success && order.Data != null) {
			this.Order = order.Data;
			let wpComplete = this;

			if (this.Order.Status == OrderStatus.Locked) {
				await this.SetPaymentsList();

				this._refreshOrderFunc = setInterval(async () => {
					let order = await wpComplete._service.GetOrderAsync(wpComplete.Order.Id);

					if (order.Success && order.Data != null) {
						wpComplete.Order = order.Data;

						if (wpComplete.Order.Status != OrderStatus.Locked) {
							clearInterval(wpComplete._refreshOrderFunc);

							if (wpComplete.Order.Status == OrderStatus.Paid) {
								let tmpEcomData: any = this.GetSuccsefullyPaidPurchase();

								wpComplete._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteSuccess, this.OrderId);
								wpComplete._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteSuccess, this.OrderId, tmpEcomData);
								let linkToOrder = await wpComplete._service.GetSignLinkToOrderAsync(this.Order.Id);
								this.SignLink = linkToOrder.Success && linkToOrder.Data != null ? linkToOrder.Data : '';
							} else {
								if (this.Order.DiscountProgramId != null) {
									let discountPrograms = await this._service.GetDiscountProgramsAsync();
									let discountProgram = discountPrograms.Data?.find((x) => x.Id == this.Order.DiscountProgramId);

									this.CardDiscountConditionError =
										discountProgram != null &&
										discountProgram.Conditions != null &&
										discountProgram.Conditions.length > 0 &&
										discountProgram.Conditions.some((x) => x.DiscountConditionType == 1);
								}

								wpComplete._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteCancel, this.OrderId);
							}

							wpComplete._notificationService.SendSystemNotification('SaleComplete');
							parent.postMessage({ status: 'completed', data: { order_id: this.OrderId } }, '*');
							this.WaitDialog = false;
						}
					} else {
						wpComplete._notificationService.Error('Ошибка', order.ErrorMessage ? order.ErrorMessage : 'Непредвиденная ошибка');
					}
				}, 5000);
			} else {
				if (this.Order.Status == OrderStatus.Paid) {
					let tmpEcomData: any = this.GetSuccsefullyPaidPurchase();

					this._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteSuccess, this.OrderId);
					this._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteSuccess, this.OrderId, tmpEcomData);
					let linkToOrder = await wpComplete._service.GetSignLinkToOrderAsync(this.Order.Id);
					this.SignLink = linkToOrder.Success && linkToOrder.Data != null ? linkToOrder.Data : '';
				} else {
					if (this.Order.DiscountProgramId != null) {
						let discountPrograms = await this._service.GetDiscountProgramsAsync();
						let discountProgram = discountPrograms.Data?.find((x) => x.Id == this.Order.DiscountProgramId);

						this.CardDiscountConditionError =
							discountProgram != null &&
							discountProgram.Conditions != null &&
							discountProgram.Conditions.length > 0 &&
							discountProgram.Conditions.some((x) => x.DiscountConditionType == 1);
					}

					this._metricsService.RegistrateEvent(MetricsEvents.WidgetCompleteCancel, this.OrderId);
				}

				this.timeoutBack = setTimeout(() => {
					this.Back();
				}, 10 * 60 * 1000);

				this._notificationService.SendSystemNotification('SaleComplete');
				this.WaitDialog = false;
			}
		} else {
			this.WaitDialog = false;
			this._notificationService.Error(this.Terms.GenericError, order.ErrorMessage ? order.ErrorMessage : this.Terms.InternalError);
		}
	}

	beforeDestroy() {
		if (this.timeoutBack) clearTimeout(this.timeoutBack);
	}

	/** Получение списка products для e-com метрики */
	GetOrderList(tmpList: EcomPurchase, coupon: string) {
		this.Order.Items.filter(
			(x) =>
				x.AdditionalInfo != null &&
				(x.AdditionalInfo.Type == 3 ||
					x.AdditionalInfo.Type == 4 ||
					x.AdditionalInfo.Type == 5 ||
					x.AdditionalInfo.Type == 6 ||
					x.AdditionalInfo.Type == 11 ||
					x.AdditionalInfo.Type == 1)
		).forEach((item) => {
			let seance =
				item.AdditionalInfo.TariffSeance != null
					? ({
							Disabled: false,
							SeanceId: item.AdditionalInfo.TariffSeanceId,
							SeanceValue: item.AdditionalInfo.TariffSeance,
					  } as TariffSeance)
					: undefined;

			tmpList.products.push({
				id: item.AdditionalInfo.TariffCode,
				name: item.ProductName,
				list: item.AdditionalInfo.GroupName,
				brand: Brand.ISDS,
				coupon: coupon,
				discount: item.Discount,
				price: item.TotalSum,
				quantity: 1,
				variant: EcomProduct.GetProductVariant(seance, item.AdditionalInfo.OptionName),
			});
		});
	}

	/** Данные по оплаченной покупке для e-com метрики */
	GetSuccsefullyPaidPurchase() {
		let result: EcomOrder = new EcomOrder();
		result.purchase = new EcomPurchase();
		result.purchase.actionField = new EcomActionField();
		result.purchase.actionField.id = this.Order.Code.toString();

		let coupon = '';
		if (this.Order.DiscountsList.length !== 0) {
			let promocode = this.Order.DiscountsList.find((x) => x.DiscountType == DiscountType.Code);
			if (promocode != null) coupon = promocode.Name;
		}

		this.GetOrderList(result.purchase, coupon);
		let tmpEcomData: any = {};
		tmpEcomData.GetEcomData = (eventId: Symbol): EcomOrder | null => {
			return result.purchase!.products!.length > 0 ? result : null;
		};
		return tmpEcomData;
	}

	Pay() {
		this.$emit('PayClick', this.SelectedPaymentId, this.ItemsPayments.find((x) => x.id == this.SelectedPaymentId).type);
	}

	async SetPaymentsList() {
		this.ItemsPayments = [];
		let payments = await this._service.GetPaymentsAsync(this.Order.Id);

		if (payments.Success && payments.Data != null) payments.Data = payments.Data.filter((x) => x.CanResendForm);
		else return;

		if (payments.Data.length > 0) {
			for (let i = 0; i < payments.Data.length; i++) {
				let paymentData = {};
				if (payments.Data[i].PaymentType == PaymentSystemType.SbpSberbank)
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'img', src: 'https://static.moipass.ru/img/sfp.png' },
						text: this.Terms.SbpPayment,
						type: payments.Data[i].PaymentType,
					};
				else if (payments.Data[i].PaymentType == PaymentSystemType.Account) {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'img', src: 'https://static.moipass.ru/img/sfp.png' },
						text: this.Terms.AccountPayment,
						type: payments.Data[i].PaymentType,
					};
				} else {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'icon', src: 'mdi-credit-card-outline' },
						text: `${this.Terms.CardPayment} - ${this.GetPaymentName(payments.Data[i].PaymentType)}`,
						type: payments.Data[i].PaymentType,
					};
				}

				this.ItemsPayments.push(paymentData);

				if (payments.Data[i].IsBasePayment) this.SelectedPaymentId = payments.Data[i].Id;
			}

			if (this.SelectedPaymentId == 0) this.SelectedPaymentId = payments.Data[0].Id;
		}
	}

	GetPaymentName(paymentType: PaymentSystemType) {
		switch (paymentType) {
			case PaymentSystemType.AlfaBank:
				return this.Terms.AlfaBank;
			case PaymentSystemType.Gazprombank:
				return this.Terms.Gazprombank;
			case PaymentSystemType.Kazkombank:
				return this.Terms.Kazkombank;
			case PaymentSystemType.PayOnline:
				return this.Terms.PayOnline;
			case PaymentSystemType.Payu:
				return this.Terms.Payu;
			case PaymentSystemType.RfiBank:
				return this.Terms.RfiBank;
			case PaymentSystemType.RosEuroBank:
				return this.Terms.RosEuroBank;
			case PaymentSystemType.Sberbank:
				return this.Terms.Sberbank;
			case PaymentSystemType.SovkomBank:
				return this.Terms.SovkomBank;
			case PaymentSystemType.Test:
				return this.Terms.Test;
			case PaymentSystemType.Yandex:
				return this.Terms.Yandex;
			case PaymentSystemType.Account:
				return this.Terms.Account;
		}

		return '';
	}

	SetBackLoading(backloading: boolean) {
		this.BackLoading = backloading;
	}
}

export class WpCompleteDictionary extends BaseUILocalizationDictionary {
	AwaitingResult: string = '';
	BackToServiceList: string = '';
	ThankYouForPurchase: string = '';
	ConfirmRefusalToWait: string = '';
	StartOver: string = '';
	FatalError: string = '';
	YourOrderNo: string = '';
	YourOrderStatusOK: string = '';
	YourOrderStatusCanceled: string = '';
	CardDiscountConditionError: string = '';
	CardPayment: string = 'Оплата картой';
	SbpPayment: string = 'Оплата через СБП';
	GenericPay: string = 'Оплатить';
	PayOrderTitle: string = 'Оплатить заказ №';
	OrderInfoLinkTitle: string = 'Просмотреть информацию по заказу, а также скачать билеты вы можете';
	OrderInfoLinkText: string = 'здесь';
	AccountPayment = 'Оплатить с лицевого счёта';
	ChoosePayment = '';
	ChangePaymentMethod = 'Изменить способ оплаты';
	BackToStore = 'Вернуться в магазин';

	Payu = 'Payu';
	Yandex = 'Яндекс';
	Sberbank = 'Сбербанк';
	RfiBank = 'РФИ банк';
	RosEuroBank = 'РосЕвроБанк';
	Gazprombank = 'Газпромбанк';
	SovkomBank = 'Совкомбанк';
	Kazkombank = 'Казкомерцбанк';
	Test = 'Тестовая';
	AlfaBank = 'Альфа-банк';
	PayOnline = 'Pay онлайн';
	Account = 'Лицевой счёт';
}
