import './wp-login-form.scss';
import Vue, { PropOptions } from 'vue';
import Component, { mixins } from 'vue-class-component';
import {
	INotificationService,
	NotificationSymbols,
	IdentityLoginState,
	ModifiedWebSaleUserIdentity,
	IdentitySymbols,
	ErrorTypes,
} from 'web-platform-core-ui';
import { IContext } from 'web-platform-core-ui';
import { Ref, Watch } from 'vue-property-decorator';
import WpBaseComponent from '../../wp-base-component';
import BaseUILocalizationDictionary from '../../Localization/base-ui-localization-terms';

export const wpLoginFormProps = Vue.extend({
	props: {
		Outlined: <PropOptions>{
			type: Boolean,
			default: false,
		},
	},
});

@Component({
	template: require('./wp-login-form.html'),
})
export default class WpLoginForm extends mixins<WpBaseComponent<WpLoginFormDictionary>>(WpBaseComponent, wpLoginFormProps, Vue) {
	protected _notificationService!: INotificationService;
	private _context?: ModifiedWebSaleUserIdentity;

	BtnLoading: boolean = false;
	Show: boolean = false;
	Action: string = '';
	Username: string = '';
	Password: string = '';
	RememberMe: boolean = false;
	AntiForgeryName: string = '';
	AntiForgeryValue: string = '';
	OrganizationId: string = '';
	SiteId: string = '';

	@Ref('LoginForm') LoginForm!: HTMLFormElement;

	get Rules() {
		return [(value: string) => !!value || this.Terms.FieldRequiredMessage];
	}

	ResetPassword() {
		IdentityLoginState.IsResetPassword = true;
		this.$emit('ResetPassword');
	}

	Registration() {
		IdentityLoginState.IsResetPassword = false;
		this.$emit('Registration');
	}

	@Watch('Username')
	UsernameChanged(): void {
		this.$nextTick(() => {
			// Remove spaces
			try {
				this.Username = this.Username.replace(/\s/, '');
			} catch (e) {
				//
			}
		});
	}

	@Watch('Password')
	PasswordChanged(): void {
		this.$nextTick(() => {
			// Remove spaces
			try {
				this.Password = this.Password.replace(/\s/, '');
			} catch (e) {
				//
			}
		});
	}

	async CheckForm(e: any): Promise<void> {
		if (this._context != null) e.preventDefault();

		if (!this.LoginForm.validate()) return;

		if (this._context != null) {
			this.BtnLoading = true;
			let result = await this._context.Login(this.Username.trim(), this.Password, this.RememberMe);

			this.BtnLoading = false;
			if (!result?.Success) {
				if (result.ErrorType == ErrorTypes.InvalidData) this._notificationService.Error('', this.Terms.InvalidUserNameOrPassword);
				else {
					this._notificationService.Error('', this.Terms.InternalError);
				}
			} else {
				this._notificationService.SendSystemNotification('EventUserAuthorized');
				this.LoginForm.reset();
				this.RememberMe = false;
				this.$emit('Authorized');
			}
		}
	}

	mounted(): void {
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context) as ModifiedWebSaleUserIdentity;
	}
}

export class WpLoginFormDictionary extends BaseUILocalizationDictionary {
	FieldRequiredMessage: string = '';
	UserNameLblText: string = '';
	PasswordLblText: string = '';
	RememberMeLblText: string = '';
	SignBtnText: string = '';
	ForgotPasswordLink: string = '';
	RegistrationLink: string = '';
	InvalidUserNameOrPassword: string = '';
}
