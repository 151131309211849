import { OrderStatus } from '../Sale';

export default class OrdersFilter {
	Page: number = 1;
	PageCount: number = 20;
	FromDate?: string;
	ToDate?: string;
	Status?: Array<OrderStatus>;
	ConnectionId?: number;
	OrderCode?: number;
}
