
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from 'web-platform-vuetify';
@Component
export default class LuzhAccountRemoval extends mixins(Vue, WpBaseComponent) {
	step1: string = require('./img/step1.png');
	step2: string = require('./img/step2.png');
	step3: string = require('./img/step3.png');
}
