import { injectable, inject } from 'inversify';
import { ITariffService } from './i-tariff-service';
import ActionResult from './../Common/action-result';
import ITariffProvider from './i-tariff-provider';
import { TariffSymbols } from './symbols';
import Tariff from './tariff';
import TariffSale from './tariff-sale';
import TariffsBundle from './tariffs-bundle';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import CardAcceptableTariffs from './card-acceptable-tariffs';
import { AppSettings } from '../Global/app-settings';
import GlobalService from '../Global/global-service';
import { ErrorTypes } from '../Common';

@injectable()
export default class TariffService implements ITariffService {
	private static _carNumbersStorage: Array<string> = [];

	protected readonly _settings: AppSettings;
	protected readonly _provider: ITariffProvider;
	protected readonly _context: IContext;

	protected _saleItems: Array<TariffSale> = [];
	private _tariffs: Tariff[] = [];

	constructor(@inject(TariffSymbols.TariffProvider) provider: ITariffProvider, @inject(IdentitySymbols.Context) context: IContext) {
		this._provider = provider;
		this._context = context;
		this._settings = GlobalService.GetSettings();

		try {
			let v = localStorage.getItem('wp-auto-numbers');
			if (v == null || v == '') return;

			TariffService._carNumbersStorage = JSON.parse(v) as Array<string>;
		} catch {}
	}

	async GetCardAcceptableTariffsAsync(card: string, cardType?: number): Promise<ActionResult<CardAcceptableTariffs>> {
		let userId: string = '';
		if (this._context.CurrentIdentity.IsAuthentificated) userId = this._context.CurrentIdentity.UserId;

		let result = await this._provider.GetCardAcceptableTariffsAsync(card, userId, cardType);
		return result;
	}

	// Find a tariff stored in a local cache-like variable. Only works after GetTariffsAsync(). VERY situational
	GetTariff(id: number) {
		return this._tariffs.find((t) => t.Id === id);
	}

	async GetTariffsAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
		let result = await this._provider.GetTariffsAsync<T>();

		if (result.Success && result.Data !== undefined && this._settings.GroupId != null)
			result.Data = result.Data.filter((x) => x.GroupId == this._settings.GroupId);

		// Store tariffs in a cache-like entity so it can be accessed anywhere. Mainly used by GetTariff()
		// This should only work once
		if (result.Data) this._tariffs = this._tariffs.concat(result.Data);
		return result;
	}

	async GetTariffsBundlesAsync(): Promise<ActionResult<Array<TariffsBundle>>> {
		let result = await this._provider.GetTariffsBundlesAsync();

		if (result.Success && result.Data !== undefined)
			result.Data = result.Data.filter((x) => x.Tariffs !== null && x.Tariffs.length > 0 && x.Tariffs.some((y) => !y.IsRewritable));

		if (result.Success && result.Data !== undefined && this._settings.GroupId != null && this._settings.GroupId != null)
			result.Data = result.Data.filter(
				(x) => x.Tariffs !== null && x.Tariffs.length > 0 && !x.Tariffs.some((y) => y.GroupId == null || y.GroupId != this._settings.GroupId)
			);

		return result;
	}

	async GetAvailableTariffsAsync(date: string): Promise<ActionResult<Array<Tariff>>> {
		let result = await this._provider.GetAvailableTariffsAsync(date);

		if (result.Success && result.Data !== undefined && result !== null) result.Data = result.Data.filter((x) => !x.IsRewritable);

		return result;
	}

	async GetTariffSalesAsync(date: string, tariffs: Array<number>): Promise<ActionResult<Array<TariffSale>>> {
		return await this._provider.GetTariffSalesAsync(date, tariffs);
	}

	async GetSingleTariffSalesAsync(date: string, tariff: Tariff, seanceId?: string): Promise<ActionResult<TariffSale>> {
		let sales = await this._provider.GetSingleTariffSalesAsync(date, tariff.Id, seanceId);

		let salesInArray = this._saleItems.find((x) => x.TariffId == tariff.Id && x.Date == date && x.SeanceId == seanceId);

		if (salesInArray != null) {
			if (sales.Data != null) salesInArray.Count = sales.Data.Count;
			return ActionResult.SuccessData(salesInArray);
		}

		if (!sales.Success) return sales;

		let result = new TariffSale();

		result.TariffId = tariff.Id;
		result.Quota = tariff.Quota;
		result.Date = date;
		result.SeanceId = seanceId;

		if (sales.Data != null) result.Count = sales.Data.Count;

		this._saleItems.push(result);

		return ActionResult.SuccessData(result);
	}

	async GetTariffPrice(date: string, tariffId: number, seanceId?: string): Promise<ActionResult> {
		return await this._provider.GetTariffPrice(date, tariffId, seanceId);
	}

	async RefreshSales(): Promise<void> {
		for (let i = 0; i < this._saleItems.length; i++) {
			let sale = this._saleItems[i];
			let sales = await this._provider.GetSingleTariffSalesAsync(sale.Date, sale.TariffId, sale.SeanceId);

			if (sales.Success && sales.Data != null) sale.Count = sales.Data.Count;
		}
	}

	// Новые методы - http://redmine.isd.su/issues/6843
	// Имплементация этих методов происходит в сервисе TariffServiceCached
	async GetTariffsAllPrices(id: number, date: string): Promise<ActionResult> {
		console.error('Этот метот переопределяется в tariff-service-cached.ts');
		return ActionResult.Failed(ErrorTypes.InternalError);
	}

	async GetTariffsAllCapacities(id: number, date: string): Promise<ActionResult<any>> {
		console.error('Этот метот переопределяется в tariff-service-cached.ts');
		return ActionResult.Failed(ErrorTypes.InternalError);
	}

	public GetCarNumbersFromStorage(): Array<string> {
		return TariffService._carNumbersStorage;
	}
	public SaveCarNumberInStorage(carNumbers: Array<string>): void {
		TariffService._carNumbersStorage = carNumbers;
		try {
			localStorage.setItem('wp-auto-numbers', JSON.stringify(TariffService._carNumbersStorage));
		} catch {}
	}

	public HandlingCarNumber(number: string) {
		let result = '';
		number = number.toUpperCase();
		for (let i = 0; i < number.length; i++) {
			switch (number.charAt(i)) {
				case 'А':
					result += 'A';
					break;
				case 'В':
					result += 'B';
					break;
				case 'Е':
					result += 'E';
					break;
				case 'К':
					result += 'K';
					break;
				case 'М':
					result += 'M';
					break;
				case 'Н':
					result += 'H';
					break;
				case 'Р':
					result += 'P';
					break;
				case 'О':
					result += 'O';
					break;
				case 'У':
					result += 'Y';
					break;
				case 'Х':
					result += 'X';
					break;
				case 'Т':
					result += 'T';
					break;
				case 'С':
					result += 'C';
					break;
				default:
					result += number.charAt(i);
			}
		}
		return result;
	}

	Cache: Array<any> = [];
}
