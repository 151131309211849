import ISaleService from './i-sale-service';
import { Cart, CartItemTypes } from './../Cart';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import { ActionResult, ErrorTypes } from './../Common';
import OrderSale from './order-sale';
import { injectable, inject } from 'inversify';
import ISaleProvider from './i-saler-provider';
import { SaleSymbols } from './symbols';
import ReservationItem from './reservation-item';
import ReservationData from './reservation-data';
import PaymentForm from './payment-form';
import { OrderStatus } from './order-status';
import OrderSaleState from './order-sale-state';
import { PersonSale, PersonificationApiOrderItem, LockOrderInfo, LoyaltyProgram, PaymentSystem, PaymentSystemType } from './';
import GlobalService from './..';
import { AppSettings } from './../Global/app-settings';
import { ConnectionType } from './../Global';
import { IDateHandler } from '../Utility';
import { DateHandlerSymbols } from '../Utility';
import DiscountProgram from './discount-program';
import { AccountReplenishmentCartItem } from '../Loyalty';
import ReservationResult from './reservation-result';

@injectable()
export default class SaleService implements ISaleService {
	protected _saleProvider: ISaleProvider;
	protected _context: IContext;
	protected _dateHandler: IDateHandler;

	constructor(
		@inject(SaleSymbols.SaleProvider) saleProvider: ISaleProvider,
		@inject(IdentitySymbols.Context) context: IContext,
		@inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler
	) {
		this._saleProvider = saleProvider;
		this._context = context;
		this._dateHandler = dateHandler;
	}
	async PayForAccountPaymentAsync(data: import('./pay-account-payment').default): Promise<ActionResult<any>> {
		return await this._saleProvider.PayForAccountPaymentAsync(data);
	}

	async GetSignLinkToOrderAsync(orderId: number): Promise<ActionResult<string>> {
		return await this._saleProvider.GetSignLinkToOrderAsync(orderId);
	}

	async ReservateAsync(cart: Cart, personId?: number): Promise<ReservationResult> {
		let settings = GlobalService.GetSettings<AppSettings>();
		let result: ReservationResult;

		if (
			settings.CurrentSite == undefined ||
			settings.CurrentSite.ConnectionType == ConnectionType.Tariff ||
			settings.CurrentSite.ConnectionType == ConnectionType.ClubISD
		) {
			result = await this.DefaultReservate(cart, personId);
		} else if (settings.CurrentSite.ConnectionType == ConnectionType.Arena) {
			result = await this.ArenaReservate(cart, personId);
		} else {
			result = await this.MarketReservate(cart);
		}

		if (result.Success && result.Data != null) {
			await this._saleProvider.SetOrderSaleStateAsync(result.Data.Id, OrderStatus.Reserved);
		}

		if (!result.Success && result.InvalidItem != null) {
			if (result.InvalidItem.Date != null)
				result.InvalidItem.Date = this._dateHandler.Parse(result.InvalidItem.Date.split('T')[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
			result.InvalidCartItem = cart.Items.find((x) => x.IsAssociatedReservationItem(result.InvalidItem!));
		}

		return result;
	}

	async SetAuthorizedUserToOrderAsync(orderId: number): Promise<ActionResult> {
		if (!this._context.CurrentIdentity.IsAuthentificated) return ActionResult.Failed(ErrorTypes.InternalError);

		if (this._context.CurrentIdentity.IsAuthentificated) {
			let setResult = await this._saleProvider.SetUser(this._context.CurrentIdentity.UserId, orderId);
			if (!setResult.Success) return setResult;
		}

		await this._saleProvider.SetUserOrderState(orderId);

		return ActionResult.Success();
	}

	async RemoveItemAsync(itemId: number): Promise<ActionResult> {
		return await this._saleProvider.RemoveItemAsync(itemId);
	}
	async CancelOrderAsync(id: number): Promise<ActionResult> {
		return await this._saleProvider.CancelOrderAsync(id);
	}
	async LeftTimeAsync(id: number): Promise<ActionResult<moment.Duration>> {
		return await this._saleProvider.LeftTimeAsync(id);
	}
	async GetOrderAsync(id: number): Promise<ActionResult<OrderSale>> {
		return await this._saleProvider.GetOrderAsync(id);
	}
	async LockOrderAsync(id: number): Promise<ActionResult> {
		let result = await this._saleProvider.LockOrderAsync(id);

		if (result.Success) {
			let settings = GlobalService.GetSettings<AppSettings>();

			parent.postMessage({ status: 'wp-order-state', data: { sessionid: settings.SessionId, state: 'open' } }, '*');

			await this._saleProvider.SetOrderSaleStateAsync(id, OrderStatus.Locked);
		}

		return result;
	}
	async GetPaymentFormAsync(id: number): Promise<ActionResult<PaymentForm>> {
		return await this._saleProvider.GetPaymentFormAsync(id);
	}
	async GetOrderSaleStateAsync(): Promise<ActionResult<OrderSaleState>> {
		return await this._saleProvider.GetOrderSaleStateAsync();
	}
	async ClearOrderSaleStateAsync(): Promise<ActionResult> {
		let settings = GlobalService.GetSettings<AppSettings>();

		parent.postMessage({ status: 'wp-order-state', data: { sessionid: settings.SessionId, state: '' } }, '*');

		return await this._saleProvider.ClearOrderSaleStateAsync();
	}
	async ApplyCodeToOrderAsync(orderId: number, code: string): Promise<ActionResult<OrderSale>> {
		return await this._saleProvider.ApplyCodeToOrderAsync(orderId, code);
	}
	async ClearOrderCodesAsync(orderId: number): Promise<ActionResult<OrderSale>> {
		return await this._saleProvider.ClearOrderCodesAsync(orderId);
	}
	async SetPersonificationAsync(personificationItem: PersonificationApiOrderItem): Promise<ActionResult<any>> {
		return await this._saleProvider.SetPersonificationAsync(personificationItem);
	}
	async GetPersonificationUserPersons(userId: string): Promise<ActionResult<Array<PersonSale>>> {
		return await this._saleProvider.GetPersonificationUserPersons(userId);
	}
	async LockOrderWithDataAsync(info: LockOrderInfo): Promise<ActionResult> {
		let result = await this._saleProvider.LockOrderWithDataAsync(info);

		if (result.Success) {
			await this._saleProvider.SetOrderSaleStateAsync(info.Id, OrderStatus.Locked);
		}

		return result;
	}
	async GetBalanceAsync(orderId: number): Promise<ActionResult> {
		return await this._saleProvider.GetBalanceAsync(orderId);
	}
	async ChargeBonusesAsync(orderId: number, bonuses: number): Promise<ActionResult> {
		return await this._saleProvider.ChargeBonusesAsync(orderId, bonuses);
	}
	async GetLoyaltyListAsync(): Promise<ActionResult<Array<LoyaltyProgram>>> {
		let list = await this._saleProvider.GetLoyaltyListAsync();

		if (list.Success && list.Data != null) list.Data = list.Data.filter((x) => x.Status != 0);

		return list;
	}
	async ApplyLoyaltyAsync(orderId: number, loyaltyId: number): Promise<ActionResult> {
		return await this._saleProvider.ApplyLoyaltyAsync(orderId, loyaltyId);
	}
	async GetDiscountProgramsAsync(): Promise<ActionResult<Array<DiscountProgram>>> {
		return await this._saleProvider.GetDiscountProgramsAsync();
	}
	async ChangeDiscountAsync(orderId: number, discountId: number): Promise<ActionResult<OrderSale>> {
		return await this._saleProvider.ChangeDiscountAsync(orderId, discountId);
	}
	async GetPaymentsAsync(orderId: number): Promise<ActionResult<Array<PaymentSystem>>> {
		let paymentsResponse = await this._saleProvider.GetPaymentsAsync(orderId);

		if (paymentsResponse.Success && paymentsResponse.Data != null)
			paymentsResponse.Data = paymentsResponse.Data.filter((payment) => payment.PaymentType != PaymentSystemType.ApiPayment);

		return paymentsResponse;
	}
	async GetSpecificPaymentFormAsync(id: number, paymentId: number): Promise<ActionResult<PaymentForm>> {
		return await this._saleProvider.GetSpecificPaymentFormAsync(id, paymentId);
	}

	protected async DefaultReservate(cart: Cart, personId?: number): Promise<ActionResult<OrderSale>> {
		let items = new Array<ReservationItem>();

		cart.Items.forEach((x) => {
			if (x.Type == CartItemTypes.AccountReplenishment) {
				let cartItem = x as AccountReplenishmentCartItem;
				if (cartItem.PersonId != personId) personId = cartItem.PersonId;
			}

			items = items.concat(x.GetReservationItem());
		});

		items.forEach((x) => {
			x.Date = this._dateHandler.Parse(x.Date, 'DD.MM.YYYY').Format('YYYY-MM-DD');
		});

		let data = new ReservationData();

		data.UtmLabels = GlobalService.GetSettings<AppSettings>().UtmLabels;
		data.Items = items;
		data.PersonId = personId;
		if (this._context.CurrentIdentity.IsAuthentificated) data.UserId = this._context.CurrentIdentity.UserId;

		let result = await this._saleProvider.ReservateAsync(data);

		return result;
	}

	protected async ArenaReservate(cart: Cart, personId?: number): Promise<ActionResult<OrderSale>> {
		if (cart.OrderId == null) return ActionResult.FailedData(ErrorTypes.InternalError);

		if (this._context.CurrentIdentity.IsAuthentificated) {
			let result = await this._saleProvider.SetUser(this._context.CurrentIdentity.UserId, cart.OrderId);

			return result;
		} else {
			let getORder = await this._saleProvider.GetOrderAsync(cart.OrderId);

			return getORder;
		}
	}

	protected async MarketReservate(cart: Cart): Promise<ActionResult<OrderSale>> {
		if (cart.Items.some((x) => x.Type == CartItemTypes.MarketBill)) {
			let items = new Array<ReservationItem>();

			cart.Items.forEach((x) => {
				items = items.concat(x.GetReservationItem());
			});

			let data = new ReservationData();

			data.UtmLabels = GlobalService.GetSettings<AppSettings>().UtmLabels;
			data.Items = items;

			let result = await this._saleProvider.ReservateAsync(data);

			return result;
		}

		if (cart.OrderId === undefined) return ActionResult.FailedData(ErrorTypes.InternalError);

		let result = await this._saleProvider.SetUser(this._context.CurrentIdentity.UserId, cart.OrderId);

		return result;
	}
}
