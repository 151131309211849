import { AccessToken, IIdentity, IdentityChangedEventArgs, OIDCStorageStore } from './';
import { IContext } from './';
import { WpEvent, WpEventHandler } from './../Common';
import { UserManager, User } from 'oidc-client';
import { AppSettings } from '../Global/app-settings';
import { injectable } from 'inversify';
import GlobalService from '../Global/global-service';
import { ILocalizationService, LocalizationSymbols } from '../Localization';

@injectable()
export default class WebSaleUserIdentity implements IIdentity, IContext {
	private _specificIdentity?: IIdentity;
	private _user?: User | null;
	private _manager?: UserManager;
	private _managerSettings: any;
	private _identityChanged: WpEventHandler<IdentityChangedEventArgs>;
	private _isNewAuth: boolean = true;
	private _store: OIDCStorageStore;

	IsInitialized: boolean = false;

	constructor(managerSettings: any, store: OIDCStorageStore, isNewAuth: boolean = true) {
		this._store = store;
		this._identityChanged = new WpEventHandler<IdentityChangedEventArgs>();
		this._managerSettings = managerSettings;
		this._isNewAuth = isNewAuth;
	}

	get AccessToken(): AccessToken {
		let accessToken = this._user?.access_token ?? '';
		let date = this._user != null ? new Date(this._user.expires_in) : new Date();
		return new AccessToken(accessToken, date);
		// Sometimes after build you can get this error
		// Uncaught TypeError: I is undefined
		// It is fixed by editing this file. And thats why you see this comment
		// This error sometimes links to other files in this project
		// And often happens when other user makes changes to this TFS repo
		// 2023-8-24 UPDATE:
		// Problem persists on GIT as well :(
	}

	get IsAuthentificated(): boolean {
		return this._user != null && this._user != undefined && !this._user.expired;
	}

	get Username(): string {
		return this._user?.profile?.preferred_username ?? '';
	}

	get UserId(): string {
		return this._user?.profile?.sub ?? '';
	}

	get Phone() {
		return this._user?.profile?.phone_number;
	}

	set Phone(val: string | undefined) {
		if (this._user != null && this._user.profile != null) {
			this._user.profile.phone_number = val;
		}
	}

	get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
		return this._identityChanged;
	}

	get CurrentIdentity(): IIdentity {
		if (this._specificIdentity != null) return this._specificIdentity;
		return this;
	}

	SignIn(): void {
		if (this._manager != null) this._manager.signinPopup();
		else Error('Manager not initialzed');
	}

	SignOut(): void {
		if (this._specificIdentity != null) this._specificIdentity = undefined;

		if (this._manager != null) this._manager.signoutPopup();
		else Error('Manager not initialzed');
	}

	ChangeIdentity(identity: IIdentity) {
		this._specificIdentity = identity;
		let args = new IdentityChangedEventArgs(this._specificIdentity);
		this._identityChanged.Send(this._specificIdentity, args);
	}

	async Refresh() {
		if (this._user != null && this._user.profile != null && this._manager != null)
			this._user = await this._manager.signinSilent({ access_token: this.AccessToken.Token });
	}

	public async Initialize(appSettings: AppSettings, globalService: GlobalService) {
		let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
		this._managerSettings.ui_locales = localizationService.CurrentLocalization;
		this._managerSettings.acr_values = ` siteid:${appSettings.SiteId} organizationId:${appSettings.CurrentSite?.OrganizationId}${
			this._isNewAuth ? ' newauth' : ''
		}`;
		this._manager = new UserManager(this._managerSettings);

		let identity = this;

		this._manager.events.addUserLoaded((user: User) => {
			identity._user = user;
			identity._store.SaveStorage(this.AccessToken.Token);
			let args = new IdentityChangedEventArgs(this);
			identity._identityChanged.Send(this, args);
		});
		this._manager.events.removeUserLoaded(() => {
			identity._user = null;
			identity._store.SaveStorage(this.AccessToken.Token);
		});
		this._manager.events.addUserSignedOut(() => {
			identity._user = null;
			identity._store.SaveStorage(this.AccessToken.Token);
		});
		this._manager.events.removeUserSignedOut(() => {
			identity._user = null;
			identity._store.SaveStorage(this.AccessToken.Token);
		});

		await this._store.RestoreStorage();
		this._user = await this._manager.getUser();

		if (this._user != null) {
			let args = new IdentityChangedEventArgs(identity);
			identity._identityChanged.Send(identity, args);
		}
		identity.IsInitialized = true;
	}
}
