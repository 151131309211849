
import { Ref } from 'vue-property-decorator';
import WpBaseComponent from '../../wp-base-component';
import Component from 'vue-class-component';
@Component
export default class WpCopylink extends WpBaseComponent {
	@Ref('link') link!: HTMLAnchorElement;

	click() {
		const TEXT = this.link.innerText;
		if (TEXT) {
			try {
				this.CopyToClipboard(TEXT);
			} catch (e) {
				console.error(e);
			}
		}
	}
}
