import { injectable, inject } from 'inversify';

import { OrderDetails, OrderProvider } from './../Order';
import { RestClient } from 'typed-rest-client';
import { ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols } from './../Identity';
import { IContext } from './../Identity';
import { AppSettings } from '../Global/app-settings';
import GlobalService from '..';

@injectable()
export default class LuzhParkingOrderProvider extends OrderProvider {
	protected _context!: IContext;
	protected _settings!: AppSettings;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		super(context);
		this._settings = GlobalService.GetSettings<AppSettings>();
	}

	async GetOrderAsync(id: number): Promise<ActionResult<OrderDetails>> {
		let client!: RestClient;
		if (this._context.CurrentIdentity.IsAuthentificated) {
			client = new RestClient('wp-app', this._settings.ServerUrl, [], {
				headers: {
					Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
					SessionId: this._settings.SessionId,
					moduleId: this._settings.SiteId,
				},
			});
		} else {
			client = new RestClient('wp-app', this._settings.ServerUrl, [], {
				headers: {
					SiteId: `${this._settings.SiteId}`,
					SessionId: this._settings.SessionId,
				},
			});
		}

		let response = await client.get<AjaxResult<OrderDetails>>(`/account/api/orders/${id}`);
		console.log(response);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData<OrderDetails>(ErrorTypes.InternalError);

		return ActionResult.SuccessData<OrderDetails>(response.result?.Data);
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
