import { injectable, inject } from 'inversify';

import { Order } from './../Order';
import { RestClient } from 'typed-rest-client';
import { FiltringResult, ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols } from './../Identity';
import { IContext } from './../Identity';
import { IDateHandler } from './../';
import { DateHandlerSymbols } from '../Utility';
import { OrderStatus } from '../Sale';
import LuzhnikiOrderProvider from './luzhniki-order-provider';

@injectable()
export default class LuzhnikiAccountBalanceProvider extends LuzhnikiOrderProvider {
	constructor(@inject(IdentitySymbols.Context) context: IContext, @inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler) {
		super(context, dateHandler);
	}

	async GetOrderListByTNodAsync(tnodId: number): Promise<ActionResult<FiltringResult<Order>>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
			},
		});

		let response = await client.get<Array<any>>(`/luzhniki/balance/history/?tnodid=${tnodId}`);

		if (response.result == null) return ActionResult.FailedData<FiltringResult<Order>>(ErrorTypes.InternalError);

		let result = new FiltringResult<Order>();

		if (response.result != null) {
			response.result.forEach((x: any) => {
				let order = new Order();

				order.Id = x.CashListId ?? 0;
				order.Date = this._dateHandler.ParseToDate(x.date, 'DD.MM.YYYY');
				order.Status = x.TypeOper == 1 || x.TypeOper == 5 ? OrderStatus.Refunded : OrderStatus.Paid;
				order.Site = x.comment;
				if (x.debet != 0) order.Sum = Number.parseFloat(x.debet) * -1;
				if (x.credit != 0) order.Sum = Number.parseFloat(x.credit);

				result.Entities.push(order);
			});
		}

		return ActionResult.SuccessData<FiltringResult<Order>>(result);
	}
}

class AjaxResult<T> {
	Result: string | number = '';
	Message: string = '';
	Content?: T;
	Data?: T;
}
