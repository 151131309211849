import ITariffCapacityService from './i-tariff-capacity-service';
import { injectable, inject } from 'inversify';
import ActionResult from './../Common/action-result';
import ITariffCapacityProvider from './i-tariff-capacity-provider';
import { TariffSymbols } from './symbols';
import { TariffCapacity } from '.';
import { MLogger } from '../Global/m-logger';
import { ErrorTypes } from '../Common/error-types';
import Tariff from './tariff';

@injectable()
export default class TariffCapacityService implements ITariffCapacityService {
	protected _capacityItems: Array<TariffCapacity> = [];
	protected _logger: MLogger = new MLogger();
	protected _cache: any = [];
	protected _processed: any = [];

	protected readonly _provider: ITariffCapacityProvider;

	constructor(@inject(TariffSymbols.TariffCapacityProvider) provider: ITariffCapacityProvider) {
		this._provider = provider;
	}

	async GetCapacityAsync(tariffId: number, date: string, seanceId: string): Promise<ActionResult<TariffCapacity>> {
		let capacity = await this._provider.GetCapacityAsync(tariffId, date, seanceId);

		if (capacity.Success && capacity.Data != null) {
			let capacityInArray = this._capacityItems.find(
				(x) => x.ZoneId == capacity.Data?.ZoneId && x.Date == date && x.SeanceId == capacity.Data?.SeanceId
			);
			if (capacityInArray != null) {
				capacityInArray.Count = capacity.Data.Count;
				return ActionResult.SuccessData(capacityInArray);
			} else {
				capacity.Data.TariffId = tariffId;
				capacity.Data.Date = date;
				this._capacityItems.push(capacity.Data);
			}
		}

		return capacity;
	}

	async RefreshCapacity(): Promise<void> {
		for (let i = 0; i < this._capacityItems.length; i++) {
			let capacity = this._capacityItems[i];
			let newCapacity = await await this._provider.GetCapacityAsync(capacity.TariffId, capacity.Date, capacity.SeanceId);

			if (newCapacity.Success && newCapacity.Data != null) capacity.Count = newCapacity.Data.Count;
		}
	}

	async GetTariffsAllCapacities(id: number, date: string): Promise<ActionResult> {
		console.error('Метод имплементирован в tariff-capacity-service-cached.ts');
		return ActionResult.Failed(ErrorTypes.InternalError);
	}

	async GetCapacitiesForAllTariffs(tariffs: Array<Tariff>, date: string): Promise<void> {
		console.error('Метод имплементирован в tariff-capacity-service-cached.ts');
	}
}
