import { injectable } from 'inversify';
import LoyaltyNotificationAddedEventArgs from './loyalty-notification-added-event-args';
import { WpEventHandler, WpEvent } from '../Common';
import { LoyaltyNotification } from '.';
import ILoyaltyNotificationProvider from './i-loyalty-notification-provider';
import { LoyaltyNotificationStatus } from './loyalty-notification-status';

// typeof check for in case we aren't in browser but in NodeJS enviroment
const _global = (typeof window !== 'undefined' ? window : global) as any;

@injectable()
export default class DefaultLoyaltyNotificationProvider implements ILoyaltyNotificationProvider {
	protected _notifications: Array<LoyaltyNotification> = [];

	protected _notificationAdded: WpEventHandler<LoyaltyNotificationAddedEventArgs>;

	get NotificationAdded(): WpEvent<LoyaltyNotificationAddedEventArgs> {
		return this._notificationAdded;
	}

	constructor() {
		this._notificationAdded = new WpEventHandler<LoyaltyNotificationAddedEventArgs>();

		try {
			this._notifications = wp_app_push_notifications;
		} catch {}

		_global.pushNotificationAdded = (data: string) => {
			try {
				let newNotification = JSON.parse(data) as LoyaltyNotification;

				if (newNotification == null) return;

				this._notifications.push(newNotification);

				this._notificationAdded.Send(this, new LoyaltyNotificationAddedEventArgs(newNotification));
			} catch (e) {}
		};
	}

	GetNotifications() {
		let result: Array<LoyaltyNotification> = [];

		try {
			this._notifications = wp_app_push_notifications;
		} catch {}

		this._notifications.forEach((x) => {
			result.push({
				Id: x.Id,
				Title: x.Title,
				NotificationDate: x.NotificationDate,
				Message: x.Message,
				Status: x.Status,
			});
		});

		return result;
	}

	CheckNotification(id: string) {
		let data = this._notifications.find((x) => x.Id == id);
		if (data == null) return;

		try {
			data.Status = LoyaltyNotificationStatus.Checked;
			checkPushNotification(id);
		} catch {}
	}
}

interface checkPushNotificationHandelr {
	(id: string): void;
}

declare var checkPushNotification: checkPushNotificationHandelr;
declare var wp_app_push_notifications: Array<LoyaltyNotification>;
