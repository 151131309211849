import IOrganiationService from './i-organization-service';
import { injectable, inject } from 'inversify';
import IOrganizationProvider from './i-organization-provider';
import { OrganizationSymbols } from './symbols';
import OrganizationInfo from './organization-info';
import { ActionResult } from './../Common';

@injectable()
export default class OrganizationService implements IOrganiationService {
	private _provider: IOrganizationProvider;

	constructor(@inject(OrganizationSymbols.OrganizationProvider) provider: IOrganizationProvider) {
		this._provider = provider;
	}

	async GetOrganizationInfoAsync(): Promise<ActionResult<OrganizationInfo>> {
		return await this._provider.GetOrganizationInfoAsync();
	}

	async GetOrganizationRulesAsync(): Promise<ActionResult<string>> {
		return await this._provider.GetOrganizationRulesAsync();
	}
}
