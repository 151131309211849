import Component from 'vue-class-component';
import {
	IdentitySymbols,
	IContext,
	ILoyaltyProvider,
	LoyaltySymbols,
	INotificationService,
	NotificationSymbols,
	LuzhnikFeedbackProvider,
} from 'web-platform-core-ui';
import { WpSettings } from 'web-platform-vuetify';

@Component({
	template: require('./luzh-settings.html'),
})
export class LuzhSettings extends WpSettings {
	RemoveDialog: boolean = false;

	created(): void {
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	}

	async RemoveAccount() {
		this.RemoveDialog = false;
		let provider = new LuzhnikFeedbackProvider(this._context);
		let result = await provider.SendAsync(
			'Удаление аккаунта',
			'Email',
			'Заявка на удаление учетной записи и связанной с ней программы лояльности',
			this._context.CurrentIdentity.Username,
			this._context.CurrentIdentity.Phone ?? ''
		);

		if (result.Success) {
			this._notificationService.Success('Отправка', 'Ваша заявка отправлена и будет рассмотрена в кратчайшие сроки');
			this.Form.reset();
		} else {
			this._notificationService.Error('Ошибка', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');
		}
	}
}
