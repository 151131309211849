import { inject, injectable } from 'inversify';
import { ActionResult, ErrorTypes } from '../../Common';
import ICaptchaProvider from './i-captcha-provider';
import { RestClient } from 'typed-rest-client';
import IContext from '../../Identity/i-context';
import { AppSettings } from '../../Global/app-settings';
import { IdentitySymbols } from '../../Identity/symbols';
import GlobalService from '../../Global/global-service';
import CaptchaDataFormat from './captcha-data-format';

@injectable()
export default class DefaultCaptchaProvider implements ICaptchaProvider {
	private _context: IContext;
	protected _settings: AppSettings;
	private _currentCaptcha?: CaptchaDataFormat;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this._context = context;
		this._settings = GlobalService.GetSettings();
	}

	async GetCode(): Promise<ActionResult<CaptchaDataFormat>> {
		let url = `/idsrv/phoneconfirmation/captcha/code`;

		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				moduleId: this._settings.SiteId,
				SessionId: this._settings.SessionId,
			},
		});

		let code = await client.get<CaptchaDataFormat>(url);
		if (code.result == null || code.statusCode != 200) return ActionResult.Failed(ErrorTypes.InternalError);
		return ActionResult.SuccessData(code.result);
	}

	GetCurrentCaptcha() {
		return this._currentCaptcha;
	}
	SetCurrentCaptcha(captcha: CaptchaDataFormat) {
		this._currentCaptcha = captcha;
	}
}
