import { IIdentity, IdentityChangedEventArgs } from './';
import { IContext } from './';
import { WpEvent } from './../Common';
import EmptyIdentity from './empty-identity';
import { AppSettings } from '..';

export default class EmptyContext implements IContext {
	IsInitialized: boolean = true;
	get CurrentIdentity(): IIdentity {
		return new EmptyIdentity();
	}

	get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
		throw new Error('Method not implemented.');
	}

	SignIn(): void {
		throw new Error('Method not implemented.');
	}
	SignOut(): void {
		throw new Error('Method not implemented.');
	}
	ChangeIdentity(identity: IIdentity): void {
		throw new Error('Method not implemented.');
	}

	async Initialize(appSettings: AppSettings): Promise<void> {}
}
