export namespace MediaCards {
	export enum MediaType {
		BarCode = 1,
		C240 = 2,
		C210 = 3,
		Magnetic = 4,
		Mifare = 5,
		Proximity = 6,
		MifareUltralight = 7,
		SkiCard = 8,
		Legic = 10,
		TagIt = 11,
		ThingMagicEPC = 15,
		BLUEPASS = 18,
		HID = 34,
		FanID = 35,
		PayPass = 36,
		FIFATicket = 37,
		MIR = 38,
		Troika = 39,
		MirHASH = 41,
	}
}

export default MediaCards;
