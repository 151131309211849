import { injectable, inject } from 'inversify';

import GlobalService from './../Global/global-service';
import {
	InstructorsPersonalCartItem,
	InstructorsPersonalLesson,
	InstructorsGroupLesson,
	InstructorsGroupCartItem,
	InstructorsBundlesCartItem,
	InstructorsBundlesGroupsLessons,
} from './../Instructors';
import {
	TariffCartItem,
	Tariff,
	TariffsBundle,
	RewriteCartItem,
	TariffSale,
	TariffSeance,
	TariffSymbols,
	TariffCapacity,
	ITariffCapacityService,
	ITariffService,
} from '../Tariffs';

import Cart from './cart';
import { CartItemTypes } from './cart-item-types';
import CartItem from './cart-item';
import ICartProvider from './i-cart-provider';
import ICartService from './i-cart-service';
import { CartSymbols } from './symbols';
import BundleCartItem from './../Tariffs/bundle-cart-item';

import { ArenaSeance, ArenaSector, ArenaTariff, ArenaTariffOption, ArenaSeat, ArenaCartItem, IArenaService } from './../Arena';

import { ArenaSymbols } from './../Arena/symbols'; // Investigate why ARENA symbols need to be imported like this. Possible circular import?

import {
	MarketSymbols,
	MarketTariffCartItem,
	MarketClient,
	MarketDateTimeRange,
	MarketRule,
	MarketEmployee,
	MarketBillCartItem,
	MarketBill,
	IMarketService,
} from '../Market';

import { NewContractCartItem, ContractClubTariffCartItem } from '../Club';
import { AccountReplenishmentCartItem } from '../Loyalty';
import { SyntechParkingCartItem } from '../Syntech';

@injectable()
export default class CartService implements ICartService {
	private _cart?: Cart;

	private _arenaService: IArenaService;
	private _provider: ICartProvider;
	private _marketService: IMarketService;
	private _tariffCapacityService: ITariffCapacityService;
	private _tariffService: ITariffService;

	constructor(
		@inject(CartSymbols.CartProvider) provider: ICartProvider,
		@inject(ArenaSymbols.ArenaService) arenaService: IArenaService,
		@inject(MarketSymbols.MarketService) marketService: IMarketService,
		@inject(TariffSymbols.TariffCapacityService) capacityService: ITariffCapacityService,
		@inject(TariffSymbols.TariffService) tariffService: ITariffService
	) {
		this._provider = provider;
		this._arenaService = arenaService;
		this._marketService = marketService;
		this._tariffCapacityService = capacityService;
		this._tariffService = tariffService;
	}

	async GetCurrentOrCreateCartAsync(): Promise<Cart> {
		if (this._cart == null) {
			let settings = GlobalService.GetSettings();

			let jsonCart = await this._provider.GetCurrentCartAsync();

			if (jsonCart !== null && jsonCart !== '') {
				try {
					let cart = jsonCart;

					if (cart._siteId != settings.SiteId) {
						throw Error('Existing cart for another site');
					}

					let cartInstance = new Cart(cart._siteId, cart._sessionId, this._arenaService, settings.CartMaxItems, cart._orderId);
					this._cart = cartInstance;

					let currentCart = this._cart;
					let items = cart._items as Array<any>;
					for (let i = 0; i < items.length; i++) {
						let x = items[i];
						let cartItem: CartItem;
						switch (x._type) {
							case CartItemTypes.SyntechParking:
								cartItem = this.CreateSyntechParkingCartItem(x, cartInstance);
								break;
							case CartItemTypes.AccountReplenishment:
								cartItem = this.CreateAccountReplenishmentCartItem(x, cartInstance);
								break;
							case CartItemTypes.ContractAdditionalService:
								cartItem = this.CreateContractClubTariffCartItem(x, cartInstance);
								break;
							case CartItemTypes.NewClubContract:
								cartItem = this.CreateNewContractCartItem(x, cartInstance);
								break;
							case CartItemTypes.ArenaTicket:
								cartItem = this.CreateArenaCartItem(x, cartInstance);
								break;
							case CartItemTypes.InstructorsGroupLesson:
								cartItem = this.CreateInstructorsGroupCartItem(x, cartInstance);
								break;
							case CartItemTypes.InstructorsPersonalLesson:
								cartItem = this.CreateInstructorsPersonalCartItem(x, cartInstance);
								break;
							case CartItemTypes.Tariff:
								cartItem = await this.CreateTariffCartItem(x, cartInstance);
								break;
							case CartItemTypes.Rewrite:
								cartItem = this.CreateRewriteTariffCartItem(x, cartInstance);
								break;
							case CartItemTypes.Bundle:
								cartItem = await this.CreateBundleCartItem(x, cartInstance);
								break;
							case CartItemTypes.InstructorsBundleLessons:
								cartItem = this.CreateInstructorsBundleCartItem(x, cartInstance);
								break;
							case CartItemTypes.MarketTariff:
								cartItem = this.CreateMarketTariffCartItem(x, cartInstance);
								break;
							case CartItemTypes.MarketBill:
								cartItem = this.CreateMarketBillCartItem(x, cartInstance);
								break;
							default:
								cartItem = this.CreateInstructorsPersonalCartItem(x, cartInstance);
								break;
						}

						currentCart.AddItem(cartItem);
					}
				} catch (err) {
					console.error(`Cart parse error: ${err}`);
					this._cart = new Cart(settings.SiteId, settings.SessionId, this._arenaService, settings.CartMaxItems);
				}
			} else this._cart = new Cart(settings.SiteId, settings.SessionId, this._arenaService, settings.CartMaxItems);

			if (settings.ResetCart) {
				this._cart.Clear();
			}

			this._cart.CartChanged.Subscribe(async (sender, e) => {
				let cache: any[] = [];
				let jsonCart = JSON.stringify(this._cart, function (key, value) {
					//if (typeof value === 'object' && value !== null) {
					//    if (cache.indexOf(value) !== -1) {
					//        return;
					//    }
					//    cache.push(value);
					//}
					if (key === 'BindedCapacity') return;
					if (key === '_cart') return;
					if (key === '_arenaService') return;

					return value;
				});
				cache = [];

				await this._provider.SaveCartAsync(jsonCart);
			});
		}

		return this._cart;
	}

	protected CreateMarketTariffCartItem(cartItem: any, cart: Cart): MarketTariffCartItem {
		let rule: MarketRule = new MarketRule();
		let schedule: MarketDateTimeRange = new MarketDateTimeRange();
		let client: MarketClient = new MarketClient();
		let employee: MarketEmployee;

		rule.typename = cartItem.Rule.typename;
		rule.AccessMask = cartItem.Rule.AccessMask;
		rule.CompositeName = cartItem.Rule.CompositeName;
		rule.GlobalMask = cartItem.Rule.GlobalMask;
		rule.Duration = cartItem.Rule.Duration;
		rule.id = cartItem.Rule.id;
		rule.IsProduct = cartItem.Rule.IsProduct;
		rule.ModificationTS = cartItem.Rule.ModificationTS;
		rule.Name = cartItem.Rule.Name;
		rule.Price = cartItem.Rule.Price;
		rule.Quantity = cartItem.Rule.Quantity;
		rule.Service = cartItem.Rule.Service;
		rule.State = cartItem.Rule.State;

		schedule.id = cartItem.Period.id;
		schedule.typename = cartItem.Period.typename;
		schedule.AccessMask = cartItem.Period.AccessMask;
		schedule.CompositeName = cartItem.Period.CompositeName;
		schedule.GlobalMask = cartItem.Period.GlobalMask;
		schedule.ModificationTS = cartItem.Period.ModificationTS;
		schedule.StartDateTime = cartItem.Period.StartDateTime;
		schedule.EndDateTime = cartItem.Period.EndDateTime;

		client.Id = cartItem.Client.Id;

		let result = new MarketTariffCartItem(this._marketService);

		result.OrderId = cartItem.OrderId;
		result.OrderItemId = cartItem.OrderItemId;
		result.Period = schedule;
		result.Client = client;
		result.Rule = rule;

		if (cartItem.Employee != null) {
			employee = new MarketEmployee();

			employee.id = cartItem.Employee.id;
			employee.typename = cartItem.Employee.typename;
			employee.AccessMask = cartItem.Employee.AccessMask;
			employee.CompositeName = cartItem.Employee.CompositeName;
			employee.GlobalMask = cartItem.Employee.GlobalMask;
			employee.FirstName = cartItem.Employee.FirstName;
			employee.LastName = cartItem.Employee.LastName;

			result.Employee = employee;
		}

		return result;
	}

	protected CreateArenaCartItem(cartItem: any, cart: Cart): ArenaCartItem {
		let seance: ArenaSeance = new ArenaSeance();
		let sector: ArenaSector = new ArenaSector();
		let tariff: ArenaTariff = new ArenaTariff();
		let seat: ArenaSeat = new ArenaSeat();
		let seatOption: ArenaTariffOption | undefined;

		seance.Id = cartItem.Seance.Id;
		seance.Name = cartItem.Seance.Name;
		seance.EventCode = cartItem.Seance.EventCode;
		seance.EventId = cartItem.Seance.EventId;
		seance.EventName = cartItem.Seance.EventName;
		seance.EndDate = cartItem.Seance.EndDate;
		seance.BeginDate = cartItem.Seance.BeginDate;
		seance.SaleEndDate = cartItem.Seance.SaleEndDate;

		sector.SectorCode = cartItem.Sector.SectorCode;
		sector.Name = cartItem.Sector.Name;
		sector.PlacesWithoutNumbers = sector.PlacesWithoutNumbers;

		tariff.TariffCode = cartItem.Tariff.TariffCode;
		tariff.Name = cartItem.Tariff.Name;
		tariff.MinPrice = cartItem.Tariff.MinPrice;
		tariff.MaxPrice = cartItem.Tariff.MaxPrice;
		tariff.Price = cartItem.Tariff.Price;

		if (cartItem.Tariff.Options != null) {
			let tariffOptions: Array<ArenaTariffOption> = [];

			cartItem.Tariff.Options.forEach((x: any) => {
				let option = new ArenaTariffOption();

				option.OptionCode = x.OptionCode;
				option.Name = x.Name;
				option.Price = x.Price;
			});

			tariff.Options = tariffOptions;
		}

		seat.PlaceCode = cartItem.Seat.PlaceCode;
		seat.LineName = cartItem.Seat.LineName;
		seat.PlaceName = cartItem.Seat.PlaceName;
		seat.TariffCode = cartItem.Seat.TariffCode;
		seat.X = cartItem.Seat.X;
		seat.Y = cartItem.Seat.Y;

		if (cartItem.Option != null) {
			seatOption = new ArenaTariffOption();

			seatOption.OptionCode = cartItem.Option.OptionCode;
			seatOption.Name = cartItem.Option.Name;
			seatOption.Price = cartItem.Option.Price;
		}

		let result = new ArenaCartItem(this._arenaService);

		result.OrderId = cartItem.OrderId;
		result.OrderItemId = cartItem.OrderItemId;
		result.SibId = cartItem.SibId;

		result.Seat = seat;
		result.Sector = sector;
		result.Seance = seance;
		result.Tariff = tariff;
		result.Option = seatOption;

		return result;
	}

	protected CreateInstructorsBundleCartItem(cartItem: any, cart: Cart): InstructorsBundlesCartItem {
		let bundle = new InstructorsBundlesGroupsLessons();

		bundle.BundleId = cartItem._bundle.BundleId;
		bundle.MinDate = cartItem._bundle.MinDate;
		bundle.MaxDate = cartItem._bundle.MaxDate;

		cartItem._bundle.Lessons.forEach(function (item: any) {
			let lesson = new InstructorsGroupLesson();

			lesson.Id = item.Id;
			lesson.BeginTime = item.BeginTime;
			lesson.Date = item.Date;
			lesson.EndTime = item.EndTime;
			lesson.MaxPersonsCount = item.MaxPersonsCount;
			lesson.FreeMembers = item.FreeMembers;
			lesson.PlaceId = item.PlaceId;
			lesson.SkillType = item.SkillType;
			lesson.TariffId = item.TariffId;
			lesson.TariffName = item.TariffName;

			bundle.Lessons.push(lesson);
		});

		return new InstructorsBundlesCartItem(bundle, cartItem._count, cartItem._price, cart);
	}

	protected CreateInstructorsGroupCartItem(cartItem: any, cart: Cart): InstructorsGroupCartItem {
		let lesson = new InstructorsGroupLesson();

		lesson.Id = cartItem._lesson.Id;
		lesson.BeginTime = cartItem._lesson.BeginTime;
		lesson.Date = cartItem._lesson.Date;
		lesson.EndTime = cartItem._lesson.EndTime;
		lesson.MaxPersonsCount = cartItem._lesson.MaxPersonsCount;
		lesson.FreeMembers = cartItem._lesson.FreeMembers;
		lesson.PlaceId = cartItem._lesson.PlaceId;
		lesson.SkillType = cartItem._lesson.SkillType;
		lesson.TariffId = cartItem._lesson.TariffId;
		lesson.TariffName = cartItem._lesson.TariffName;

		return new InstructorsGroupCartItem(lesson, cartItem._count, cartItem._price, cart, cartItem._lessonCoachId, cartItem._lessonLocationId);
	}

	protected CreateInstructorsPersonalCartItem(cartItem: any, cart: Cart): InstructorsPersonalCartItem {
		let lesson = new InstructorsPersonalLesson();

		lesson.Id = cartItem._lesson.Id;
		lesson.BeginTime = cartItem._lesson.BeginTime;
		lesson.Date = cartItem._lesson.Date;
		lesson.EndTime = cartItem._lesson.EndTime;
		lesson.MaxPersonsCount = cartItem._lesson.MaxPersonsCount;
		lesson.PlaceId = cartItem._lesson.PlaceId;
		lesson.SkillType = cartItem._lesson.SkillType;
		lesson.PriceForOne = cartItem._lesson.PriceForOne;
		lesson.PriceForSecond = cartItem._lesson.PriceForSecond;
		lesson.TariffId = cartItem._lesson.TariffId;
		lesson.TariffName = cartItem._lesson.TariffName;

		return new InstructorsPersonalCartItem(lesson, cartItem._count, cart);
	}

	protected async CreateTariffCartItem(cartItem: any, cart: Cart): Promise<TariffCartItem> {
		let tariff = new Tariff();

		tariff.Id = cartItem._tariff.Id;
		tariff.TariffCode = cartItem._tariff.TariffCode;
		tariff.Name = cartItem._tariff.Name;
		tariff.GroupId = cartItem._tariff.GroupId;
		tariff.GroupName = cartItem._tariff.GroupName;
		tariff.Category = cartItem._tariff.Category;
		tariff.WorkingDays = cartItem._tariff.WorkingDays;
		tariff.Description = cartItem._tariff.Description;
		tariff.Quota = cartItem._tariff.Quota;
		tariff.CalendarEnabled = cartItem._tariff.CalendarEnabled;
		tariff.StartSale = cartItem._tariff.StartSale;
		tariff.EndSale = cartItem._tariff.EndSale;
		tariff.ZIndex = cartItem._tariff.ZIndex;
		tariff.RequiredPersonification = cartItem._tariff.RequiredPersonification;
		tariff.IsRewritable = cartItem._tariff.IsRewritable;

		let tariffSeance: TariffSeance | undefined;
		if (cartItem._seance != null) {
			tariffSeance = new TariffSeance();
			tariffSeance.SeanceId = cartItem._seance.SeanceId;
			tariffSeance.SeanceValue = cartItem._seance.SeanceValue;
		}

		let tariffCapacity: TariffCapacity | undefined;
		if (cartItem._capacity != null) {
			let capacity = await this._tariffCapacityService.GetCapacityAsync(tariff.Id, cartItem._capacity.Date, cartItem._capacity.SeanceId);
			if (capacity.Success && capacity.Data != undefined) tariffCapacity = capacity.Data;
		}

		let tariffSale = new TariffSale();
		if (cartItem._tariffSale.TariffId != 0) {
			let tariffSaleResult = await this._tariffService.GetSingleTariffSalesAsync(cartItem._tariffSale.Date, tariff, cartItem._seance?.SeanceId);
			if (tariffSaleResult.Success && tariffSaleResult.Data != null) tariffSale = tariffSaleResult.Data;
		}

		return new TariffCartItem(tariff, cartItem._count, cartItem._date, cartItem._price, cart, tariffSale, tariffSeance, tariffCapacity);
	}

	protected CreateRewriteTariffCartItem(cartItem: any, cart: Cart): RewriteCartItem {
		let tariff = new Tariff();

		tariff.Id = cartItem._tariff.Id;
		tariff.Name = cartItem._tariff.Name;
		tariff.GroupId = cartItem._tariff.GroupId;
		tariff.GroupName = cartItem._tariff.GroupName;
		tariff.Category = cartItem._tariff.Category;
		tariff.WorkingDays = cartItem._tariff.WorkingDays;
		tariff.Description = cartItem._tariff.Description;
		tariff.Quota = cartItem._tariff.Quota;
		tariff.CalendarEnabled = cartItem._tariff.CalendarEnabled;
		tariff.StartSale = cartItem._tariff.StartSale;
		tariff.EndSale = cartItem._tariff.EndSale;
		tariff.ZIndex = cartItem._tariff.ZIndex;
		tariff.RequiredPersonification = cartItem._tariff.RequiredPersonification;
		tariff.IsRewritable = cartItem._tariff.IsRewritable;

		return new RewriteCartItem(
			tariff,
			cartItem._date,
			cartItem._price,
			cartItem._card,
			cartItem._quota,
			cartItem._rentalOptionId,
			cartItem._cardType,
			cartItem._cardName
		);
	}

	protected async CreateBundleCartItem(cartItem: any, cart: Cart): Promise<BundleCartItem> {
		let bundle: TariffsBundle = new TariffsBundle();
		let tariffsSales: Array<TariffSale> = [];
		let tariffsCapacity: Array<TariffCapacity | undefined> = [];

		bundle.Id = cartItem._bundle.Id;
		bundle.Name = cartItem._bundle.Name;

		let tariffSeance: TariffSeance | undefined;
		if (cartItem._seance != null) {
			tariffSeance = new TariffSeance();
			tariffSeance.SeanceId = cartItem._seance.SeanceId;
			tariffSeance.SeanceValue = cartItem._seance.SeanceValue;
		}

		for (let i = 0; i < cartItem._bundle.Tariffs.length; i++) {
			let x = cartItem._bundle.Tariffs[i];

			let tariff = new Tariff();

			tariff.Id = x.Id;
			tariff.Name = x.Name;
			tariff.GroupId = x.GroupId;
			tariff.GroupName = x.GroupName;
			tariff.Category = x.Category;
			tariff.WorkingDays = x.WorkingDays;
			tariff.Description = x.Description;
			tariff.Quota = x.Quota;
			tariff.CalendarEnabled = x.CalendarEnabled;
			tariff.StartSale = x.StartSale;
			tariff.EndSale = x.EndSale;
			tariff.ZIndex = x.ZIndex;
			tariff.RequiredPersonification = x.RequiredPersonification;
			tariff.IsRewritable = x.IsRewritable;

			bundle.Tariffs.push(tariff);

			let tariffCapacity: TariffCapacity | undefined;
			if (cartItem._capacity[i] != undefined) {
				let capacity = await this._tariffCapacityService.GetCapacityAsync(
					tariff.Id,
					cartItem._capacity[i].Date,
					cartItem._capacity[i].SeanceId
				);
				if (capacity.Success && capacity.Data != undefined) tariffCapacity = capacity.Data;
			}

			tariffsCapacity.push(tariffCapacity);

			let tariffSale = new TariffSale();
			let tariffSaleResult = await this._tariffService.GetSingleTariffSalesAsync(cartItem._sales[i].Date, tariff, cartItem._seance?.SeanceId);
			if (tariffSaleResult.Success && tariffSaleResult.Data != null) tariffSale = tariffSaleResult.Data;

			tariffsSales.push(tariffSale);
		}

		return new BundleCartItem(bundle, cartItem._date, cartItem._price, tariffsSales, tariffsCapacity, tariffSeance);
	}

	protected CreateMarketBillCartItem(cartItem: any, cart: Cart): MarketBillCartItem {
		let bill: MarketBill = new MarketBill();
		let schedule: MarketDateTimeRange = new MarketDateTimeRange();
		let client: MarketClient = new MarketClient();
		let employee: MarketEmployee;

		bill.typename = cartItem.Bill.typename;
		bill.AccessMask = cartItem.Bill.AccessMask;
		bill.CompositeName = cartItem.Bill.CompositeName;
		bill.GlobalMask = cartItem.Bill.GlobalMask;
		bill.id = cartItem.Bill.id;

		bill.Email = cartItem.Bill.Email;
		bill.Phone = cartItem.Bill.Phone;
		bill.Status = cartItem.Bill.Status;
		bill.Price = cartItem.Bill.Price;

		return new MarketBillCartItem(bill);
	}

	protected CreateNewContractCartItem(cartItem: any, cart: Cart): NewContractCartItem {
		return new NewContractCartItem(cartItem._id, cartItem._date, cartItem._name, cartItem._price, cartItem._code);
	}

	protected CreateContractClubTariffCartItem(cartItem: any, cart: Cart): ContractClubTariffCartItem {
		return new ContractClubTariffCartItem(
			cartItem._id,
			cartItem._templateContractId,
			cartItem._personContractId,
			cartItem._date,
			cartItem._name,
			cartItem._price,
			cartItem._code
		);
	}

	protected CreateAccountReplenishmentCartItem(cartItem: any, cart: Cart): AccountReplenishmentCartItem {
		return new AccountReplenishmentCartItem(cartItem._price, cartItem._personId);
	}

	protected CreateSyntechParkingCartItem(cartItem: any, cart: Cart): SyntechParkingCartItem {
		let tariffId: number = cartItem._tariffId;
		let sessionId: string = cartItem._sessionId;
		let ticket: string = cartItem._ticket;
		let price: number = cartItem._price;

		return new SyntechParkingCartItem(ticket, sessionId, price, tariffId);
	}
}
