// GENERIC
/* -= WpDateOfBirth =- */
import { WpDateOfBirthDictionary } from './../../Generic';
const wpDateOfBirthDictionary: WpDateOfBirthDictionary = new WpDateOfBirthDictionary();
wpDateOfBirthDictionary.DateOfBirth = 'Дата рождения';
wpDateOfBirthDictionary.NotEmpty = 'Поле не может быть пустым';
wpDateOfBirthDictionary.ValidDateRequired = 'Требуется валидная дата';

/* -= WpLoyalty*/
import { WpIsdLoyaltyRegisterDictionary } from './../../WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyRegister/wp-isd-loyalty-register';
import { WpIsdLoyaltyChildrenDictionary } from './../../WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyChildren/wp-isd-loyalty-children';

/* -= WpSale =- */
import { WpSaleDictionary } from './../../WpSale/wp-sale';

/* -= WpArena =- */
import { WpArenaDictionary } from './../../WpSale/WpArena/wp-arena';
import { WpArenaSeancesDictionary } from './../../WpSale/WpArena/WpArenaSeances/wp-arena-seances';
import { WpArenaDatepickerDictionary } from './../../WpSale/WpArena/WpArenaSeances/WpArenaSeancesDatepicker/wp-arena-seances-datepicker';
import { WpArenaSeanceItemDictionary } from './../../WpSale/WpArena/WpArenaSeances/wp-arena-seance-item';
import { WpArenaSeatsDictionary } from './../../WpSale/WpArena/WpArenaSeats/wp-arena-seats';
import { WpArenaSeatItemDictionary } from './../../WpSale/WpArena/WpArenaSeats/wp-arena-seat-item';
import { WpArenaSectorsDictionary } from './../../WpSale/WpArena/WpArenaSectors/wp-arena-sectors';
import { WpArenaSectorItemDictionary } from './../../WpSale/WpArena/WpArenaSectors/wp-arena-sector-item';
import { WpMapDictionary } from './../../WpSale/WpArena/WpMap/wp-map';
import { WpMinimapDictionary } from './../../WpSale/WpArena/WpMinimap/wp-minimap';

/* -= WpCart =- */
import { WpCartDictionary } from './../../WpSale/WpCart/wp-cart';
import { WpCartItemDictionary } from './../../WpSale/WpCart/WpCartItem/wp-cart-item';
import { WpCartBundleDictionary } from './../../WpSale/WpCart/WpCartBundle/wp-cart-bundle';

/* -= WpComplete =- */
import { WpCompleteDictionary } from './../../WpSale/WpComplete/wp-complete';

/* -= WpInstructors =- */
import { WpInstructorsDictionary } from './../../WpSale/WpInstructors/wp-instructors';
import { WpInstructorsBundlesDictionary } from './../../WpSale/WpInstructors/wp-instructors-bundles';
import { WpInstructorsGroupDictionary } from './../../WpSale/WpInstructors/wp-instructors-group';
import { WpInstructorsDatepickerDictionary } from './../../WpSale/WpInstructors/WpInstructorsDatepicker/wp-instructors-datepicker';
import { WpInstructorsBundlesLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-bundles-lessons';
import { WpInstructorsGroupLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-group-lessons';
import { WpInstructorsLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-lessons';

/* -= WpMarket =- */
import { WpMarketRulesDictionary } from './../../WpSale/WpMarket/wp-market-rules';
import { WpMarketRuleItemDictionary } from './../../WpSale/WpMarket/wp-market-rule-item';

/* -= WpOrderTimer =- */
import { WpOrderTimerDictionary } from './../../WpSale/WpOrderTimer/wp-order-timer';

/* -= WpPayConfirm =- */
import { WpPayConfirmDictionary } from './../../WpSale/WpPayConfirm/wp-payconfirm';
import { WpPayConfirmItemDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-item';
import { WpPayConfirmBundleDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-bundle';
import { WpPayConfirmPromoDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmPromo/wp-payconfirm-promo';
import { WpRulesDictionary } from './../../WpSale/WpPayConfirm/WpRules/wp-rules';

/* -= WpPersonification =- */
import { WpPersonificationDictionary } from './../../WpSale/WpPersonification/wp-personification';
import { WpPersonificationBundleDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle';
import { WpPersonificationBundleFormDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle-form';
import { WpPersonificationItemDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-item';

/* -= WpTariffs =- */
import { WpTariffsDictionary } from './../../WpSale/WpTariffs/wp-tariffs';
import { WpTariffsRewriteDictionary } from './../../WpSale/WpTariffs/wp-tariffs-rewrite';
import { WpTariffBundleDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-bundle';
import { WpTariffItemDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-item';
import { WpTariffRewriteItemDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-rewrite-item';
import { WpTariffsDatepickerDictionary } from './../../WpSale/WpTariffs/WpTariffsDatepicker/wp-tariffs-datepicker';

//import WpLoginPage from './WpLogin/wp-login-page';
import { WpLoginFormDictionary } from './../../WpLogin/WpLoginForm/wp-login-form';
import { WpPasswordResetDictionary } from './../../WpLogin/WpPasswordReset/wp-password-reset';
import { WpRegistrationFormDictionary } from './../../WpLogin/WpRegistrationForm/wp-registration-form';

import { WpPhoneConfirmDictionary } from './../../WpPhoneConfirm/wp-phone-confirm';

//import WpTopBar from './WpTopBar/wp-top-bar';

import { LocalizationStaticDictionary } from 'web-platform-core-ui';
import { WpInstructorsListDictionary } from '../../WpSale/WpInstructors/WpInstructorsList/wp-instructors-list';

import { WpUserAccountWireframeDictionary } from '../../WpUserAccountWireframe/wp-user-account-wireframe';

export class BaseDictionaryRu {
	static InternalError = 'Произошла непредвиденная ошибка';
	static AccessDenied = 'Доступ запрещен';

	static GenericYes = 'Да';
	static GenericNo = 'Нет';
	static GenericBack = 'Назад';
	static GenericNext = 'Далее';
	static GenericChange = 'Изменить';
	static GenericClose = 'Закрыть';
	static GenericError = 'Ошибка';
	static GenericPleaseWait = 'Подождите';
	static GenericClear = 'Очистить';
	static GenericCancel = 'Отмена';
	static GenericOK = 'ОК';
	static GenericLogIn = 'Войти';
	static GenericAdd = 'Добавить';
	static GenericEdit = 'Редактировать';
	static GenericUpdate = 'Обновить';
	static GenericApply = 'Применить';
	static GenericSelect = 'Выбрать';

	static GenericPrice = 'Цена';
	static GenericBuy = 'Купить';
	static GenericFrom = 'от';
	static GenericTo = 'до';

	static FormFieldRequired = 'Обязательное поле';
}

/* -= WpLoyalty =- */
const wpIsdLoyaltyRegisterDictionary: WpIsdLoyaltyRegisterDictionary = new WpIsdLoyaltyRegisterDictionary();
wpIsdLoyaltyRegisterDictionary.LastName = 'Фамилия';
wpIsdLoyaltyRegisterDictionary.FirstName = 'Имя';
wpIsdLoyaltyRegisterDictionary.SecondName = 'Отчество';
wpIsdLoyaltyRegisterDictionary.Birthdate = 'Дата рождения';
wpIsdLoyaltyRegisterDictionary.ChooseGender = 'Укажите ваш пол';
wpIsdLoyaltyRegisterDictionary.GenderMale = 'Мужской';
wpIsdLoyaltyRegisterDictionary.GenderFemale = 'Женский';
wpIsdLoyaltyRegisterDictionary.City = 'Город';
wpIsdLoyaltyRegisterDictionary.AcceptRequired = 'Необходимо согласиться';
wpIsdLoyaltyRegisterDictionary.GenericSave = 'Сохранить';
wpIsdLoyaltyRegisterDictionary.AcceptPartOne = 'Я согласен(-на) с';
wpIsdLoyaltyRegisterDictionary.AcceptPartTwo = 'условиями';

wpIsdLoyaltyRegisterDictionary.GenericFieldRequired = 'Поле нельзя оставить пустым';
wpIsdLoyaltyRegisterDictionary.GenericFormatIncorrect = 'Неверный формат';
wpIsdLoyaltyRegisterDictionary.GenericLettersOnly = 'В поле могут быть только буквы';
wpIsdLoyaltyRegisterDictionary.GenericPickOneOption = 'Выберите один из вариантов';
wpIsdLoyaltyRegisterDictionary.GenericValidBirthdateRequired = 'Введены некорректные данные';

const wpIsdLoyaltyChildrenDictionary: WpIsdLoyaltyChildrenDictionary = new WpIsdLoyaltyChildrenDictionary();
wpIsdLoyaltyChildrenDictionary.GenericChildren = 'Дети';
wpIsdLoyaltyChildrenDictionary.GenericChildData = 'Данные ребёнка';
wpIsdLoyaltyChildrenDictionary.GenericConfirm = 'Вы уверены?';
wpIsdLoyaltyChildrenDictionary.GenderMale = '♂ Мужской';
wpIsdLoyaltyChildrenDictionary.GenderFemale = '♀ Женский';
wpIsdLoyaltyChildrenDictionary.LastName = 'Фамилия';
wpIsdLoyaltyChildrenDictionary.FirstName = 'Имя';
wpIsdLoyaltyChildrenDictionary.SecondName = 'Отчество';

/* -= WpSale =- */
const wpSaleDictionary: WpSaleDictionary = new WpSaleDictionary();
wpSaleDictionary.TabService = 'Продажа услуг';
wpSaleDictionary.TabRewrite = 'Пополнение услуг';
wpSaleDictionary.TabPersonalLessons = 'Индивидуальные занятия';
wpSaleDictionary.TabGroupLessons = 'Групповые занятия';
wpSaleDictionary.TabBundlesLessons = 'Многодневные занятия';
wpSaleDictionary.GenericPlaceOrder = 'Оформить';
wpSaleDictionary.GenericPay = 'Оплатить';
wpSaleDictionary.GenericProcessPayment = 'Оплата';
wpSaleDictionary.CartItemNotAvailableError = 'Одна из позиций в корзине уже недоступна';
wpSaleDictionary.CartItemCountError = 'Закончились билеты на одну из позиций в корзине';
wpSaleDictionary.CardHaveTariffWithSoType = 'Билет уже с прокатным договором';
wpSaleDictionary.CardHaveNotUsedTariff = 'На карте есть неизрасходованная услуга';
wpSaleDictionary.LessonNotAvailable = 'Занятие недоступно';
wpSaleDictionary.InvalidEmail = 'Неверный формат email-а';
wpSaleDictionary.DataRequired = 'Необходимо заполнить данные';
wpSaleDictionary.SbpTitle = 'Отсканируйте QR-код с помощью телефона и перейдите по ссылке для оплаты заказа';
wpSaleDictionary.SbpMobileTitle = 'Если приложение банка не открылось перейдите, по ';
wpSaleDictionary.SbpMobileTitleLink = 'ссылке';
wpSaleDictionary.FreeTicketLimitError = 'Для Вас достигнут лимит оформления билетов. Ознакомьтесь с правилами покупки билетов на сайте.';

/* -= WpArena =- */
const wpArenaSeancesDictionary: WpArenaSeancesDictionary = new WpArenaSeancesDictionary();
wpArenaSeancesDictionary.NoAvailableSeances = 'Нет доступных мероприятий в продаже';

const wpArenaDatepickerDictionary: WpArenaDatepickerDictionary = new WpArenaDatepickerDictionary();
wpArenaDatepickerDictionary.TextBoxLabel = 'Выберите дату';
wpArenaDatepickerDictionary.DialogBtnClean = 'Очистить';
wpArenaDatepickerDictionary.DialogBtnCancel = 'Отмена';
wpArenaDatepickerDictionary.DialogBtnOk = 'OK';
wpArenaDatepickerDictionary.TitleText = 'Мероприятия на дату';

const wpArenaSeanceItemDictionary: WpArenaSeanceItemDictionary = new WpArenaSeanceItemDictionary();
wpArenaSeanceItemDictionary.TicketPrice = 'Цена билетов';
wpArenaSeanceItemDictionary.PricesLoading = 'Загрузка цен…';
wpArenaSeanceItemDictionary.CurrencyIcon = '₽';

const wpArenaSeatsDictionary: WpArenaSeatsDictionary = new WpArenaSeatsDictionary();
wpArenaSeatsDictionary.GenericOccupied = 'Занято';
wpArenaSeatsDictionary.GenericPicked = 'Выбрано';
wpArenaSeatsDictionary.ChooseSeatsOption = 'Выберите вариант места';
wpArenaSeatsDictionary.GenericRow = 'Ряд';
wpArenaSeatsDictionary.GenericSeat = 'Место';

const wpArenaSeatItemDictionary: WpArenaSeatItemDictionary = new WpArenaSeatItemDictionary();
wpArenaSeatItemDictionary.ErrorCartIsFull = 'Превышено максимальное количество элементов корзины';
wpArenaSeatItemDictionary.ErrorSeatIsOccupied = 'Место уже занято';

const wpArenaSectorsDictionary: WpArenaSectorsDictionary = new WpArenaSectorsDictionary();

const wpArenaSectorItemDictionary: WpArenaSectorItemDictionary = new WpArenaSectorItemDictionary();
wpArenaSectorItemDictionary.TicketPrice = 'Цена билетов';
wpArenaSectorItemDictionary.PricesLoading = 'Загрузка цен…';
wpArenaSectorItemDictionary.SeatsLoading = 'Загрузка мест…';
wpArenaSectorItemDictionary.SeatsSingular = 'место';
wpArenaSectorItemDictionary.SeatsPlural = 'мест';
wpArenaSectorItemDictionary.GenericRemaining = 'Осталось';
wpArenaSectorItemDictionary.CurrencyIcon = '₽';

const wpArenaDictionary: WpArenaDictionary = new WpArenaDictionary();
wpArenaDictionary.ArenaText = 'Арена';

const wpMapDictionary = new WpMapDictionary();
wpMapDictionary.row = 'Ряд';
wpMapDictionary.seat = 'Место';

const wpMinimapDictionary = new WpMinimapDictionary();
wpMinimapDictionary.Minimap = 'Миникарта';

/* -= WpCart =- */
const wpCartDictionary: WpCartDictionary = new WpCartDictionary();
wpCartDictionary.GenericCart = 'Корзина';
wpCartDictionary.GenericClearCart = 'Очистить корзину';
wpCartDictionary.GenericTotal = 'Итого';
wpCartDictionary.GenericCheckout = 'Оплатить';

const wpCartItemDictionary: WpCartItemDictionary = new WpCartItemDictionary();
const wpCartBundleDictionary: WpCartBundleDictionary = new WpCartBundleDictionary();

/* -= WpComplete =- */
const wpCompleteDictionary: WpCompleteDictionary = new WpCompleteDictionary();
wpCompleteDictionary.AwaitingResult = 'Ожидание результата платежа';
wpCompleteDictionary.BackToServiceList = 'Вернуться к выбору услуг';
wpCompleteDictionary.ThankYouForPurchase = 'Спасибо за покупку!';
wpCompleteDictionary.ConfirmRefusalToWait = 'Вы действительно хотите не дожидаться результата платежа?';
wpCompleteDictionary.FatalError = 'К сожалению возникли неполадки при оформлении заказа, обратитесь в техническую поддержку.';
wpCompleteDictionary.StartOver = 'Начать заново';
wpCompleteDictionary.YourOrderNo = 'Ваш заказ №';
wpCompleteDictionary.YourOrderStatusOK = 'оформлен! Информация по заказу была отправлена на вашу почту.';
wpCompleteDictionary.YourOrderStatusCanceled = 'отменен.';
wpCompleteDictionary.CardDiscountConditionError =
	'В случае отмены по причине ввода карты, не соответствующей условиям предоставления скидки, деньги будут возвращены в течение трёх рабочих дней';
wpCompleteDictionary.OrderInfoLinkTitle = 'Просмотреть информацию по заказу, а также скачать билеты вы можете';
wpCompleteDictionary.OrderInfoLinkText = 'здесь';
wpCompleteDictionary.CardPayment = 'Оплата картой';
wpCompleteDictionary.SbpPayment = 'Оплата через СБП';
wpCompleteDictionary.GenericPay = 'Оплатить';
wpCompleteDictionary.PayOrderTitle = 'Оплатить заказ №';
wpCompleteDictionary.Payu = 'Payu';
wpCompleteDictionary.Yandex = 'Яндекс';
wpCompleteDictionary.Sberbank = 'Сбербанк';
wpCompleteDictionary.RfiBank = 'РФИ банк';
wpCompleteDictionary.RosEuroBank = 'РосЕвроБанк';
wpCompleteDictionary.Gazprombank = 'Газпромбанк';
wpCompleteDictionary.SovkomBank = 'Совкомбанк';
wpCompleteDictionary.Kazkombank = 'Казкомерцбанк';
wpCompleteDictionary.Test = 'Тестовая';
wpCompleteDictionary.AlfaBank = 'Альфа-банк';
wpCompleteDictionary.PayOnline = 'Pay онлайн';

/* -= WpInstructors =- */
const wpInstructorsDictionary: WpInstructorsDictionary = new WpInstructorsDictionary();
wpInstructorsDictionary.Discipline = 'Дисциплина';
wpInstructorsDictionary.DisciplinesLoading = 'Загрузка дисциплин…';
wpInstructorsDictionary.Category = 'Категория';
wpInstructorsDictionary.CategoriesLoading = 'Загрузка категорий…';
wpInstructorsDictionary.Program = 'Программа обучения';
wpInstructorsDictionary.ProgramsLoading = 'Загрузка программ обучения…';
wpInstructorsDictionary.DialogBtnShow = 'Показать';
wpInstructorsDictionary.Location = 'Место';
wpInstructorsDictionary.LocationsLoading = 'Загрузка мест…';
wpInstructorsDictionary.Instructor = 'Инструктор';
wpInstructorsDictionary.InstructorsLoading = 'Загрузка инструкторов…';
wpInstructorsDictionary.NoAvailablePlaces = 'Нет доступных расписаний';

const wpInstructorsListDictionary: WpInstructorsListDictionary = new WpInstructorsListDictionary();
wpInstructorsListDictionary.Instructor = 'Инструктор';
wpInstructorsListDictionary.InstructorsLoading = 'Загрузка инструкторов…';

const wpInstructorsBundlesDictionary: WpInstructorsBundlesDictionary = new WpInstructorsBundlesDictionary();
wpInstructorsBundlesDictionary.Discipline = 'Дисциплина';
wpInstructorsBundlesDictionary.DisciplinesLoading = 'Загрузка дисциплин…';
wpInstructorsBundlesDictionary.Category = 'Категория';
wpInstructorsBundlesDictionary.CategoriesLoading = 'Загрузка категорий…';
wpInstructorsBundlesDictionary.Program = 'Программа обучения';
wpInstructorsBundlesDictionary.ProgramsLoading = 'Загрузка программ обучения…';
wpInstructorsBundlesDictionary.DialogBtnShow = 'Показать';
wpInstructorsBundlesDictionary.NoLessonsAvailable = 'Нет доступных занятий';
wpInstructorsBundlesDictionary.Location = 'Место';
wpInstructorsBundlesDictionary.LocationsLoading = 'Загрузка мест…';

const wpInstructorsGroupDictionary: WpInstructorsGroupDictionary = new WpInstructorsGroupDictionary();
wpInstructorsGroupDictionary.Discipline = 'Дисциплина';
wpInstructorsGroupDictionary.DisciplinesLoading = 'Загрузка дисциплин…';
wpInstructorsGroupDictionary.Category = 'Категория';
wpInstructorsGroupDictionary.CategoriesLoading = 'Загрузка категорий…';
wpInstructorsGroupDictionary.Program = 'Программа обучения';
wpInstructorsGroupDictionary.ProgramsLoading = 'Загрузка программ обучения…';
wpInstructorsGroupDictionary.DialogBtnShow = 'Показать';
wpInstructorsGroupDictionary.Location = 'Место';
wpInstructorsGroupDictionary.LocationsLoading = 'Загрузка мест…';

const wpInstructorsDatepickerDictionary: WpInstructorsDatepickerDictionary = new WpInstructorsDatepickerDictionary();
wpInstructorsDatepickerDictionary.PeriodOfStudy = 'Период обучения';

const wpInstructorsBundlesLessonsDictionary: WpInstructorsBundlesLessonsDictionary = new WpInstructorsBundlesLessonsDictionary();
wpInstructorsBundlesLessonsDictionary.Vacant = 'свободных мест';
wpInstructorsBundlesLessonsDictionary.GuestsAmmount = 'Количество гостей';

const wpInstructorsGroupLessonsDictionary: WpInstructorsGroupLessonsDictionary = new WpInstructorsGroupLessonsDictionary();
wpInstructorsGroupLessonsDictionary.Vacant = 'свободных мест';
wpInstructorsGroupLessonsDictionary.GuestsAmmount = 'Количество гостей';
wpInstructorsGroupLessonsDictionary.NoAvailableLessons = 'Нет доступных занятий';

const wpInstructorsLessonsDictionary: WpInstructorsLessonsDictionary = new WpInstructorsLessonsDictionary();
wpInstructorsLessonsDictionary.GuestsAmmount = 'Количество гостей';
wpInstructorsLessonsDictionary.ShorthandPeople = 'чел.';
wpInstructorsLessonsDictionary.NoAvailableLessons = 'Нет доступных занятий';

/* -= WpMarket =- */
const wpMarketRulesDictionary: WpMarketRulesDictionary = new WpMarketRulesDictionary();
wpMarketRulesDictionary.NoServicesAvailableOnThisDate = 'Нет доступных услуг на эту дату';
wpMarketRulesDictionary.DataRequired = 'Необходимо заполнить данные';

const wpMarketRuleItemDictionary: WpMarketRuleItemDictionary = new WpMarketRuleItemDictionary();
wpMarketRuleItemDictionary.CartIsFull = 'Максимальное количество элементов в корзине';
wpMarketRuleItemDictionary.ToCart = 'В корзину';

/* -= WpOrderTimer =- */
const wpOrderTimerDictionary: WpOrderTimerDictionary = new WpOrderTimerDictionary();

/* -= WpPayConfirm =- */
const wpPayConfirmDictionary: WpPayConfirmDictionary = new WpPayConfirmDictionary();
wpPayConfirmDictionary.UsedPromoCode = 'Применен промокод';
wpPayConfirmDictionary.DataRequired = 'Необходимо заполнить данные';
wpPayConfirmDictionary.PlacingOrder = 'Оформление заказа';
wpPayConfirmDictionary.ToGetDeals = 'Чтобы накопить баллы и получить скидку необходимо';
wpPayConfirmDictionary.YouAreMemberOfLoyaltyProgram = 'Вы являетесь участником программы лояльности';
wpPayConfirmDictionary.LabelEmail = 'Email';
wpPayConfirmDictionary.LabelPhone = 'Телефон';
wpPayConfirmDictionary.LabelPromocode = 'Промокод';
wpPayConfirmDictionary.EnterEmail = 'Введите e-mail';
wpPayConfirmDictionary.EnterPhone = 'Введите телефон';
wpPayConfirmDictionary.ChooseDiscount = 'Выберите скидку';
wpPayConfirmDictionary.CodeIncorrect = 'Неверный код';
wpPayConfirmDictionary.GenericDiscount = 'Скидка';
wpPayConfirmDictionary.GenericTotal = 'Итого';
wpPayConfirmDictionary.GenericPay = 'Оплатить';
wpPayConfirmDictionary.GenericByPressingPay = 'Нажимая "Оплатить", Вы соглашаетесь с';
wpPayConfirmDictionary.GenericToRules = 'условиями';
wpPayConfirmDictionary.HowManyPointsToSpend = 'Сколько баллов вы хотите списать?';
wpPayConfirmDictionary.NoDiscount = 'Без скидки';
wpPayConfirmDictionary.GenericPurchaseComment = 'Комментарий к заказу';
wpPayConfirmDictionary.ChoosePayment = 'Выберите способ оплаты';
wpPayConfirmDictionary.CardPayment = 'Оплата картой';
wpPayConfirmDictionary.SbpPayment = 'Оплата через СБП';
wpPayConfirmDictionary.NoPointsToSpend = 'У вас нет баллов, которые можно использовать в заказе';
wpPayConfirmDictionary.MaxCharactersLimitPromocode = 'Промокод не может быть длиннее 50 символов';

wpPayConfirmDictionary.Payu = 'Payu';
wpPayConfirmDictionary.Yandex = 'Яндекс';
wpPayConfirmDictionary.Sberbank = 'Сбербанк';
wpPayConfirmDictionary.RfiBank = 'РФИ банк';
wpPayConfirmDictionary.RosEuroBank = 'РосЕвроБанк';
wpPayConfirmDictionary.Gazprombank = 'Газпромбанк';
wpPayConfirmDictionary.SovkomBank = 'Совкомбанк';
wpPayConfirmDictionary.Kazkombank = 'Казкомерцбанк';
wpPayConfirmDictionary.Test = 'Тестовая';
wpPayConfirmDictionary.AlfaBank = 'Альфа-банк';
wpPayConfirmDictionary.PayOnline = 'Pay онлайн';

const wpPayConfirmItemDictionary: WpPayConfirmItemDictionary = new WpPayConfirmItemDictionary();
wpPayConfirmItemDictionary.GenericDecline = 'Отказаться';
wpPayConfirmItemDictionary.GenericTotalSum = 'Сумма';

const wpPayConfirmBundleDictionary: WpPayConfirmBundleDictionary = new WpPayConfirmBundleDictionary();
wpPayConfirmBundleDictionary.GenericDecline = 'Отказаться';
wpPayConfirmBundleDictionary.GenericTotalSum = 'Сумма';
wpPayConfirmBundleDictionary.Personalization = 'Персонализация';

const wpPayConfirmPromoDictionary: WpPayConfirmPromoDictionary = new WpPayConfirmPromoDictionary();
wpPayConfirmPromoDictionary.CallToAction = 'Воспользуйтесь промокодом!';
wpPayConfirmPromoDictionary.GenericSuccess = 'Вы применили промокод «ABC123»!';

const wpRulesDictionary: WpRulesDictionary = new WpRulesDictionary();
wpRulesDictionary.GenericTerms = 'Условия';
wpRulesDictionary.GenericTermsISDS = 'Условия ISDS';
wpRulesDictionary.GenericIAgree = 'Подтверждаю и ';
wpRulesDictionary.GenericPay = 'оплатить';

/* -= WpPersonification =- */
const wpPersonificationDictionary: WpPersonificationDictionary = new WpPersonificationDictionary();
wpPersonificationDictionary.PersonSaleFirstName = 'клиента';
wpPersonificationDictionary.PersonSaleLastName = 'Нового';
wpPersonificationDictionary.GenericTotalSum = 'Итого';
wpPersonificationDictionary.GenericTotalSumDiscounted = 'Итого со скидкой';

const wpPersonificationBundleDictionary: WpPersonificationBundleDictionary = new WpPersonificationBundleDictionary();
wpPersonificationBundleDictionary.GenericDecline = 'Отказаться';
wpPersonificationBundleDictionary.GenericTotalSum = 'Сумма';

const wpPersonificationBundleFormDictionary: WpPersonificationBundleFormDictionary = new WpPersonificationBundleFormDictionary();
wpPersonificationBundleFormDictionary.LabelFirstName = 'Имя';
wpPersonificationBundleFormDictionary.LabelSecondName = 'Отчество';
wpPersonificationBundleFormDictionary.LabelLastName = 'Фамилия';
wpPersonificationBundleFormDictionary.FieldRequired = 'Необходимо заполнить';
wpPersonificationBundleFormDictionary.UnacceptableCharacters = 'Введены недопустимые символы';
wpPersonificationBundleFormDictionary.PersonifyOn = 'Персонифицировать на:';

const wpPersonificationItemDictionary: WpPersonificationItemDictionary = new WpPersonificationItemDictionary();
wpPersonificationItemDictionary.LabelFirstName = 'Имя';
wpPersonificationItemDictionary.LabelSecondName = 'Отчество';
wpPersonificationItemDictionary.LabelLastName = 'Фамилия';
wpPersonificationItemDictionary.FieldRequired = 'Необходимо заполнить';
wpPersonificationItemDictionary.UnacceptableCharacters = 'Введены недопустимые символы';
wpPersonificationItemDictionary.PersonifyOn = 'Персонифицировать на:';
wpPersonificationItemDictionary.GenericDecline = 'Отказаться';
wpPersonificationItemDictionary.GenericTotalSum = 'Сумма';

/* -= WpTariffs =- */
const wpTariffsDictionary: WpTariffsDictionary = new WpTariffsDictionary();
wpTariffsDictionary.NoAvailableLessonsForThisDate = 'Нет доступных услуг на эту дату';

const wpTariffsRewriteDictionary: WpTariffsRewriteDictionary = new WpTariffsRewriteDictionary();
wpTariffsRewriteDictionary.CardRegisteredOn = 'Карта оформлена на';
wpTariffsRewriteDictionary.NoAvailableServicesForThisCard = 'Нет доступных услуг для данной карты';
wpTariffsRewriteDictionary.UnacceptableCharacters = 'Номер карты должен содержать A-F и цифры';
wpTariffsRewriteDictionary.GenericCheck = 'Проверить';
wpTariffsRewriteDictionary.LabelCardNo = 'Номер карты';
wpTariffsRewriteDictionary.InstructionEnterCardNumber = 'Для пополнения услуг, введите номер карты:';
wpTariffsRewriteDictionary.InstructionCharacters = 'Номер карты представляет из себя 16 букв-цифр и находится на самой карте.';
wpTariffsRewriteDictionary.CardNotFound = 'Карта не найдена';
wpTariffsRewriteDictionary.CardExpired = 'Период действия карты истек';
wpTariffsRewriteDictionary.CardInProcess = 'Данная карта уже участвует в другом заказе';
wpTariffsRewriteDictionary.CardIsAlredyInCart = 'За один раз можно дозаписать только одну услугу на одну карту';
wpTariffsRewriteDictionary.CardMinimum8 = 'Минимальная длина номера карты - 8 символов';
wpTariffsRewriteDictionary.NfcBtnText = 'Сканировать карту телефоном';
wpTariffsRewriteDictionary.NfcDialogTitle = 'Поднесите карту к телефону';
wpTariffsRewriteDictionary.CardBanned = 'Карта в стоп листе';

const wpTariffBundleDictionary: WpTariffBundleDictionary = new WpTariffBundleDictionary();
wpTariffBundleDictionary.GenericItemsLeft = 'осталось';
wpTariffBundleDictionary.GenericItemsOf = 'из';
wpTariffBundleDictionary.GenericToCart = 'В корзину';
wpTariffBundleDictionary.CartIsFull = 'Максимальное количество элементов в корзине';
wpTariffBundleDictionary.NoMoreTickets = 'Закончились билеты';
wpTariffBundleDictionary.CustomService =
	'Абонемент на 1 день для проезда и провоза велосипеда на подъемнике Олимпия (однократно) и Волчья Скала (многократно).';
wpTariffBundleDictionary.CarNumberDialogTitle = 'Введите номер(а) автомобиля';
wpTariffBundleDictionary.CarTicketTitle = 'Билет';
wpTariffBundleDictionary.CarNumberTextLabel = 'Введите номер';
wpTariffBundleDictionary.CarNumberRequiredError = 'Поле обязательно к заполнению';
wpTariffBundleDictionary.CarNumberNotValid = 'Неверный формат';
wpTariffBundleDictionary.CarNumberLatinOnly = 'В номере автомобиля допускаются только латинские символы и цифры';

const wpTariffItemDictionary: WpTariffItemDictionary = new WpTariffItemDictionary();
wpTariffItemDictionary.GenericItemsLeft = 'осталось';
wpTariffItemDictionary.GenericItemsOf = 'из';
wpTariffItemDictionary.GenericToCart = 'В корзину';
wpTariffItemDictionary.CartIsFull = 'Максимальное количество элементов в корзине';
wpTariffItemDictionary.NoMoreTickets = 'Закончились билеты';
wpTariffItemDictionary.TariffUnavailableForThisDate = 'Тариф недоступен на эту дату';
wpTariffItemDictionary.CarNumberDialogTitle = 'Введите номер(а) автомобиля';
wpTariffItemDictionary.CarTicketTitle = 'Билет';
wpTariffItemDictionary.CarNumberTextLabel = 'Введите номер';
wpTariffItemDictionary.CarNumberRequiredError = 'Поле обязательно к заполнению';
wpTariffItemDictionary.CarNumberNotValid = 'Неверный формат';
wpTariffItemDictionary.CarNumberLatinOnly = 'В номере автомобиля допускаются только латинские символы и цифры';

const wpTariffRewriteItemDictionary: WpTariffRewriteItemDictionary = new WpTariffRewriteItemDictionary();
wpTariffRewriteItemDictionary.GenericToCart = 'В корзину';
wpTariffRewriteItemDictionary.TariffUnavailableForThisDate = 'Тариф недоступен на эту дату';

const wpTariffsDatepickerDictionary: WpTariffsDatepickerDictionary = new WpTariffsDatepickerDictionary();
wpTariffsDatepickerDictionary.TextBoxLabel = 'Выберите дату';
wpTariffsDatepickerDictionary.DialogBtnClean = 'Очистить';
wpTariffsDatepickerDictionary.DialogBtnCancel = 'Отмена';
wpTariffsDatepickerDictionary.DialogBtnOk = 'OK';
wpArenaDatepickerDictionary.TitleText = 'Услуги на дату';

/* -= WpLogin =- */
const wpLoginFormDictionary: WpLoginFormDictionary = new WpLoginFormDictionary();
wpLoginFormDictionary.FieldRequiredMessage = 'Поле обязательно к заполнению';
wpLoginFormDictionary.UserNameLblText = 'Email или телефон';
wpLoginFormDictionary.PasswordLblText = 'Пароль';
wpLoginFormDictionary.RememberMeLblText = 'Запомнить меня';
wpLoginFormDictionary.SignBtnText = 'Войти';
wpLoginFormDictionary.ForgotPasswordLink = 'Забыли пароль?';
wpLoginFormDictionary.RegistrationLink = 'Регистрация';
wpLoginFormDictionary.InvalidUserNameOrPassword = 'Неверный логин или пароль';

const wpRegistrationFormDictionary: WpRegistrationFormDictionary = new WpRegistrationFormDictionary();
wpRegistrationFormDictionary.SuccessRegistrationMessage = 'Успешная регистрация';
wpRegistrationFormDictionary.EmailRequiredMessage = 'Введите e-mail';
wpRegistrationFormDictionary.FieldRequiredMessage = 'Поле обязательно к заполнению';
wpRegistrationFormDictionary.PasswordLengthError = 'Должно быть длинее 6 символов';
wpRegistrationFormDictionary.PasswordNotAvailableCharacters =
	'Доступные символы для пароля: цифры, кириллица, латинские буквы, (пробел) ! " № # $ % & \'() * + , - . / : ; + = ?@[] ^ _` { | } ~ <>';
wpRegistrationFormDictionary.ComparePasswordError = 'Пароли не совпадают';
wpRegistrationFormDictionary.RegistrationTitle = 'Регистрация';
wpRegistrationFormDictionary.EmailLblText = 'Введите email';
wpRegistrationFormDictionary.PasswordLblText = 'Введите пароль';
wpRegistrationFormDictionary.RepeatPasswordLblText = 'Повторите пароль';
wpRegistrationFormDictionary.AgreementError = 'Необходимо согласиться';
wpRegistrationFormDictionary.BeforeAgreementLinkText = 'Я согласен(-на) с';
wpRegistrationFormDictionary.AgreementLink = 'условиями';
wpRegistrationFormDictionary.AfterAgreementLinkText = 'обработки персональных данных';
wpRegistrationFormDictionary.RegistrationBtnText = 'Зарегистрироваться';
wpRegistrationFormDictionary.EmailExistError = 'Такой email уже зарегистрирован';

const wpPasswordResetDictionary: WpPasswordResetDictionary = new WpPasswordResetDictionary();
wpPasswordResetDictionary.SuccessPasswordChangedMessage = 'Пароль изменен';
wpPasswordResetDictionary.NewPasswordSendedMessage = 'Новый пароль отправлен на почту';
wpPasswordResetDictionary.EmailRequiredMessage = 'Введите e-mail';
wpPasswordResetDictionary.FieldRequiredMessage = 'Поле обязательно к заполнению';
wpPasswordResetDictionary.PasswordLengthError = 'Должно быть длинее 6 символов';
wpPasswordResetDictionary.PasswordNotAvailableCharacters =
	'Доступные символы для пароля: цифры, кириллица, латинские буквы, (пробел) ! " № # $ % & \'() * + , - . / : ; + = ?@[] ^ _` { | } ~ <>';
wpPasswordResetDictionary.ComparePasswordError = 'Пароли не совпадают';
wpPasswordResetDictionary.ResetPasswordTitle = 'Восстановление пароля';
wpPasswordResetDictionary.EmailLblText = 'Введите email';
wpPasswordResetDictionary.NewPasswordLblText = 'Введите новый пароль';
wpPasswordResetDictionary.RepeatPasswordLblText = 'Повторите пароль';
wpPasswordResetDictionary.ResetBtnText = 'Восстановить';
wpPasswordResetDictionary.ChangeBtnText = 'сменить';

const wpPhoneConfirmDictionary: WpPhoneConfirmDictionary = new WpPhoneConfirmDictionary();
wpPhoneConfirmDictionary.TryCountError = 'Слишком много попыток. Попробуйте после';
wpPhoneConfirmDictionary.CodeRequiredError = 'Введите код';
wpPhoneConfirmDictionary.PhoneNotDigitsError = 'Должны быть только цифры';
wpPhoneConfirmDictionary.PhoneRequiredError = 'Заполните номер до конца';
wpPhoneConfirmDictionary.PhoneLblText = 'Введите номер телефона';
wpPhoneConfirmDictionary.PhoneCountryChangeLink = 'изменить';
wpPhoneConfirmDictionary.PhoneCountryTitle = 'Выбранный регион ';
wpPhoneConfirmDictionary.SendSMSBtnText = 'Отправить';
wpPhoneConfirmDictionary.CodeSendedMessage = 'Код выслан на номер';
wpPhoneConfirmDictionary.AnotherPhoneLink = 'другой номер';
wpPhoneConfirmDictionary.CodeLblText = 'Введите код';
wpPhoneConfirmDictionary.SendAgainLink = 'Отправить повторно';
wpPhoneConfirmDictionary.TimeoutResendMessage = 'Повторно вы сможете запросить код через';
wpPhoneConfirmDictionary.TimeoutSecondsMessage = 'сек.';
wpPhoneConfirmDictionary.ConfirmCodeBtnText = 'Подтвердить';
wpPhoneConfirmDictionary.SomeOneElsePhoneError = 'Данный телефон зарегистрирован на другого пользователя';

wpPhoneConfirmDictionary.NewTelTitle = 'Подтверждение номера телефона';
wpPhoneConfirmDictionary.NewTelDescription = 'Для подтверждения позвоните по номеру';
wpPhoneConfirmDictionary.NewTelResendMessage = 'Повторно вы сможете запросить проверку через';

const wpUserAccountWireframeDictionary: WpUserAccountWireframeDictionary = new WpUserAccountWireframeDictionary();
wpUserAccountWireframeDictionary.NewsMenu = 'Новости';
wpUserAccountWireframeDictionary.ProfileMenu = 'Профиль';
wpUserAccountWireframeDictionary.OrdersMenu = 'Мои заказы';
wpUserAccountWireframeDictionary.CardsMenu = 'Мои карты';
wpUserAccountWireframeDictionary.FeedbackMenu = 'Обратная связь';
wpUserAccountWireframeDictionary.ChangePasswordMenu = 'Сменить пароль';
wpUserAccountWireframeDictionary.LogOutMenu = 'Выйти';
wpUserAccountWireframeDictionary.LogOutDialogTitle = 'Выход';
wpUserAccountWireframeDictionary.LogOutDialogMessage = 'Вы действительно хотите выйти?';

import { WpDisclaimerDictionary } from './../../WpCommon/WpDisclaimer.vue';
const wpDisclaimerDictionary: WpDisclaimerDictionary = new WpDisclaimerDictionary();

const wpRuLocalizationComponentsDictionary: Array<any> = [
	// Friendly reminder:
	// Ключ должен совпадать с наименованием компонента в разметке
	// Ключ = Symbol.for(ключ)

	new LocalizationStaticDictionary<WpDateOfBirthDictionary>(Symbol.for('wp-date-of-birth'), wpDateOfBirthDictionary),
	new LocalizationStaticDictionary<WpDisclaimerDictionary>(Symbol.for('wp-disclaimer'), wpDisclaimerDictionary),

	/* -= WpLoyalty =- */
	new LocalizationStaticDictionary<WpIsdLoyaltyRegisterDictionary>(Symbol.for('wp-isd-loyalty-register'), wpIsdLoyaltyRegisterDictionary),
	new LocalizationStaticDictionary<WpIsdLoyaltyChildrenDictionary>(Symbol.for('wp-isd-loyalty-children'), wpIsdLoyaltyChildrenDictionary),

	/* -= WpSale =- */
	new LocalizationStaticDictionary<WpSaleDictionary>(Symbol.for('wp-sale'), wpSaleDictionary),

	/* -= WpArena =- */
	new LocalizationStaticDictionary<WpArenaDictionary>(Symbol.for('wp-arena'), wpArenaDictionary),
	new LocalizationStaticDictionary<WpArenaSeancesDictionary>(Symbol.for('wp-arena-seances'), wpArenaSeancesDictionary),
	new LocalizationStaticDictionary<WpArenaDatepickerDictionary>(Symbol.for('wp-arena-seances-datepicker'), wpArenaDatepickerDictionary),
	new LocalizationStaticDictionary<WpArenaSeanceItemDictionary>(Symbol.for('wp-arena-seance-item'), wpArenaSeanceItemDictionary),
	new LocalizationStaticDictionary<WpArenaSeatsDictionary>(Symbol.for('wp-arena-seats'), wpArenaSeatsDictionary),
	new LocalizationStaticDictionary<WpArenaSeatItemDictionary>(Symbol.for('wp-arena-seat-item'), wpArenaSeatItemDictionary),
	new LocalizationStaticDictionary<WpArenaSectorsDictionary>(Symbol.for('wp-arena-sectors'), wpArenaSectorsDictionary),
	new LocalizationStaticDictionary<WpArenaSectorItemDictionary>(Symbol.for('wp-arena-sector-item'), wpArenaSectorItemDictionary),
	new LocalizationStaticDictionary<WpMapDictionary>(Symbol.for('wp-map'), wpMapDictionary),
	new LocalizationStaticDictionary<WpMinimapDictionary>(Symbol.for('wp-minimap'), wpMinimapDictionary),

	/* -= WpCart =- */
	new LocalizationStaticDictionary<WpCartDictionary>(Symbol.for('wp-cart'), wpCartDictionary),
	new LocalizationStaticDictionary<WpCartItemDictionary>(Symbol.for('wp-cart-item'), wpCartItemDictionary),
	new LocalizationStaticDictionary<WpCartBundleDictionary>(Symbol.for('wp-cart-bundle'), wpCartBundleDictionary),

	/* -= WpComplete =- */
	new LocalizationStaticDictionary<WpCompleteDictionary>(Symbol.for('wp-complete'), wpCompleteDictionary),

	/* -= WpInstructors =- */
	new LocalizationStaticDictionary<WpInstructorsDictionary>(Symbol.for('wp-instructors'), wpInstructorsDictionary),
	new LocalizationStaticDictionary<WpInstructorsBundlesDictionary>(Symbol.for('wp-instructors-bundles'), wpInstructorsBundlesDictionary),
	new LocalizationStaticDictionary<WpInstructorsGroupDictionary>(Symbol.for('wp-instructors-group'), wpInstructorsGroupDictionary),
	new LocalizationStaticDictionary<WpInstructorsDatepickerDictionary>(Symbol.for('wp-instructors-datepicker'), wpInstructorsDatepickerDictionary),
	new LocalizationStaticDictionary<WpInstructorsBundlesLessonsDictionary>(
		Symbol.for('wp-instructors-bundles-lessons'),
		wpInstructorsBundlesLessonsDictionary
	),
	new LocalizationStaticDictionary<WpInstructorsGroupLessonsDictionary>(
		Symbol.for('wp-instructors-group-lessons'),
		wpInstructorsGroupLessonsDictionary
	),
	new LocalizationStaticDictionary<WpInstructorsLessonsDictionary>(Symbol.for('wp-instructors-lessons'), wpInstructorsLessonsDictionary),
	new LocalizationStaticDictionary<WpInstructorsListDictionary>(Symbol.for('wp-instructors-list'), wpInstructorsListDictionary),

	/* -= WpMarket =- */
	new LocalizationStaticDictionary<WpMarketRulesDictionary>(Symbol.for('wp-market-rules'), wpMarketRulesDictionary),
	new LocalizationStaticDictionary<WpMarketRuleItemDictionary>(Symbol.for('wp-market-rule-item'), wpMarketRuleItemDictionary),

	/* -= WpOrderTimer =- */
	new LocalizationStaticDictionary<WpOrderTimerDictionary>(Symbol.for('wp-order-timer'), wpOrderTimerDictionary),

	/* -= WpPayConfirm =- */
	new LocalizationStaticDictionary<WpPayConfirmDictionary>(Symbol.for('wp-payconfirm'), wpPayConfirmDictionary),
	new LocalizationStaticDictionary<WpPayConfirmItemDictionary>(Symbol.for('wp-payconfirm-item'), wpPayConfirmItemDictionary),
	new LocalizationStaticDictionary<WpPayConfirmBundleDictionary>(Symbol.for('wp-payconfirm-bundle'), wpPayConfirmBundleDictionary),
	new LocalizationStaticDictionary<WpPayConfirmPromoDictionary>(Symbol.for('wp-payconfirm-promo'), wpPayConfirmPromoDictionary),
	new LocalizationStaticDictionary<WpRulesDictionary>(Symbol.for('wp-rules'), wpRulesDictionary),

	/* -= WpPersonification =- */
	new LocalizationStaticDictionary<WpPersonificationDictionary>(Symbol.for('wp-personification'), wpPersonificationDictionary),
	new LocalizationStaticDictionary<WpPersonificationBundleDictionary>(Symbol.for('wp-personification-bundle'), wpPersonificationBundleDictionary),
	new LocalizationStaticDictionary<WpPersonificationBundleFormDictionary>(
		Symbol.for('wp-personification-bundle-form'),
		wpPersonificationBundleFormDictionary
	),
	new LocalizationStaticDictionary<WpPersonificationItemDictionary>(Symbol.for('wp-personification-item'), wpPersonificationItemDictionary),

	/* -= WpTariffs =- */
	new LocalizationStaticDictionary<WpTariffsDictionary>(Symbol.for('wp-tariffs'), wpTariffsDictionary),
	new LocalizationStaticDictionary<WpTariffsRewriteDictionary>(Symbol.for('wp-tariffs-rewrite'), wpTariffsRewriteDictionary),
	new LocalizationStaticDictionary<WpTariffBundleDictionary>(Symbol.for('wp-tariff-bundle'), wpTariffBundleDictionary),
	new LocalizationStaticDictionary<WpTariffItemDictionary>(Symbol.for('wp-tariff-item'), wpTariffItemDictionary),
	new LocalizationStaticDictionary<WpTariffRewriteItemDictionary>(Symbol.for('wp-tariff-rewrite-item'), wpTariffRewriteItemDictionary),
	new LocalizationStaticDictionary<WpTariffsDatepickerDictionary>(Symbol.for('wp-tariffs-datepicker'), wpTariffsDatepickerDictionary),

	/* -= WpLogin =- */
	new LocalizationStaticDictionary<WpLoginFormDictionary>(Symbol.for('wp-login-form'), wpLoginFormDictionary),
	new LocalizationStaticDictionary<WpRegistrationFormDictionary>(Symbol.for('wp-registration-form'), wpRegistrationFormDictionary),
	new LocalizationStaticDictionary<WpPasswordResetDictionary>(Symbol.for('wp-password-reset'), wpPasswordResetDictionary),

	new LocalizationStaticDictionary<WpPhoneConfirmDictionary>(Symbol.for('wp-phone-confirm'), wpPhoneConfirmDictionary),

	new LocalizationStaticDictionary<WpUserAccountWireframeDictionary>(Symbol.for('wp-user-account-wireframe'), wpUserAccountWireframeDictionary),
];

export default wpRuLocalizationComponentsDictionary;
