import { injectable, inject } from 'inversify';

import { ActionResult } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';

import { INewTelProvider, IdentityPhoneConfirmationProvider } from './..';
import { ICaptchaProvider } from './..';

import DefaultPhoneConfirmationProvider from './default-phone-confirmation-provider';
import { IContext } from './../Identity';
import { CaptchaSymbols } from './Captcha';

@injectable()
export default class CombineNewTelProvider implements INewTelProvider {
	private _context: IContext;

	private _identityProvider: IdentityPhoneConfirmationProvider;
	private _defaultProvider: DefaultPhoneConfirmationProvider;

	constructor(@inject(IdentitySymbols.Context) context: IContext, @inject(CaptchaSymbols.CaptchaProvider) captchaProvider: ICaptchaProvider) {
		this._context = context;
		this._identityProvider = new IdentityPhoneConfirmationProvider(captchaProvider);
		this._defaultProvider = new DefaultPhoneConfirmationProvider(context);
	}

	async GetStateAsync(): Promise<ActionResult> {
		return this._context.CurrentIdentity.IsAuthentificated
			? await this._defaultProvider.GetStateAsync()
			: await this._identityProvider.GetStateAsync();
	}
}
