import { injectable, inject } from 'inversify';
import IArenaService from './i-arena-service';
import GlobalService, { AppSettings, ActionResult, OrderSale } from './../';
import IArenaProvider from './i-arena-provider';
import ArenaSeance from './arena-seance';
import { ArenaSymbols } from './symbols';
import ArenaSectorsList from './arena-sectors-list';
import ArenaTariff from './arena-tariff';
import ArenaSeatsList from './arena-seats-list';
import ArenaCartItem from './arena-cart-item';

@injectable()
export default class DefaultArenaService implements IArenaService {
	private readonly _settings: AppSettings;
	protected readonly _provider: IArenaProvider;

	constructor(@inject(ArenaSymbols.ArenaProvider) provider: IArenaProvider) {
		this._provider = provider;
		this._settings = GlobalService.GetSettings<AppSettings>();
	}

	async GetSeancesAsync(): Promise<ActionResult<Array<ArenaSeance>>> {
		return await this._provider.GetSeancesAsync();
	}

	async GetSectorsAsync(seanceId: number): Promise<ActionResult<ArenaSectorsList>> {
		return await this._provider.GetSectorsAsync(seanceId);
	}

	async GetTariffsAsync(seanceId: number): Promise<ActionResult<Array<ArenaTariff>>> {
		return await this._provider.GetTariffsAsync(seanceId);
	}

	async GetSeatsAsync(seanceId: number, sectorCode: number): Promise<ActionResult<ArenaSeatsList>> {
		return await this._provider.GetSeatsAsync(seanceId, sectorCode);
	}

	async ReservateSeatAsync(arenaCartItem: ArenaCartItem): Promise<ActionResult<OrderSale>> {
		return await this._provider.ReservateSeatAsync(arenaCartItem);
	}

	async UnreservateSeatAsync(itemId: number): Promise<ActionResult> {
		return await this._provider.UnreservateSeatAsync(itemId);
	}

	async CancelOrderAsync(orderId: number): Promise<ActionResult> {
		return await this._provider.CancelOrderAsync(orderId);
	}
}
