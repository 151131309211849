import { injectable } from 'inversify';

import { IIdentity, IdentityChangedEventArgs, EmpryIdentity } from './../Identity';
import { IContext } from './../Identity';

import { WpEventHandler, WpEvent } from '../Common';
import RHIdentity from './rh-identity';
import { AppSettings } from '..';

@injectable()
export default class RHContext implements IContext {
	IsInitialized: boolean = true;
	private _identity?: RHIdentity;
	private _identityChanged: WpEventHandler<IdentityChangedEventArgs> = new WpEventHandler<IdentityChangedEventArgs>();

	get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
		return this._identityChanged;
	}
	get CurrentIdentity(): IIdentity {
		return this._identity == null ? new EmpryIdentity() : this._identity;
	}

	SignIn(): void {
		throw new Error('Method not implemented.');
	}
	SignOut(): void {
		throw new Error('Method not implemented.');
	}
	ChangeIdentity(identity: IIdentity): void {
		this._identity = identity as RHIdentity;
		let args = new IdentityChangedEventArgs(identity);

		this._identityChanged.Send(this, args);
	}

	async Initialize(appSettings: AppSettings): Promise<void> {}
}
