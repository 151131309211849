import { injectable, inject } from 'inversify';

import { RestClient } from 'typed-rest-client';
import { ActionResult, ErrorTypes, WpEventHandler, WpEvent } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';

import { IdentityChangedEventArgs } from './../Identity';
import { IContext } from './../Identity';

import IsdUserLoyalty from './isd-user-loyalty';
import ILoyaltyProvider from './i-loyalty-provider';
import IsdLoyaltyProgram from './isd-loyalty-program';
import PersonIsdLoyaltyData from './person-isd-loyalty-data';
import GlobalService from './../Global/global-service';
import { AppSettings } from './../Global/app-settings';
import PersonAchievement, { AvailableGift } from './person-achievement';
import LoyaltyChangedEventArgs from './loyalty-changed-event-args';
import SocialNetworkData from './social-network-data';
import { AccountBalance } from '.';
import { MediaCards } from './media-cards';

import { NotificationEventArgs, NotificationTypes, NotificationSymbols } from '../Notification';
import { INotificationService } from '../Notification';

@injectable()
export default class DefaultLoyaltyProvider implements ILoyaltyProvider {
	static _this: ILoyaltyProvider;
	private _context: IContext;
	protected _notificationService: INotificationService;
	private _loyaltyChanged: WpEventHandler<LoyaltyChangedEventArgs>;
	private _children: Array<PersonIsdLoyaltyData> | undefined = [];
	_currentUserLoyalty?: IsdUserLoyalty;
	private _settings: AppSettings;

	// Boilerplate code to generate RestClient
	private CreateRestClient(): RestClient {
		/* Friendly RestClient reminder
		 * .get() = GET
		 * .create() = POST
		 * .replace() = PUT
		 */
		return new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				moduleId: GlobalService.GetSettings().SiteId,
				SessionId: GlobalService.GetSettings().SessionId,
			},
		});
	}

	get LoyaltyChanged(): WpEvent<LoyaltyChangedEventArgs> {
		return this._loyaltyChanged;
	}

	constructor(
		@inject(IdentitySymbols.Context) context: IContext,
		@inject(NotificationSymbols.NotificationService) notificationService: INotificationService
	) {
		this._context = context;
		this._notificationService = notificationService;
		this._settings = GlobalService.GetSettings();

		DefaultLoyaltyProvider._this = this;

		let _this = this;
		this._loyaltyChanged = new WpEventHandler<LoyaltyChangedEventArgs>();
		this._context.IdentityChanged.Subscribe((sender: any, e: IdentityChangedEventArgs) => {
			_this._currentUserLoyalty = undefined;
		});
		this._notificationService.NotificationSended.Subscribe(this.notification);
	}

	async AddSocialNetworkDataAsync(loyaltyId: number, data: SocialNetworkData): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			var request = {
				AccountId: loyaltyId,
				ExternalId: data.ExternalId,
				Type: data.Type.toString(),
				AccessToken: data.AccessToken,
			};

			let response = await client.create<AjaxResult<string>>('/account/api/loyalty/isd/socials', request);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			this._currentUserLoyalty = undefined;
			await this.GetIsdUserLoyaltyAsync();

			return ActionResult.Success();
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async RemoveSocialNetworkDataAsync(loyaltyId: number, data: SocialNetworkData): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.del<AjaxResult<string>>(
				`/account/api/loyalty/isd/socials?accountid=${loyaltyId}&externalid=${data.ExternalId}&type=${data.Type.toString()}`
			);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			this._currentUserLoyalty = undefined;
			await this.GetIsdUserLoyaltyAsync();

			return ActionResult.Success();
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async GetIsdUserLoyaltyAsync(): Promise<ActionResult<IsdUserLoyalty>> {
		if (this._currentUserLoyalty != null) return ActionResult.SuccessData<IsdUserLoyalty>(this._currentUserLoyalty);

		try {
			let settings = GlobalService.GetSettings<AppSettings>();
			let client: RestClient = this.CreateRestClient();

			let response = await client.get<AjaxResult<IsdUserLoyalty>>(`/account/api/loyalty/isd/?orgId=${settings.CurrentSite?.OrganizationId}`);

			if (response.result != null && response.result.Result != 0) return ActionResult.FailedData<IsdUserLoyalty>(ErrorTypes.InternalError);

			this._currentUserLoyalty = response.result?.Data;

			if (this._currentUserLoyalty != null) this._loyaltyChanged.Send(this, new LoyaltyChangedEventArgs(this._currentUserLoyalty));

			if (
				response.result &&
				response.result.Data &&
				(this._context.CurrentIdentity.Phone == null || this._context.CurrentIdentity.Phone == '')
			) {
				this._context.CurrentIdentity.Phone = response.result!.Data!.Phone;
				let responseSetPhone = await client.replace<AjaxResult<number>>(`/account/api/profile/phone/`, {
					Phone: this._context.CurrentIdentity.Phone,
				});
			}

			return ActionResult.SuccessData<IsdUserLoyalty>(response.result?.Data);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	async GetProgramInfoAsync(loyaltyId: number): Promise<ActionResult<IsdLoyaltyProgram>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<IsdLoyaltyProgram>>(`/account/api/loyalty/isd/program/${loyaltyId}`);

			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

			return ActionResult.SuccessData<IsdLoyaltyProgram>(response.result?.Data);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}
	async ChangeCardName(id: number, name: string, description: string): Promise<ActionResult<any>> {
		try {
			let client: RestClient = this.CreateRestClient();
			var request = {
				id: id,
				cardName: name,
				cardDescription: description,
			};

			let response = await client.create<AjaxResult<any>>('/account/api/mediacards/change', request);
			if (response.result != null && response.result.Result != 0) {
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			return ActionResult.SuccessData<any>(response.result?.Data);
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async DeleteCard(id: number): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.del<AjaxResult<ActionResult>>(`/account/api/mediacards/${id}`);

			if (response.result != null && response.result.Result != 0) {
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			return ActionResult.Success();
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async GetCardInfo(mediaNum: string, mediaType: number): Promise<any> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<any>(
				`/account/api/mediacards/isd/info?mediaNum=${mediaNum}&mediaType=${mediaType}&siteId=${this._settings.SiteId}`
			);

			return response.result;
		} catch (e) {
			return undefined;
		}
	}

	async BindCard(mediaNum: string, name: string, description: string, mediaType?: MediaCards.MediaType): Promise<ActionResult<any>> {
		try {
			let client: RestClient = this.CreateRestClient();
			var request = {
				siteId: this._settings.SiteId,
				userId: this._context.CurrentIdentity.UserId,
				mediaNum: mediaNum,
				cardName: name,
				cardDescription: description,
				mediaType: mediaType,
			};

			let response = await client.create<AjaxResult<any>>('/account/api/mediacards/bind', request);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 9) return ActionResult.Failed(ErrorTypes.MediaCardIsExist, response.result.Message);

				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			return ActionResult.SuccessData<any>(response.result?.Data);
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async NspkState(): Promise<ActionResult<any>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<string>>(`/nspk/cards/state?userid=${this._context.CurrentIdentity.UserId}`);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 5) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}

				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			return ActionResult.SuccessData<any>(response.result?.Data);
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async RegisterNspkRequest(): Promise<ActionResult<string>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<string>>(`/nspk/cards/add?userid=${this._context.CurrentIdentity.UserId}`);
			if (response.result != null && response.result.Result != 0) {
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}

			return ActionResult.SuccessData<string>(response.result?.Data);
		} catch (e) {
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async GetCards(): Promise<Array<any>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<Array<any>>>(`/account/api/mediacards/?userid=${this._context.CurrentIdentity.UserId}`);

			if (response == null || response.result == null) return new Array<any>();

			return response.result.Data!;
		} catch (e) {
			console.error(e);
			return new Array<any>();
		}
	}

	async BindUserAsync(loyaltyId: number, confirmCodeId: number, phone: string): Promise<ActionResult<PersonIsdLoyaltyData>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.create<AjaxResult<PersonIsdLoyaltyData>>(`/account/api/loyalty/isd/binduser`, {
				LoyaltyId: loyaltyId,
				ConfirmCodeId: confirmCodeId,
				Phone: phone,
			});

			if (response.result != null) {
				if (response.result.Result === 4) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
				else if (response.result.Result == 5) return ActionResult.FailedData(ErrorTypes.NotFoundData);
				else if (response.result.Result == 6) return ActionResult.FailedData(ErrorTypes.InvalidData, response.result.Data);
				else if (response.result.Result == 58)
					return ActionResult.Failed(
						ErrorTypes.InternalError,
						'Не удалось однозначно определить пльзователя по номеру телефона. Обратитесь к администору объекта.'
					);
			}

			this._currentUserLoyalty = undefined;
			await this.GetIsdUserLoyaltyAsync();

			return ActionResult.Success();
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	async RegisterAsync(personIsdLoyaltyData: PersonIsdLoyaltyData): Promise<ActionResult<number>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.create<AjaxResult<number>>(`/account/api/loyalty/isd/register`, personIsdLoyaltyData);
			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

			this._currentUserLoyalty = undefined;
			this.GetIsdUserLoyaltyAsync();

			return ActionResult.SuccessData<number>(response.result?.Result);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	async EditAsync(personIsdLoyaltyData: PersonIsdLoyaltyData): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.create<AjaxResult<number>>(`/account/api/loyalty/isd/edit`, personIsdLoyaltyData);

			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

			this._currentUserLoyalty = undefined;
			await this.GetIsdUserLoyaltyAsync();

			return ActionResult.SuccessData();
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	async ChangeEmailAsync(loyaltyId: number, personId: number, email: string): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let data = {
				LoyaltyId: loyaltyId,
				PersonId: personId,
				Email: email,
			};
			let response = await client.replace<AjaxResult<number>>(`/account/api/loyalty/isd/changeemail`, data);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
			this._currentUserLoyalty = undefined;
			await this.GetIsdUserLoyaltyAsync();
			return ActionResult.SuccessData();
		} catch (e) {
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	/* Раздел для работы с детьми */
	/**
	 * Get the list of children in form of an Array<PersonIsdLoyaltyData>
	 * @param {boolean} recache - (Optional) Skip cache and re-request if true
	 */
	async GetChildrenAsync(recache?: boolean): Promise<ActionResult<Array<PersonIsdLoyaltyData>>> {
		// Return the array of children from cache if exists
		if (this._children && this._children.length != 0 && !recache) return ActionResult.SuccessData(this._children);

		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<Array<PersonIsdLoyaltyData>>>('/account/api/loyalty/isd/children');
			// If we got any result...
			if (response.result != null) {
				let resultCode: number = response.result.Result;
				switch (resultCode) {
					case 0:
						this._children = response.result.Data;
						return ActionResult.SuccessData(response.result.Data);
					default:
						return ActionResult.Failed(resultCode);
				}
			}
			return ActionResult.Failed(ErrorTypes.InternalError);
		} catch (e) {
			// Couldn't get/update children list
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async RemoveChildAsync(loyaltyId: number, personId: number): Promise<ActionResult> {
		try {
			if (this._children) this._children.length = 0;
			let client: RestClient = this.CreateRestClient();
			let response = await client.del<AjaxResult<string>>('/account/api/loyalty/isd/child?loyaltyId=' + loyaltyId + '&personId=' + personId);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
			return ActionResult.Success();
		} catch (e) {
			// Couldn't remove child
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async AddChildAsync(child: any): Promise<ActionResult> {
		try {
			if (this._children) this._children.length = 0;
			let client: RestClient = this.CreateRestClient();
			let response = await client.create<AjaxResult<string>>('/account/api/loyalty/isd/child', child);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
			return ActionResult.Success();
		} catch (e) {
			// Couldn't add child
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async UpdateChildAsync(child: any): Promise<ActionResult> {
		try {
			if (this._children) this._children.length = 0;
			let client: RestClient = this.CreateRestClient();
			let response = await client.replace<AjaxResult<string>>('/account/api/loyalty/isd/child', child);
			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) {
					return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
				}
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
			return ActionResult.Success();
		} catch (e) {
			// Couldn't update child
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	// http://redmine.isd.su/issues/8104 - Настройка оповещений
	async UpdateNotificationSettings(type: string, value: boolean): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let setting = {
				AccountId: this._currentUserLoyalty!.Id,
				Type: type,
				Enabled: value,
			};
			let query = await client.replace<AjaxResult<string>>('/account/api/loyalty/isd/notification', setting);
			if (query.result && query.result.Result == 0) {
				this._currentUserLoyalty = undefined;
				await this.GetIsdUserLoyaltyAsync();

				return ActionResult.Success();
			}
		} catch (e) {
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
		return ActionResult.Failed(ErrorTypes.InternalError, 'Не удалось изменить настройки уведомлений');
	}

	async SetDeviceIdAsync(deviceId: string, pushToken: string, platform: string): Promise<ActionResult> {
		try {
			let client: RestClient = this.CreateRestClient();
			let data = { token: pushToken, deviceid: deviceId, platform: platform };
			await client.create<any>(`/mobile/device/`, data);
			return ActionResult.SuccessData();
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	// http://redmine.isd.su/issues/8306 - Вывод достижений
	async GetPersonsAchievements(tnodId: number, loyaltyId: number, contractId?: number): Promise<ActionResult<Array<PersonAchievement>>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<Array<PersonAchievement>>>(
				`/account/api/loyalty/isd/achievements?tnodid=${tnodId}&contractid=${contractId}&loyaltyId=${loyaltyId}`
			);
			if (response.result == null || response.result.Result != 0 || response.result.Data == null) {
				return ActionResult.Failed(ErrorTypes.InternalError, response.result?.Message);
			}
			return ActionResult.SuccessData(response.result?.Data);
		} catch (e) {
			// Couldn't update child
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	async RegisterGift(gift: AvailableGift, loyalty: IsdUserLoyalty, PerfAchievementId: number, kind?: number): Promise<ActionResult> {
		let client: RestClient = this.CreateRestClient();

		let type = kind == 1 ? 'coupon' : 'gift';
		let url = `/account/api/loyalty/isd/${type}/register`;
		let data =
			kind == 1
				? {
						CshdId: gift.CshdId,
						PerfAchievementId: PerfAchievementId,
						TnodId: gift.TnodId,
						UserLoyaltyId: loyalty.Id,
						CouponId: gift.Id,
						TCoupId: gift.TcoupId,
				  }
				: {
						CshdId: gift.CshdId,
						PerfAchievementId: PerfAchievementId,
						TnodId: gift.TnodId,
						UserLoyaltyId: loyalty.Id,
						GiftId: gift.Id,
				  };

		let register = await client.create<AjaxResult<any>>(url, data);
		if (register.statusCode == 200 && register.result && register.result.Result == 0) return ActionResult.Success();
		else return ActionResult.FailedData(ErrorTypes.InternalError, register.result!.Message!);
	}

	async GetAccountBalanceAsync(tnodid: number, personId: number, loyaltyId: number): Promise<ActionResult<AccountBalance>> {
		try {
			let client: RestClient = this.CreateRestClient();
			let response = await client.get<AjaxResult<AccountBalance>>(
				'/account/api/loyalty/isd/account/balance/?loyaltyId=' + loyaltyId + '&personId=' + personId + '&tnodid=' + tnodid
			);
			if (response.result != null && response.result.Result != 0) {
				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
			return ActionResult.SuccessData(response.result?.Data);
		} catch (e) {
			console.error(e);
			return ActionResult.Failed(ErrorTypes.InternalError);
		}
	}

	private async notification(sender: any, e: NotificationEventArgs): Promise<void> {
		if (e.Data.Type == NotificationTypes.System) {
			if (e.Data.SystemProcessKey == 'SaleComplete') {
				DefaultLoyaltyProvider._this._currentUserLoyalty = undefined;
				DefaultLoyaltyProvider._this.GetIsdUserLoyaltyAsync();
			}
		}
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
