import { RestClient } from 'typed-rest-client';
import { ActionResult, ErrorTypes } from './../Common';
import { AppSettings } from '../Global/app-settings';
import GlobalService from '../Global/global-service';

export default class IdentityAccountProvider {
	private _settings: AppSettings;

	constructor() {
		this._settings = GlobalService.GetSettings<AppSettings>();
	}

	async ResetPasswordByPhone(newPassword: string, confirmPassword: string): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				'session-id': this._settings.SessionId,
				moduleId: this._settings.SiteId,
				'wp.language': this._settings.Locale,
			},
		});

		let response = await client.create<AjaxResult<string>>(`/idsrv/account/resetpasswordbyphone`, {
			NewPassword: newPassword,
			ConfirmPassword: confirmPassword,
		});

		if (response.statusCode == 404) return ActionResult.Failed(ErrorTypes.NotFoundData, 'Срок действия кода истек, попробуйте повторить попытку');

		if (response.result != null && response.result.Result != 'ok') {
			if (response.result.Result == 'invalid_data') return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);

			return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
		}

		return ActionResult.SuccessData();
	}

	async ResetPasswordByEmail(email: string, organizationId: number): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				'session-id': this._settings.SessionId,
				moduleId: this._settings.SiteId,
				'wp.language': this._settings.Locale,
			},
		});

		let response = await client.create<AjaxResult<string>>(`/idsrv/account/resetpasswordbyemail`, {
			Email: email,
			OrganizationId: organizationId,
		});

		if (response.result != null && response.result.Result != 'ok') {
			if (response.result.Result == 'invalid_data') return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);

			return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
		}
		return ActionResult.SuccessData();
	}

	async Registration(email: string, password: string, confirmPassword: string, organizationId: number): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				'session-id': this._settings.SessionId,
				moduleId: this._settings.SiteId,
				'wp.language': this._settings.Locale,
			},
		});

		let response = await client.create<AjaxResult<string>>(`/idsrv/account/registration`, {
			Email: email,
			Password: password,
			ConfirmPassword: confirmPassword,
			OrganizationId: organizationId,
		});

		if (response.statusCode == 404) return ActionResult.Failed(ErrorTypes.NotFoundData, 'Срок действия кода истек, попробуйте повторить попытку');

		if (response.result != null && response.result.Result != 'ok') {
			if (response.result.Result == 'invalid_data') return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
			if (response.result.Result == 'user_exist') return ActionResult.Failed(ErrorTypes.UserAlreadyExists, 'Пользователь уже существует');
			return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
		}

		return ActionResult.SuccessData();
	}
}

class AjaxResult<T> {
	Result: string = '';
	Message: string = '';
	Data?: T;
}
