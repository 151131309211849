import { injectable, inject } from 'inversify';
import MarketCompany from './market-company';
import { ActionResult, ErrorTypes } from '../Common';
import MarketSchedule from './market-schedule';
import IMarketProvider from './i-market-provider';
import { MarketSymbols } from './symbols';
import { MarketClient, MarketTariffCartItem, IMarketService, MarketBill, MarketIdentity } from '.';
import { OrderSale } from '..';
import { IdentitySymbols } from '../Identity';
import { IContext } from '../Identity';

@injectable()
export default class MarketService implements IMarketService {
	protected readonly _context: IContext;
	protected readonly _provider: IMarketProvider;

	constructor(@inject(MarketSymbols.MarketProvider) provider: IMarketProvider, @inject(IdentitySymbols.Context) context: IContext) {
		this._provider = provider;
		this._context = context;
	}

	async GetRulesAsync(): Promise<ActionResult<MarketCompany>> {
		return await this._provider.GetRulesAsync();
	}
	async GetRuleScheduleAsync(id: number, date: string): Promise<ActionResult<MarketSchedule>> {
		return await this._provider.GetRuleScheduleAsync(id, date);
	}

	async UnreservateTariffAsync(itemId: number): Promise<ActionResult> {
		return await this._provider.UnreservateTariffAsync(itemId);
	}

	async ReservateTariffAsync(marketTariffCartItem: MarketTariffCartItem): Promise<ActionResult<OrderSale>> {
		return await this._provider.ReservateTariffAsync(marketTariffCartItem);
	}

	async GetClientAsync(phone: string): Promise<ActionResult<MarketClient>> {
		return await this._provider.GetClientAsync(phone);
	}

	async Get(phone: string): Promise<ActionResult<MarketClient>> {
		return await this._provider.GetClientAsync(phone);
	}

	async InitializeByBillAsync(billId: number): Promise<ActionResult<MarketBill>> {
		let result = await this._provider.InitializeByBill(billId);

		if (!result.Success || result.Data == null)
			return ActionResult.FailedData(result.ErrorType ?? ErrorTypes.InternalError, new MarketBill(), result.ErrorMessage);

		let marketIdentity = new MarketIdentity(result.Data);

		this._context.ChangeIdentity(marketIdentity);

		return ActionResult.SuccessData(result.Data.Bill);
	}
}
