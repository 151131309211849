export enum ErrorTypes {
	InternalError = 1,
	SystemError = 2,
	AccessDenied = 3,
	NotFoundData = 5,
	InvalidData = 6,
	CartError = 7,
	TariffNotAvailableForSale = 8,
	QuotaExceededForTariff = 9,
	SeatIsOccupied = 10,
	MediaCardIsLocked = 11,
	MediaCardNotFound = 12,
	MediaCardExpired = 13,
	MediaCardInProcess = 14,
	CartItemsConflict = 15,
	CardWithSoTypeTariff = 16,
	RewriteTariffNotAvailable = 17,
	LessonNotAvailable = 18,
	FreeTicketLimit = 10000,
	SiteOrOrganizationDisabled = 19,
	MediaCardIsExist = 20,
	MediaCardIsBanned = 21,
	UserAlreadyExists = 22, // 22 is arbitrary
}
