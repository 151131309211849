import Vue from 'vue';
import WpPageBaseComponent from '../wp-page-base-component';
import Component, { mixins } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import GlobalService, {
	IContext,
	IdentitySymbols,
	INotificationService,
	NotificationSymbols,
	ILoyaltyProvider,
	LoyaltySymbols,
	NotificationEventArgs,
	NotificationTypes,
	AppSettings,
	IsdUserLoyalty,
	ErrorTypes,
	MediaCards,
} from 'web-platform-core-ui';

export const WpClientsCardsProp = Vue.extend({});

@Component({
	template: require('./wp-clients-cards.html'),
})
export default class WpClientsCards extends mixins(WpPageBaseComponent, WpClientsCardsProp, Vue) {
	protected _notificationService!: INotificationService;
	protected _loyaltyProvider!: ILoyaltyProvider;
	protected _context!: IContext;
	protected _settings!: AppSettings;

	protected _refreshCardsFunc: any;

	UserLoyalty!: IsdUserLoyalty;
	Cards: Array<any> = [];
	FullName: string = '';
	NewCardNumber: string = '';

	AddCardStep: any = 0;
	ReadingNfc: boolean = false;
	WaitNewCardError: boolean = false;
	WaitNewCardErrorMessage: string = '';
	WaitNewCard: boolean = false;
	NewCardDialog: boolean = false;
	NewCardChooseDialog: boolean = false;
	NewCardFormDialog: boolean = false;
	AddNewCardBtnLoading: boolean = false;

	CardDeleteDialog: boolean = false;
	CardToDelete?: any;

	CardInfoTitle: string = '';
	CardInfoDialog: boolean = false;
	CardInfoLoading: boolean = true;
	CardInfo?: any;

	Loading: boolean = true;

	@Ref('NewCardForm') NewCardForm!: HTMLFormElement;

	get IsCardValid(): boolean {
		return this.NewCardNumber !== '' && /^[a-fA-F0-9]+$/.test(this.NewCardNumber);
	}

	get Rules(): any {
		return {
			required: (value: string | null) => (value && value !== '' && /^[a-fA-F0-9]+$/.test(value)) || 'Номер карты должен содержать A-F и цифры',
			minimum8: (value: string | null) => (value && value.length >= 8) || 'Минимальная длина номера карты - 8 символов',
		};
	}

	async mounted(): Promise<void> {
		this._settings = GlobalService.GetSettings();
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);

		this.Loading = true;
		let result = await this._loyaltyProvider.GetIsdUserLoyaltyAsync();

		if (result.Success && result.Data != null) {
			this.UserLoyalty = result.Data;
			this.FullName = `${this.UserLoyalty.LastName} ${this.UserLoyalty.FirstName} ${this.UserLoyalty.SecondName}`;

			let cards = await this._loyaltyProvider.GetCards();
			cards.forEach((x) => {
				if (x.Data.MediaType == 41 && x.Name == x.Description) {
					console.log(x);
					x.Name = '';
				}
				x.TempName = x.Name;
			});
			this.Cards = cards;

			let length = localStorage.getItem('new_card');
			localStorage.removeItem('new_card');

			if (length != null) {
				this.NewCardDialog = true;

				if (length == this.Cards.length.toString()) {
					this.WaitNewCard = true;

					this._refreshCardsFunc = setInterval(async () => {
						let nspkCardState = await this._loyaltyProvider.NspkState();

						if (nspkCardState.Success && nspkCardState.Data != null) {
							let cards = await this._loyaltyProvider.GetCards();
							this.WaitNewCard = false;
							cards.forEach((x) => {
								if (x.Data.MediaType == 41 && x.Name == x.Description) x.Name = '';
								x.TempName = x.Name;
							});
							this.Cards = cards;
							clearInterval(this._refreshCardsFunc);
							this._refreshCardsFunc = null;
						}

						if (!nspkCardState.Success) {
							if (nspkCardState.ErrorType != ErrorTypes.InvalidData) {
								this.WaitNewCardError = true;
								this.WaitNewCardErrorMessage = nspkCardState.ErrorMessage ?? 'Произошла непредвиденная ошибка';
								this.WaitNewCard = false;
							}

							clearInterval(this._refreshCardsFunc);
							this._refreshCardsFunc = null;
						}
					}, 5000);
				} else {
					this.WaitNewCard = false;
				}
			}
		} else {
			this._notificationService.Error('', result?.ErrorMessage ?? 'Произошла непредвиденная ошибка');
		}

		this._notificationService.NotificationSended.Subscribe(async (sender, e) => {
			if (e.Data.SystemProcessKey == 'NewUserCardAdded') {
				let cards = await this._loyaltyProvider.GetCards();
				cards.forEach((x) => {
					if (x.Data.MediaType == 41 && x.Name == x.Description) {
						console.log(x);
						x.Name = '';
					}
					x.TempName = x.Name;
				});
				this.Cards = cards;
			}
		});

		this.Loading = false;
	}

	CardDataChanged(d: any): void {
		this.$nextTick(() => {
			// Убираем русский алфавит и латиницу после буквы G, как и пробелы
			//this.Card = this.Card.toUpperCase().replace(/[А-ЯG-Z]/gi, " ").replace(" ", "");
			// Убираем просто пробелы
			this.NewCardNumber = this.NewCardNumber?.toUpperCase().replace(' ', '');
		});
	}

	async AddNewNspkCard() {
		localStorage.setItem('new_card', `${this.Cards.length}`);

		var result = await this._loyaltyProvider.RegisterNspkRequest();
		if (result.Success && result.Data != null) location.href = result.Data;
	}

	BindNewCardClick() {
		if (!this._settings.NspkEnabled) this.SimpleNewCardBtnClick();
		this.NewCardChooseDialog = true;
	}

	async SimpleNewCardBtnClick() {
		if (this._settings.NfcSupported) {
			this.AddCardStep = 1;
		} else {
			this.NewCardChooseDialog = false;
			this.NewCardFormDialog = true;
		}
	}

	async AddNewCard() {
		if (!this.NewCardForm.validate()) return;

		this.AddNewCardBtnLoading = true;

		// MediaCards.MediaType.BLUEPASS
		var result = await this._loyaltyProvider.BindCard(this.NewCardNumber, '', '', MediaCards.MediaType.TagIt);
		if (!result.Success || result.Data == null) {
			this._notificationService.Error('', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');
			this.AddNewCardBtnLoading = false;
			return;
		}

		this.WaitNewCard = false;
		this.NewCardChooseDialog = false;
		this.NewCardFormDialog = false;
		this.NewCardDialog = true;
		result.Data!.TempName = result.Data!.Name;
		this.Cards.push(result.Data!);
		this.NewCardForm.reset();

		this.AddNewCardBtnLoading = false;
	}

	// BLUEPASS METHODS
	// https://redmine.moipass.ru/issues/13074
	protected BluepassEnabled = true;
	protected LoadingBluepass = false;
	protected DialogBluepass = false;
	protected NumberBluepass: string | null = ''; // v-text-field strings can be null if cleared by the button via "clearable" attribute
	protected FormBluepassValid = false;
	@Ref('FormBluepass') FormBluepass?: HTMLFormElement;
	async AddBluepass() {
		this.DialogBluepass = true;
	}
	SubmitBluepass(event: Event) {
		event.preventDefault();
		console.log('Submit bluepass');
	}
	InputBluepass(_value: string): void {
		this.$nextTick(() => {
			if (!this.NumberBluepass) return;
			this.NumberBluepass = this.NumberBluepass.toUpperCase().replace(' ', '');
		});
	}
	async BtnAddBluepass() {
		if (!this.NumberBluepass) return this._notificationService.Error('', 'Поле номера карты не может быть пустым');

		try {
			this.LoadingBluepass = true;

			var result = await this._loyaltyProvider.BindCard(this.NumberBluepass, '', '', MediaCards.MediaType.BLUEPASS);
			if (!result.Success || result.Data == null)
				return this._notificationService.Error('Bluepass', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');

			this.FormBluepass?.reset();
			result.Data.TempName = result.Data.Name;
			this.Cards.push(result.Data);

			this.DialogBluepass = this.NewCardChooseDialog = false;
			this._notificationService.Success('Успех', 'Операция прошла успешно');
		} catch (error) {
			let message = '';

			if (error instanceof Error) {
				message = error.message;
			} else if (typeof error === 'string') {
				message = error;
			}

			this._notificationService.Error('', message ?? 'Unknown error');
		} finally {
			this.LoadingBluepass = false;
		}
	}
	// END: Bluepass methods...

	async ChangeName(card: any, input: any) {
		if (card.TempName == card.Name) {
			this.$nextTick(() => {
				(this.$refs[`carditem${card.Id}`] as any)[0].focus();
			});
			return;
		}

		var newName = card.Data.MediaType == 41 && (card.TempName == null || card.TempName == '') ? card.Description : card.TempName;

		var result = await this._loyaltyProvider.ChangeCardName(card.Id, newName, card.Description);
		if (result.Success) this._notificationService.Success('', 'Изменения созранены');
		else this._notificationService.Error('', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');

		card.Name = card.TempName;
	}

	SelectCardToDelete(card: any) {
		this.CardToDelete = card;
		this.CardDeleteDialog = true;
	}

	async DeleteCard() {
		if (this.CardToDelete == null) return;

		var result = await this._loyaltyProvider.DeleteCard(this.CardToDelete.Id);
		if (result.Success) this._notificationService.Success('', 'Карта удалена');
		else this._notificationService.Error('', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');

		this.Cards = this.Cards.filter((x) => x.Id != this.CardToDelete.Id);
		this.CardDeleteDialog = false;
		this.CardToDelete = undefined;
	}

	async CheckForm(e: any): Promise<void> {
		e.preventDefault();

		await this.AddNewCard();
	}

	GetDate(date: string) {
		return this.DateHandler.Parse(date.split('T')[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
	}

	OpenWidget(mediaNum: string) {
		this._settings.OnlyTab = ['rewrite']; // Force rewrite? (May be incorrect business logic)
		this._notificationService.SendSystemNotification('OpenWidget', mediaNum);
	}

	NewCardDialogClose() {
		this.NewCardDialog = false;
	}

	async InfoCard(card: any) {
		this.CardInfoTitle = card.Name && card.Name != '' ? card.Name : card.Data.MediaNum;
		this.CardInfoLoading = true;
		this.CardInfoDialog = true;
		this.CardInfo = undefined;
		card = await this._loyaltyProvider.GetCardInfo(card.Data.MediaNum, card.Data.MediaType);

		if (card != null && card.root && card.root.AnswerResult) {
			this.CardInfo = card.root.AnswerResult.InfoFromTicketHtml;
		}

		this.CardInfoLoading = false;
	}

	ReadNFC() {
		this.ReadingNfc = true;
		this.NewCardChooseDialog = false;
		startReadNFC();
	}

	EndReadNFC() {
		this.ReadingNfc = false;
		endReadNFC();
	}

	async notification(_sender: any, e: NotificationEventArgs): Promise<void> {
		if (e.Data.Type == NotificationTypes.System) {
			if (e.Data.SystemProcessKey == 'NfcReaded') {
				if (this.ReadingNfc) {
					this.NewCardNumber = e.Data.AdditionalData!;
					this.AddNewCard();
				}
			}
		}
	}
}
