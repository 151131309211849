import './wp-login-page.scss';

import Vue, { PropOptions } from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from './../wp-base-component';
import GlobalService, {
	AppSettings,
	ModifiedWebSaleUserIdentity,
	IContext,
	IdentitySymbols,
	IdentityLoginState,
	NotificationSymbols,
	INotificationService,
	NotificationEventArgs,
	NotificationTypes,
} from 'web-platform-core-ui';
import { Watch } from 'vue-property-decorator';

export const wpLoginDialogProps = Vue.extend({
	props: {
		CanClose: <PropOptions>{
			type: Boolean,
			default: true,
		},
		ShowDialog: <PropOptions>{
			type: Boolean,
			default: false,
		},
	},
});

export enum WpLoginDialogState {
	Hidden = 0,
	Show = 1,
}

@Component({
	template: require('./wp-login-dialog.html'),
})
export default class WpLoginDialog extends mixins(WpBaseComponent, wpLoginDialogProps, Vue) {
	private _context?: ModifiedWebSaleUserIdentity;

	Step: number = 1;
	Url: string = '';
	Snackbar: boolean = false;
	Dialog: boolean = false;

	Settings!: AppSettings;

	mounted() {
		this.Settings = GlobalService.GetSettings();
		this.Url = `/organization/logo/${this.Settings.SiteId}`;

		if (this.GetItem('identity-login-warn-message') != null) this.Snackbar = false;
		else this.Snackbar = true;

		this.Dialog = this.ShowDialog;
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context) as ModifiedWebSaleUserIdentity;
		if (this._context != null) {
			let dialog = this;
			this._context.SignInCalled.Subscribe((sender, e) => {
				dialog.Dialog = true;
			});
		}

		let pageNotifitcationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		pageNotifitcationService.NotificationSended.Subscribe(this.notification);
	}

	notification(sender: any, e: NotificationEventArgs): void {
		if (e.Data.Type == NotificationTypes.System && e.Data.SystemProcessKey == 'AndroidBackButtonPressed') this.AppGoBack();
	}

	ImgError(e: any) {
		e.target.src = 'https://info.moipass.ru/images/webplatform.png';
	}

	NotShow() {
		this.SetItem('identity-login-warn-message', 'false');
		this.Snackbar = false;
	}

	AppGoBack(): void {
		if (this.Step != 1) this.Step = 1;
		else this.Dialog = false;
	}

	@Watch('Dialog')
	onPropertyChanged(value: boolean, oldValue: boolean) {
		IdentityLoginState.IsLoginShow = value;
	}

	GetItem(key: string): string | null {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	SetItem(key: string, value: string) {
		try {
			return localStorage.setItem(key, value);
		} catch (e) {
			console.error(e);
		}
	}

	GoToPasswordRecovery() {
		IdentityLoginState.IsResetPassword = true;
		this.Step = 2;
	}
}
