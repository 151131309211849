import Vue from 'vue';
import { Inject } from 'vue-property-decorator';
import ComponentVue from 'vue-class-component';

import GlobalService, {
	GlobalSymbols,
	LocalizationStaticDictionary,
	ILocalizationService,
	LocalizationSymbols,
	IDateHandler,
	DateHandlerSymbols,
	BaseSystemLocalizationDictionary,
	Localization,
	formatDateIsoToRu,
	formatTimeIsoToRu,
	formatPrice,
	INotificationService,
	NotificationSymbols,
} from 'web-platform-core-ui';

@ComponentVue
export default class WpBaseComponent<T extends BaseSystemLocalizationDictionary = BaseSystemLocalizationDictionary> extends Vue {
	protected _notificationService!: INotificationService;
	_formatDateIsoToRu = formatDateIsoToRu;
	_formatTimeIsoToRu = formatTimeIsoToRu;
	_formatPrice = formatPrice;

	@Inject(GlobalSymbols.GlobalService)
	GlobalService!: GlobalService;

	Terms: T = {} as T;

	get DateHandler(): IDateHandler {
		return this.GlobalService.Get<IDateHandler>(DateHandlerSymbols.DateHandlerService);
	}

	get Locale(): Localization {
		return this.GlobalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService).CurrentLocalization;
	}

	get LocalizationService(): ILocalizationService {
		return this.GlobalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
	}

	get InputRules() {
		return { NotEmpty: (text: string) => text != '' || 'Значение не может быть пустым' };
	}

	constructor() {
		super();
		if (this.GetTermsKey != null) {
			let localizationService = this.GlobalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
			let result = localizationService.GetStaticDictionary<T>(Symbol.for(this.GetTermsKey()));
			if (result.Success && result.Data != null) this.Terms = result.Data.Terms;
		}
	}

	created(): void {
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	}

	protected GetTermsKey() {
		if (this.$options != null) return (<any>this.$options)._componentTag;
	}

	// Save text to clipboard so it can be pasted with Ctrl+V or such
	protected async CopyToClipboard(text: string): Promise<boolean> {
		try {
			if (!navigator.clipboard) {
				// Create text area so we can expose .select() method
				let textArea = document.createElement('textarea');
				// Add text to copy to clipboard to text area
				textArea.value = text;
				// Prevent scrolling with fixed position (Possibly top: 0 and left: 0 are needed)
				textArea.style.position = 'fixed';
				// Append textarea to body
				document.body.appendChild(textArea);
				// Focus and select text from textarea
				textArea.focus();
				textArea.select();
				// Copy to clipboard
				try {
					document.execCommand('copy');
				} catch (e) {
					console.error(e);
				}
				// Remove textarea as it's no longer needed
				document.body.removeChild(textArea);
			} else {
				await navigator.clipboard.writeText(text).then(
					() => {
						/* Success */
					},
					function (error) {
						console.error('Не удалось скопировать в буффер обмена', error);
						return false;
					}
				);
			}
			return true;
		} catch (error) {
			console.error('We tried to copy text to clipboard but got theese shenanigans: ', error);
		}
		return false;
	}

	protected FormatPrice(num: number | string): string {
		let match = num
			.toString()
			.replace(/ /g, '')
			.split('')
			.reverse()
			.join('')
			.match(/.{1,3}/g);
		if (match) {
			return match.join(' ').split('').reverse().join('').toString();
		} else {
			return '';
		}
	}

	// For debugging purposes mostly...
	async Sleep(ms: number): Promise<void> {
		await new Promise((r) => setTimeout(r, ms));
	}
}
