import { Cart, CartItemTypes } from './../Cart';
import { IdentitySymbols } from './../Identity/symbols';
import { ActionResult } from './../Common';

import { IContext } from './..';
import GlobalService from './..';

import { IDateHandler } from '../Utility';
import { DateHandlerSymbols } from '../Utility';

import { ISaleProvider } from './';
import { SaleService, SaleSymbols, ReservationItem, ReservationData, OrderSale } from './';

import { inject, injectable } from 'inversify';
import { TariffCartItem } from '../Tariffs';
import BenefitAppSettings from './benefit-app-settings';

@injectable()
export default class BenefitSaleService extends SaleService {
	constructor(
		@inject(SaleSymbols.SaleProvider) saleProvider: ISaleProvider,
		@inject(IdentitySymbols.Context) context: IContext,
		@inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler
	) {
		super(saleProvider, context, dateHandler);
	}

	protected async DefaultReservate(cart: Cart, personId?: number): Promise<ActionResult<OrderSale>> {
		let items = new Array<ReservationItem>();

		cart.Items.forEach((x) => {
			if (x.Type == CartItemTypes.Tariff) {
				let itemTariff = x as TariffCartItem;
				let newItems = x.GetReservationItem();
				newItems.forEach((y) => (y.TariffId = itemTariff.Tariff.TariffCode));
				items = items.concat(newItems);
			} else items = items.concat(x.GetReservationItem());
		});

		items.forEach((x) => {
			x.Date = this._dateHandler.Parse(x.Date, 'DD.MM.YYYY').Format('YYYY-MM-DD');
		});

		/**/

		let setting = GlobalService.GetSettings<BenefitAppSettings>();
		let data = new ReservationData();

		data.UserId = setting.BenefitDocument;
		data.Items = items;
		data.PersonId = personId;

		let result = await this._saleProvider.ReservateAsync(data);
		return result;
	}
}
