import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ILoyaltyProvider,
	LoyaltySymbols,
	IsdUserLoyalty,
	PersonIsdLoyaltyData,
	IsdLoyaltyProgram,
	INotificationService,
	NotificationSymbols,
	AppSettings,
	BarcodeType,
	IdentitySymbols,
	ErrorTypes,
} from 'web-platform-core-ui';
import { IContext } from 'web-platform-core-ui';
import WpPageBaseComponent from '../../wp-page-base-component';
import { Ref } from 'vue-property-decorator';

export const wpIsdLoyaltyProps = Vue.extend({
	props: {
		ReturnRouteName: {
			type: String,
			default: '',
		},
	},
});

@Component({
	template: require('./wp-isd-loyalty.html'),
})
export default class WpIsdLoyalty extends mixins(WpPageBaseComponent, Vue, wpIsdLoyaltyProps) {
	private pageRegRes: string = 'loaylty-reg-res.html';
	_context!: IContext;
	_provider!: ILoyaltyProvider;
	_notificationService!: INotificationService;

	IsdLoyaltyProgram!: IsdLoyaltyProgram;
	IsdUserLoyalty!: IsdUserLoyalty;

	ChangeEmailLoading: boolean = false;
	ChangeEmailFormValid: boolean = true;
	@Ref('ChangeEmailForm') ChangeEmailForm!: HTMLFormElement;

	PersonalDataStep: number = 1;
	LoyaltyId: number | undefined = 0;

	LoyaltyExist: boolean = false;
	LoyaltyRegister: boolean = false;

	BarcodeUrl: string = '';
	FirstName: string = '';
	LastName: string = '';
	SecondName: string = '';
	Phone: string = '';
	Birthdate: string = '';
	City: string = '';
	Gender: string = '';

	EnableUsingBonuses: boolean = true;
	BirthdateMenu: boolean = false;
	CurrentDate: string = '';

	Balance: number = 0;
	Discount: string = '';

	ProgramName: string = '';
	RuleAddress: string = '';

	OrganizationSiteUrl: string = '';
	OrganizationName: string = '';
	OrganizationEmail: string = '';
	OrganizationPhone: string = '';

	WidgetUrl: string = '';

	Email: string = '';
	NewEmail: string = '';
	EditModal: boolean = false;

	get EmailRules() {
		return [(v: string) => !!v || 'Введите e-mail', (v: string) => /.+@.+\..+/.test(v) || 'Введите e-mail'];
	}

	created() {
		this.CurrentDate = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
	}

	async mounted() {
		await this.GetLoyalty();
	}

	async Registered(IsRegistered: boolean) {
		if (this.ReturnRouteName != '') {
			this.$router.push({ name: this.ReturnRouteName });
		} else {
			await this.GetLoyalty();
		}
	}

	Edited(isdPersonData: PersonIsdLoyaltyData) {
		this.IsdUserLoyalty.FirstName = isdPersonData.FirstName;
		this.IsdUserLoyalty.LastName = isdPersonData.LastName;
		this.IsdUserLoyalty.SecondName = isdPersonData.SecondName;
		if (isdPersonData.Birthdate != null) this.IsdUserLoyalty.Birthdate = this.DateHandler.ParseToDate(isdPersonData.Birthdate, 'DD.MM.YYYY');
		this.IsdUserLoyalty.City = isdPersonData.City;
		if (isdPersonData.Gender != null && isdPersonData.Gender > 0 && isdPersonData.Gender < 3) this.IsdUserLoyalty.Gender = isdPersonData.Gender;

		this.FirstName = isdPersonData.FirstName;
		this.LastName = isdPersonData.LastName;
		this.SecondName = isdPersonData.SecondName;
		this.Birthdate = isdPersonData.Birthdate != null ? isdPersonData.Birthdate : '';
		this.City = isdPersonData.City;
		if (isdPersonData.Gender != null && isdPersonData.Gender > 0 && isdPersonData.Gender < 3)
			this.Gender = isdPersonData.Gender == 1 ? 'мужской' : 'женский';

		this._notificationService.SendSystemNotification('IsdLoyaltyDataChanged', this.IsdUserLoyalty);

		this.PersonalDataStep = 1;
	}

	async GetLoyalty() {
		this.PageLoading = true;
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		let getProgramInfoAsync = await this._provider.GetProgramInfoAsync(GlobalService.GetSettings<AppSettings>().LoyaltyId);

		this.Email = this._context.CurrentIdentity.Username;
		this.NewEmail = this._context.CurrentIdentity.Username;

		if (getProgramInfoAsync.Data !== null && getProgramInfoAsync.Data !== undefined) {
			this.IsdLoyaltyProgram = getProgramInfoAsync.Data;

			if (getProgramInfoAsync.Data?.LoyaltyProgramId !== undefined) {
				this.LoyaltyId = getProgramInfoAsync.Data?.LoyaltyProgramId;
			}
		} else {
			this._notificationService.Error(
				'Ошибка',
				getProgramInfoAsync != null && getProgramInfoAsync.ErrorMessage != null
					? getProgramInfoAsync.ErrorMessage
					: 'Произошла непредвиденная ошибка'
			);
		}

		let result = await this._provider.GetIsdUserLoyaltyAsync();

		if (result.Success) {
			if (result.Data != null) {
				this.IsdUserLoyalty = result.Data;
				this._notificationService.SendSystemNotification('IsdLoyaltyDataChanged', this.IsdUserLoyalty);
				this.LoyaltyExist = true;

				this.FirstName = result.Data.FirstName;
				this.LastName = result.Data.LastName;
				this.SecondName = result.Data.SecondName;
				this.Birthdate = result.Data.Birthdate != null ? result.Data.Birthdate.toString() : '';
				this.City = result.Data.City;
				if (result.Data.Gender != null && result.Data.Gender > 0 && result.Data.Gender < 3)
					this.Gender = result.Data.Gender == 1 ? 'мужской' : 'женский';
				this.Balance = result.Data.Balance;
				this.Discount = result.Data.Discount;
				this.Phone = result.Data.Phone;
				this.ProgramName = result.Data.ProgramName;
				this.OrganizationName = result.Data.OrganizationName;
				this.OrganizationEmail = result.Data.OrganizationEmail;
				this.OrganizationPhone = result.Data.OrganizationPhone;
				this.RuleAddress = result.Data.RuleAddress;
				this.EnableUsingBonuses = result.Data.EnableUsingBonuses;
				this.BarcodeUrl = `https://mailer.moipass.ru/barcode/${this.GetStringBarcodeType(result.Data.BarcodeType)}Img/?v=${
					result.Data.CardNumber
				}`;
			} else {
				this.LoyaltyRegister = true;
			}
		}
		this.PageLoading = false;
	}

	GetStringBarcodeType(barcodeType: BarcodeType) {
		if (barcodeType == BarcodeType.NoBarcode) return 'NoBarcode';
		else if (barcodeType == BarcodeType.Code128B) return 'Code128B';
		else if (barcodeType == BarcodeType.Interleaved2of5) return 'Interleaved2of5';
		else return 'QRCode';
	}

	FormatDate(date: string): string {
		if (date !== null && date !== '') {
			return this.DateHandler.Parse(date, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY');
		} else {
			return '';
		}
	}
	FormatPrice(Price: number): string {
		return parseFloat(Price.toString()).toFixed(2).toString().replace(',', '.');
	}

	openRule() {
		if (this.RuleAddress !== '') {
			try {
				// @ts-ignore
				openInBrowser(this.RuleAddress);
			} catch {
				window.open(this.RuleAddress, '_blank');
			}
		}
	}

	callTo() {
		if (this.OrganizationPhone !== '') {
			try {
				// @ts-ignore
				openInBrowser(`callto:${this.OrganizationPhone}`);
			} catch {
				window.location.href = `callto:${this.OrganizationPhone}`;
			}
		}
	}

	mailTo() {
		if (this.OrganizationEmail !== '') {
			try {
				// @ts-ignore
				openInBrowser(`mailto:${this.OrganizationEmail}`);
			} catch {
				window.location.href = `mailto:${this.OrganizationEmail}`;
			}
		}
	}

	async ChangeEmailFormSubmit(e: any) {
		e.preventDefault();
		await this.ChangeEmail();
	}

	async ChangeEmail() {
		if (!this.ChangeEmailForm.validate()) return;

		this.ChangeEmailLoading = true;

		if (this.LoyaltyId != null) {
			let result = await this._provider.ChangeEmailAsync(this.LoyaltyId, this.IsdUserLoyalty.PersId, this.NewEmail);
			if (result.Success) {
				this.Email = this.NewEmail;

				this._context.SignOut();
			} else {
				if (result.ErrorType == ErrorTypes.InvalidData) {
					this._notificationService.Error('error', 'Такой email зарегистрирован на другого пользователя');
				} else {
					this._notificationService.Error('error', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');
				}
			}
		}

		this.ChangeEmailLoading = false;
	}

	ChangeEmailDialogClose() {
		this.NewEmail = this.Email;
		this.EditModal = false;
	}
}
