import './wp-market-rule-item.scss';

import Vue from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	AppSettings,
	IMarketService,
	MarketSymbols,
	Cart,
	MarketRule,
	MarketDateTimeRange,
	MarketTariffCartItem,
	INotificationService,
	NotificationSymbols,
	IdentitySymbols,
	MarketTariffService,
	BaseSystemLocalizationDictionary,
} from 'web-platform-core-ui';
import { IContext } from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';

export const wpMarketRuleItemProp = Vue.extend({
	props: {
		Id: String,
		Rule: Object as Prop<MarketRule>,
		TariffService: Object as Prop<MarketTariffService>,
		Date: String,
		Schedule: Object as Prop<MarketDateTimeRange>,
		Cart: Object as Prop<Cart>,
	},
});

@Component({
	template: require('./wp-market-rule-item.html'),
})
export default class WpMarketRuleItem extends mixins<WpBaseComponent<WpMarketRuleItemDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpMarketRuleItemProp,
	Vue
) {
	private _marketService!: IMarketService;
	protected _notificationService!: INotificationService;
	private _context!: IContext;

	Settings!: AppSettings;
	Amount: number = 1;
	CartMaxItems: number = 6;
	Loading: boolean = false;
	Period: string = '';
	Duration: string = '';
	Category: string = '';
	UseCount: string = '';

	get Price(): number {
		return this.Rule.Price[0] * this.Amount;
	}

	AmountDecrease(): void {
		if (this.Amount >= 2) {
			this.Amount--;
		}
	}
	AmountIncrease(): void {
		if (this.Amount < this.CartMaxItems - this.Cart.Count) {
			this.Amount++;
		}
	}

	//AddToCart(): void {
	//    let cartItem = new MarketCartItem(this.Market, this.Amount, this.Date, this._price, this.Cart, this._leftQuota);
	//    this.$emit('addtocart', cartItem);
	//    this.Amount = 1;
	//}

	async mounted() {
		this.Settings = GlobalService.GetSettings<AppSettings>();

		this._marketService = this.GlobalService.Get<IMarketService>(MarketSymbols.MarketService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);

		this.CartMaxItems = this.Settings.CartMaxItems;
		this.Period = `${this.DateHandler.Parse(this.Schedule.StartDateTime[0], 'YYYY-MM-DDTHH:mm:ss').Format('HH:mm')} - ${this.DateHandler.Parse(
			this.Schedule.EndDateTime[0],
			'YYYY-MM-DDTHH:mm:ss'
		).Format('HH:mm')}`;
		if (this.Rule.Duration && this.Rule.Duration.length > 0) {
			this.Duration = `Продолжительность: ${this.DateHandler.Parse(this.Rule.Duration[0], 'HH:mm:ss').Format('HH:mm')}`;
		}

		if (this.Rule.ClientType != null && this.Rule.ClientType.length > 0) {
			this.Rule.ClientType.forEach((x: any, i: number) => {
				switch (x) {
					case 0:
						this.Category += 'Взрослый';
						break;
					case 1:
						this.Category += 'Детский';
						break;
				}
				if (i != this.Rule.ClientType.length - 1) this.Category += ' | ';
			});
		}

		if (this.Rule.Quantity != null && this.Rule.Quantity.length > 0 && this.Rule.Quantity[0] > 1) {
			this.UseCount = `Количество посещений: ${this.Rule.Quantity[0]}`;
		}

		this._notificationService.NotificationSended.Subscribe(async (sender, e) => {
			if (
				e.Data.SystemProcessKey === 'MarketClientGetted' &&
				e.Data.AdditionalData === `${this.Rule.id}_${this.Schedule.StartDateTime[0]}_${this.Schedule.EndDateTime[0]}`
			) {
				await this.AddToCart();
			}
		});
	}

	async AddToCart() {
		if (!this._context.CurrentIdentity.IsAuthentificated || this.Settings == null || this.Settings.CurrentMarketClient == null) {
			this.$emit('Selected', `${this.Rule.id}_${this.Schedule.StartDateTime[0]}_${this.Schedule.EndDateTime[0]}`);
			this._context.SignIn();
			return;
		}

		this.Loading = true;

		let cartItem = new MarketTariffCartItem(this._marketService);
		cartItem.OrderId = this.Cart.OrderId;
		cartItem.Rule = this.Rule;
		cartItem.Period = this.Schedule;
		cartItem.Client = this.Settings.CurrentMarketClient;

		if (this.Settings.MarketEmployeeId != null) {
			let employee = this.TariffService?.User.find((x: { id: number | undefined }) => x.id == this.Settings.MarketEmployeeId);

			cartItem.Employee = employee;
		}

		let result = await cartItem.ReservateItem();

		if (!result.Success) {
			this._notificationService.Error('Ошибка', result.ErrorMessage ? result.ErrorMessage : 'Непредвиденная ошибка');
		} else {
			let result = this.Cart.AddItem(cartItem);

			if (!result.Success) {
				this._notificationService.Error('Ошибка', result.ErrorMessage ? result.ErrorMessage : 'Непредвиденная ошибка');
			}

			this.Cart.OrderId = cartItem.OrderId;
		}

		this.Loading = false;
	}
}

export class WpMarketRuleItemDictionary extends BaseSystemLocalizationDictionary {
	CartIsFull: string = 'Максимальное количество элементов в корзине';
	ToCart: string = 'В корзину';
}
