
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import WpBaseComponent from '../../wp-base-component';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
@Component
export default class WpDateOfBirth extends WpBaseComponent<WpDateOfBirthDictionary> {
	@Prop() value?: string;
	today = new Date(new Date().setHours(0, 0, 0));
	birthdate: string = this.value || '';

	@Watch('value') // Need to refactor these shenanigans l8r
	watcherValue() {
		this.birthdate = this.value || '';
	}

	handlerInput() {
		if (this.dateOfBirth) {
			this.$emit('input', this.birthdate);
			this.$emit('valid', this.dateOfBirth ? this.DateHandler.Parse(this.dateOfBirth).Format('YYYY-MM-DD') : null);
		}
	}

	get rules() {
		return [
			(v: string) => v !== '' || this.Terms.NotEmpty, // Can't be empty
			() => (this.dateOfBirth ? true : this.Terms.ValidDateRequired), // Must be valid
		];
	}

	get dateOfBirth(): Date | null {
		if (!this.birthdate) return null; // Null shield
		let [strDate, strMonth, strYear] = this.birthdate.split('.'); // Object deconstruction
		if (!strDate || !strMonth || !strYear) return null; // Null shield
		if (strDate.length === 2 && strMonth.length === 2 && strYear.length === 4) {
			let [date, month, year] = [parseInt(strDate), parseInt(strMonth), parseInt(strYear)];
			let lastDate = this.DateHandler.GetMonthLength(month, year); // Берём последнюю дату данного месяца в году
			if (date === 0 || date > lastDate) return null; // 0 или 32 января?
			if (month < 1 || month > 12) return null; // 0 или 13 месяц?
			let yearsAgo = this.today.getFullYear() - 100; // Какой год был 100 лет назад?
			if (year < yearsAgo) return null; // Возраст более 100 лет?
			let validDate = new Date(`${year}/${month}/${date}`);
			if (!this.DateHandler.IsValid(validDate)) return null; // invalid date?
			if (validDate > this.today) return null; // Дата рождения завтра?
			return validDate;
		}
		return null;
	}
}

export class WpDateOfBirthDictionary extends BaseUILocalizationDictionary {
	DateOfBirth = 'Дата рождения';
	NotEmpty = 'Поле не может быть пустым';
	ValidDateRequired = 'Требуется валидная дата';
}
