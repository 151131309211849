import { injectable, inject } from 'inversify';
import GlobalService, { AppSettings } from './..';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import ICartProvider from './i-cart-provider';
import { RestClient } from 'typed-rest-client/RestClient';

@injectable()
export default class CartProvider implements ICartProvider {
	private _context: IContext;
	private _settings: AppSettings;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this._context = context;
		this._settings = GlobalService.GetSettings();
	}

	async SaveCartAsync(cart: string): Promise<void> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				SessionId: this._settings.SessionId,
				User: this._context.CurrentIdentity.UserId,
				SiteId: this._settings.SiteId,
				moduleId: this._settings.SiteId,
				Authorization: `Bearer ${this._context.CurrentIdentity?.AccessToken?.Token}`,
			},
		});

		await client.replace(`/api/cart/save/`, { Data: cart });
	}

	async GetCurrentCartAsync(): Promise<any> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				SessionId: this._settings.SessionId,
				User: this._context.CurrentIdentity.UserId,
				SiteId: this._settings.SiteId,
				moduleId: this._settings.SiteId,
				Authorization: `Bearer ${this._context.CurrentIdentity?.AccessToken?.Token}`,
			},
		});

		let response = await client.get<any>(`/api/cart/`);

		return response.result == null ? '' : response.result;
	}
}
