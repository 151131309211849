import './wp-arena-seats.scss';

import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ArenaSector,
	ArenaSeat,
	ArenaTariff,
	ArenaSeance,
	Cart,
	CartItemTypes,
	ArenaCartItem,
	INotificationService,
	NotificationSymbols,
	AppSettings,
	ArenaTariffOption,
	IArenaService,
	ArenaSymbols,
	WpEventArgs,
	IMetricsService,
	MetricsSymbols,
	ErrorTypes,
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { MetricsEvents } from '../../../metrics-events-symbols';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

export const wpArenaSeatItemProp = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
		Color: String,
		Seat: Object as Prop<ArenaSeat>,
		Tariff: Object as Prop<ArenaTariff>,
		Sector: Object as Prop<ArenaSector>,
		Seance: Object as Prop<ArenaSeance>,
	},
});

@Component({
	template: require('./wp-arena-seat-item.html'),
})
export default class WpArenaSeatItem extends mixins<WpBaseComponent<WpArenaSeatItemDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	Vue,
	wpArenaSeatItemProp
) {
	private _metricsService!: IMetricsService;
	private _arenaService!: IArenaService;
	protected _notificationService!: INotificationService;

	Settings!: AppSettings;
	CartItem?: ArenaCartItem;
	IsReserved: boolean = false;
	Loading: boolean = false;

	get TranslateString() {
		return `translate(${(this.Seat.X - 1) * 49 + this.Seat.X + 55}, ${(this.Seat.Y - 1) * 49 + this.Seat.Y + 2 + 55})`;
	}

	get CartItems() {
		return this.Cart._items;
	}

	mounted(): void {
		this.Settings = GlobalService.GetSettings<AppSettings>();

		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);

		this.Cart?.Items?.forEach((x: ArenaCartItem) => {
			if (x.Type == CartItemTypes.ArenaTicket) {
				let cartItem = x as ArenaCartItem;
				if (cartItem !== undefined && cartItem.Seat.PlaceCode == this.Seat.PlaceCode) {
					this.CartItem = cartItem;
					this.CartItem.CartItemChanged.Subscribe(this.ItemRemoved);
					this.IsReserved = true;
					this.Seat.IsFree = true;
					this.Cart.CartChanged.Subscribe(this.CartChanged);
				}
			}
		});
	}

	async SeatSelected() {
		if (this.$parent?.$data.Panning === false && this.$parent.$data.Buying === false && this.$parent.$data.Zooming === false) {
			if (this.CartItem == null) {
				if (!this.Seat.IsFree) return;

				if (this.Cart.LeftCount == 0) {
					return this._notificationService.Error(this.Terms.GenericError, this.Terms.ErrorCartIsFull);
				}
			}

			if (this.CartItem == null && this.Tariff != null && this.Tariff.Options != null && this.Tariff.Options.length > 0) {
				this.$emit('ShowOptions', { tariff: this.Tariff, reservateFunc: this.SeatReservate });
			} else {
				this.SeatReservate(undefined);
			}
		}
	}

	async SeatReservate(option?: ArenaTariffOption) {
		this.Loading = true;
		this.$emit('SetBuying', true);

		if (this.CartItem == null) {
			this.CartItem = new ArenaCartItem(this._arenaService);

			this.CartItem.OrderId = this.Cart.OrderId;
			this.CartItem.Seat = this.Seat;
			this.CartItem.Sector = this.Sector;
			this.CartItem.Seance = this.Seance;
			this.CartItem.Tariff = this.Tariff;
			this.CartItem.Option = option;
			this.CartItem.SibId = this.Settings.SibId;

			let result = await this.CartItem.ReservateItem();

			if (!result.Success) {
				this.CartItem = undefined;

				if (result.ErrorType == ErrorTypes.SeatIsOccupied) {
					this.Seat.IsFree = false;
					this._notificationService.Error(this.Terms.GenericError, this.Terms.ErrorSeatIsOccupied);
				} else {
					this._notificationService.Error(this.Terms.GenericError, result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
				}
			} else {
				let result = this.Cart.AddItem(this.CartItem);

				this._metricsService.RegistrateEvent(MetricsEvents.ArenaSeatReservate, this.CartItem?.Seance?.Id);

				if (!result.Success) {
					this._notificationService.Error(this.Terms.GenericError, result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
				}
				this.Cart.OrderId = this.CartItem.OrderId;
				this.CartItem?.CartItemChanged.Subscribe(this.ItemRemoved);
				this.IsReserved = true;
				this.Cart.CartChanged.Subscribe(this.CartChanged);
			}
		} else {
			let result = await this.Cart.RemoveItem(this.CartItem.TempId);
			this._metricsService.RegistrateEvent(MetricsEvents.ArenaSeatUnreservate, this.CartItem?.Seance?.Id);
			if (!result.Success) {
				this._notificationService.Error(this.Terms.GenericError, result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
			}
		}
		this.$emit('SetBuying', false);
		this.Loading = false;
	}

	MouseEnter(event: any) {
		if (!this.Seat.IsFree) return;
		let seatPosition = event.target.getBoundingClientRect();
		this.$emit('SeatMouseEnter', { tariff: this.Tariff, seat: this.Seat, left: seatPosition.left + 15, top: seatPosition.top + 15 });
	}

	MouseLeave() {
		this.$emit('SeatMouseLeave', this.Seat);
	}

	ItemRemoved(_e: any, _args: WpEventArgs) {
		if (this.CartItem != null) {
			this.Tariff = this.CartItem.Tariff;
			this.Seat.TariffCode = this.CartItem.Tariff.TariffCode;
			this.Seat.IsFree = true;
			this.IsReserved = false;
			this.CartItem.CartItemChanged.Unsubscribe(this.ItemRemoved);
			this.CartItem = undefined;
			this.Cart.CartChanged.Unsubscribe(this.CartChanged);
		}
	}

	CartChanged(e: any, _args: WpEventArgs) {
		if (this.CartItem == null) return;

		let cart = e as Cart;

		if (cart != null && (cart.Items == null || cart.Items.length == 0)) {
			this.CartItem = undefined;
			this.IsReserved = false;
		}
	}
}

export class WpArenaSeatItemDictionary extends BaseUILocalizationDictionary {
	ErrorCartIsFull: string = '';
	ErrorSeatIsOccupied: string = '';
}
