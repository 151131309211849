import { AccessToken, IIdentity, IdentityChangedEventArgs } from './';
import { IContext } from './';

import IUserStorage from './i-user-storage';
import { WpEvent, WpEventHandler, WpEventArgs, ActionResult, ErrorTypes } from './../Common';
import { AppSettings } from '../Global/app-settings';
import { injectable } from 'inversify';
import GlobalService from '../Global/global-service';
import { ILocalizationService, LocalizationSymbols } from '../Localization';
import { RestClient } from 'typed-rest-client';

@injectable()
export default class ModifiedWebSaleUserIdentity implements IIdentity, IContext {
	private static _storageUserKey: string = 'wp:access-token';

	_store: IUserStorage;

	private _settings!: AppSettings;
	private _timerAccessTokenChecker?: any;
	private _identityChanged: WpEventHandler<IdentityChangedEventArgs>;
	private _signInCalled: WpEventHandler<WpEventArgs>;
	private _specificIdentity?: IIdentity;
	private _user?: User | null;
	private _accessToken = new AccessToken('', new Date());

	IsInitialized: boolean = false;

	get AccessToken(): AccessToken {
		return this._accessToken;
	}
	get IsAuthentificated(): boolean {
		return this._user != null && this._user != undefined && this._accessToken.Expired > new Date();
	}
	get Username(): string {
		return this._user?.preferred_username ?? '';
	}
	get UserId(): string {
		return this._user?.sub ?? '';
	}
	get Phone() {
		return this._user?.phone_number;
	}
	set Phone(val: string | undefined) {
		if (this._user != null) {
			this._user.phone_number = val;
		}
	}

	get SignInCalled(): WpEvent<WpEventArgs> {
		return this._signInCalled;
	}
	get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
		return this._identityChanged;
	}
	get CurrentIdentity(): IIdentity {
		if (this._specificIdentity != null) return this._specificIdentity;

		return this;
	}

	constructor(store: IUserStorage) {
		this._store = store;
		this._identityChanged = new WpEventHandler<IdentityChangedEventArgs>();
		this._signInCalled = new WpEventHandler<WpEventArgs>();
		this._settings = GlobalService.GetSettings<AppSettings>();
	}

	SignIn(): void {
		this._signInCalled.Send(this, WpEventArgs.Empty);
	}

	SignOut(): void {
		if (this._specificIdentity != null) this._specificIdentity = undefined;

		this._user = null;
		this._accessToken = new AccessToken('', new Date());
		this._store.removeItem(ModifiedWebSaleUserIdentity._storageUserKey);
		let args = new IdentityChangedEventArgs(this);
		this._identityChanged.Send(this, args);
	}

	ChangeIdentity(identity: IIdentity) {
		this._specificIdentity = identity;

		let args = new IdentityChangedEventArgs(this._specificIdentity);
		this._identityChanged.Send(this._specificIdentity, args);

		this._store.removeItem(ModifiedWebSaleUserIdentity._storageUserKey);
		this._accessToken = new AccessToken('', new Date());
		this._user = null;
	}

	public async Initialize(appSettings: AppSettings, globalService: GlobalService) {
		let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);

		await this._store.RestoreStorage();

		let accessTokenString = this._store.getItem(ModifiedWebSaleUserIdentity._storageUserKey);

		if (accessTokenString != null) {
			let parsedAccessToken = JSON.parse(accessTokenString);
			let accessToken = new AccessToken(parsedAccessToken.Token, new Date(parsedAccessToken.Expired), parsedAccessToken.RefreshToken);

			if (new Date() >= new Date(accessToken.Expired.getTime() - 300000) && accessToken.RefreshToken == null) {
				return;
			}

			this._accessToken = accessToken;

			if (this._accessToken.RefreshToken != null) {
				await this.RefreshAccessToken();
			} else await this.LoadUser();
		}

		if (this._user != null) {
			let args = new IdentityChangedEventArgs(this);
			this._identityChanged.Send(this, args);
		}
		this.IsInitialized = true;
	}

	public async Login(userName: string, password: string, rememberMe: boolean = false): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				siteid: this._settings.SiteId,
				moduleId: this._settings.SiteId,
				Authorization: `Bearer ${this.AccessToken.Token}`,
			},
		});

		let response = await client.create<AjaxResult<any>>(`/identity/signin`, { UserName: userName, Password: password, RememberMe: rememberMe });

		if (response.result == null) return ActionResult.Failed(ErrorTypes.InternalError);

		if (response.result.Result != 0) {
			if (response.result.Result == 5) {
				return ActionResult.Failed(ErrorTypes.InvalidData);
			}
			return ActionResult.Failed(ErrorTypes.InternalError);
		}

		let accessToken = response.result.Data.AccessToken;
		let date = new Date(new Date().getTime() + response.result.Data.ExpiresIn * 1000);
		let refreshToken = response.result.Data.RefreshToken;

		this._accessToken = new AccessToken(accessToken, date, refreshToken);
		this._store.setItem(ModifiedWebSaleUserIdentity._storageUserKey, JSON.stringify(this._accessToken));
		this._store.SaveStorage(accessToken);

		await this.LoadUser();

		let identity = this;
		if (this._user != null) {
			let args = new IdentityChangedEventArgs(identity);
			identity._identityChanged.Send(identity, args);
		}

		if (this._accessToken.RefreshToken != null && this._timerAccessTokenChecker == null) {
			let _identity = this;
			setInterval(() => {
				if (new Date() >= new Date(this._accessToken.Expired.getTime() - 300000)) {
					_identity.RefreshAccessToken();
				}
			}, 300000);
		}

		return ActionResult.Success();
	}

	private async RefreshAccessToken(): Promise<void> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				siteid: this._settings.SiteId,
				moduleId: this._settings.SiteId,
				Authorization: `Bearer ${this.AccessToken.Token}`,
			},
		});

		let response = await client.create<AjaxResult<any>>(`/identity/refreshtoken`, { token: this._accessToken.RefreshToken });

		if (response.result == null || response.result.Result != 0) {
			this._store.removeItem(ModifiedWebSaleUserIdentity._storageUserKey);
			return;
		}

		let accessToken = response.result.Data.AccessToken;
		let date = new Date(new Date().getTime() + response.result.Data.ExpiresIn * 1000);
		let refreshToken = response.result.Data.RefreshToken;

		this._accessToken = new AccessToken(accessToken, date, refreshToken);
		this._store.setItem(ModifiedWebSaleUserIdentity._storageUserKey, JSON.stringify(this._accessToken));
		this._store.SaveStorage(accessToken);

		await this.LoadUser();

		if (this._accessToken.RefreshToken != null && this._timerAccessTokenChecker == null) {
			let _identity = this;
			setInterval(() => {
				if (new Date() >= new Date(this._accessToken.Expired.getTime() - 300000)) {
					_identity.RefreshAccessToken();
				}
			}, 300000);
		}

		if (this._accessToken.RefreshToken == null && this._timerAccessTokenChecker != null) {
			clearInterval(this._timerAccessTokenChecker);
		}
	}

	private async LoadUser(): Promise<void> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this.AccessToken.Token}`,
				moduleId: this._settings.SiteId,
			},
		});
		let response = await client.get<User>(`/idsrv/identity/connect/userinfo`);
		this._user = response.result;
	}
}

class User {
	sub: string = '';
	preferred_username: string = '';
	email: string = '';
	email_verified: boolean = false;
	phone_number?: string;
	phone_number_verified: boolean = false;
	role?: string;
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
