import IClubProvider from './i-club-provider';
import { injectable, inject } from 'inversify';
import ClubContract from './club-contract';
import { ActionResult, ErrorTypes } from '../Common';
import ClubAppSettings from './club-app-settings';
import GlobalService from '../Global/global-service';
import { RestClient } from 'typed-rest-client';
import SoldClubContract from './sold-club-contract';

import { IdentitySymbols } from '../Identity';
import { IContext } from '../Identity';

import { Tariff } from '..';

@injectable()
export default class DefaultClubProvider implements IClubProvider {
	protected Settings!: ClubAppSettings;
	protected Context!: IContext;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this.Settings = GlobalService.GetSettings<ClubAppSettings>();
		this.Context = context;
	}

	// Regenerate rest client
	private GetRestClient(isAuth: boolean = false): RestClient {
		/* Friendly reminder
		 * .get() = GET
		 * .create() = POST
		 * .replace() = PUT
		 */
		let headers = isAuth
			? {
					Authorization: `Bearer ${this.Context.CurrentIdentity.AccessToken.Token}`,
					SessionId: this.Settings.SessionId,
					moduleId: this.Settings.SiteId,
			  }
			: {
					SiteId: this.Settings.ClubServicesSiteId,
					SessionId: this.Settings.SessionId,
					moduleId: this.Settings.SiteId,
			  };
		return new RestClient('wp-app', this.Settings.ServerUrl, [], {
			headers: headers,
		});
	}

	async GetContractsAdditionalServicesAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
		let client: RestClient = new RestClient('wp-app', this.Settings.ServerUrl, [], {
			headers: {
				SiteId: this.Settings.SiteId,
				SessionId: this.Settings.SessionId,
				moduleId: this.Settings.SiteId,
			},
		});

		let response = await client.get<AjaxResult<Array<T>>>(`/websale/api/club/tariffs`);

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

		return ActionResult.SuccessData<Array<T>>(response.result?.Data);
	}

	async GetContractsAsync(): Promise<ActionResult<Array<ClubContract>>> {
		try {
			let client = this.GetRestClient();
			let response = await client.get<AjaxResult<Array<ClubContract>>>(`/websale/api/club/contracts`);
			if (response.result != null && response.result.Result != 0) {
				return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);
			}
			return ActionResult.SuccessData<Array<ClubContract>>(response.result?.Data);
		} catch (error) {
			console.error(error);
			return ActionResult.FailedData(ErrorTypes.InternalError, [], 'Не удалось загрузить список контрактов');
		}
	}

	async GetContractAsync(id: number): Promise<ActionResult<ClubContract>> {
		let client = this.GetRestClient();

		let response = await client.get<AjaxResult<ClubContract>>(`/websale/api/club/contracts/${id}`);

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

		return ActionResult.SuccessData<ClubContract>(response.result?.Data);
	}

	async GetPersonContractsAsync(tnodId: number, loyaltyId: number): Promise<ActionResult<Array<SoldClubContract>>> {
		let client = this.GetRestClient(true);

		let response = await client.get<AjaxResult<Array<SoldClubContract>>>(
			`/account/api/club/contracts/?tnodid=${tnodId}&loyaltyId=${loyaltyId}&clubsiteid=${this.Settings.ClubServicesSiteId}`
		);

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<Array<SoldClubContract>>(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

		return ActionResult.SuccessData<Array<SoldClubContract>>(response.result?.Data);
	}

	async CreateGuestContractAsync(): Promise<void> {
		let client = this.GetRestClient(true);
		let query = await client.create(`/club/guestcontract/`, {});
	}

	async RegistrationToGroupLessson(
		personId: number,
		tnodId: number,
		loayltyId: number,
		lessonId: number,
		contratId?: number
	): Promise<ActionResult> {
		let client = this.GetRestClient(true);

		let response = await client.replace<AjaxResult<Array<SoldClubContract>>>(`/account/api/club/lesson/group`, {
			LessonId: lessonId,
			PersonId: personId,
			TNodId: tnodId,
			UserLoyaltyId: loayltyId,
			ContractId: contratId,
		});

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result?.Message);

		return ActionResult.SuccessData();
	}

	async RegistrationToFreeGroupLessson(
		personId: number,
		tnodId: number,
		loayltyId: number,
		lessonId: number,
		contratId?: number
	): Promise<ActionResult> {
		let client = this.GetRestClient(true);

		let response = await client.replace<AjaxResult<Array<SoldClubContract>>>(`/account/api/club/lesson/group/free`, {
			LessonId: lessonId,
			PersonId: personId,
			TNodId: tnodId,
			UserLoyaltyId: loayltyId,
			ContractId: contratId,
		});

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result?.Message);

		return ActionResult.SuccessData();
	}

	async RegistrationToPersonalLessson(
		personId: number,
		tnodId: number,
		loayltyId: number,
		lessonId: number,
		contratId?: number
	): Promise<ActionResult> {
		let client = this.GetRestClient(true);

		let response = await client.replace<AjaxResult<Array<SoldClubContract>>>(`/account/api/club/lesson/personal`, {
			LessonId: lessonId,
			PersonId: personId,
			TNodId: tnodId,
			UserLoyaltyId: loayltyId,
			ContractId: contratId,
		});

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result?.Message);

		return ActionResult.SuccessData();
	}

	/**
	 * http://redmine.isd.su/issues/8403
	 * Запрос для получения списка занятий на которые записан клиент
	 */
	async GetPersonalLessons(tnodId: number, loyaltyId: number, dateFrom: string, dateTo: string): Promise<ActionResult> {
		let client = this.GetRestClient(true);
		let query = await client.get<AjaxResult<any>>(
			`/account/api/club/lessons/person/?tnodid=${tnodId}&loyaltyId=${loyaltyId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
			{}
		);
		if (query.result != null && query.result.Result == 0) return ActionResult.SuccessData(query.result.Data);
		return ActionResult.Failed(ErrorTypes.InternalError);
	}
	/*
	 * Заморозка карты
	 */
	async FreezeCard(
		ContractId: number,
		DateFrom: string,
		DaysAmount: number,
		UserLoyaltyId: number,
		ClubSiteId: number,
		TNodId: number
	): Promise<ActionResult> {
		let client = this.GetRestClient(true);
		let freeze = await client.replace<AjaxResult<any>>('/account/api/club/contract/freeze', {
			ContractId: ContractId,
			DateFrom: DateFrom,
			DaysAmount: DaysAmount,
			UserLoyaltyId: UserLoyaltyId,
			ClubSiteId: ClubSiteId,
			TNodId: TNodId,
		});

		if (freeze.statusCode === 404) return ActionResult.Failed(ErrorTypes.InternalError, 'Method not implemented on the backend');

		if (freeze.result != null && freeze.result.Result == 0) return ActionResult.SuccessData(freeze.result.Data);

		return ActionResult.Failed(ErrorTypes.InternalError, freeze.result ? freeze.result.Message : 'Нет сообщения ошибки');
	}

	async CancelLessonAsync(tnodId: number, loyaltyId: number, cashListId: number, cashItemId: number): Promise<ActionResult> {
		let client = this.GetRestClient(true);
		let query = await client.get<AjaxResult<any>>(
			`/account/api/club/lessons/person/cancel/?tnodid=${tnodId}&loyaltyId=${loyaltyId}&cashListId=${cashListId}&cashItemId=${cashItemId}`,
			{}
		);
		if (query.result != null && query.result.Result == 0) return ActionResult.SuccessData();
		return ActionResult.Failed(ErrorTypes.InternalError);
	}
	async GetStatsAsync(
		siteId: number,
		tnodid: number,
		loyaltyId: number,
		dateFrom: string,
		dateTo: string,
		ContractId: number
	): Promise<ActionResult> {
		const PARAMS = {
			siteId: siteId.toString(),
			tnodid: tnodid.toString(),
			loyaltyId: loyaltyId.toString(),
			dateFrom: dateFrom,
			dateTo: dateTo,
			ContractId: ContractId.toString(),
		};
		const SEARCH = new URLSearchParams(PARAMS).toString();
		const CLIENT = this.GetRestClient(true);
		const URL = `/account/api/club/lessons/person/stats?${SEARCH}`;
		const STATS = await CLIENT.get<AjaxResult<object>>(URL);
		if (STATS.result != null && STATS.result.Result === 0) return ActionResult.SuccessData(STATS.result.Data);
		return ActionResult.Failed(ErrorTypes.InternalError);
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
