import './wp-registration-form.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from '../../wp-base-component';
import { Ref, Watch } from 'vue-property-decorator';
import GlobalService, { INotificationService, IdentityAccountProvider, NotificationSymbols, ErrorTypes, AppSettings } from 'web-platform-core-ui';
import BaseUILocalizationDictionary from '../../Localization/base-ui-localization-terms';

@Component({
	template: require('./wp-registration-form.html'),
})
export default class WpRegistrationForm extends mixins<WpBaseComponent<WpRegistrationFormDictionary>>(WpBaseComponent, Vue) {
	protected _notificationService!: INotificationService;
	@Ref('RegistrationForm') RegistrationForm!: HTMLFormElement;
	@Ref('ConfirmPasswordInput') ConfirmPasswordInput!: any;

	Settings!: AppSettings;
	RegistrationLoading: boolean = false;
	RegistrationFormValid: boolean = false;
	CodeId: number = 0;
	Password: string | null = '';
	ConfirmPassword: string = '';
	PasswordShow: boolean = false;
	ConfirmPasswordShow: boolean = false;
	Tab: string = 'email';
	RegistrationStep: number = 1;
	Email: string = '';
	Agree: boolean = false;
	dialogUsedEmail = false;

	mounted(): void {
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this.Settings = GlobalService.GetSettings();
	}

	@Watch('Email')
	EmailChanged(): void {
		this.$nextTick(() => {
			// Remove spaces
			try {
				this.Email = this.Email?.replace(/\s/, '');
			} catch (e) {
				console.error(e);
				this._notificationService.Error('Email error', `${e}`);
			}
		});
	}

	@Watch('Password')
	PasswordChanged(): void {
		this.$nextTick(() => {
			// Remove spaces
			if (this.Password) this.Password = this.Password?.replace(/\s/, '');
		});
	}

	@Watch('ConfirmPassword')
	ConfirmPasswordChanged(): void {
		this.ConfirmPasswordInput.validate();
		this.$nextTick(() => {
			// Remove spaces
			if (this.ConfirmPassword) this.ConfirmPassword = this.ConfirmPassword?.replace(/\s/, '');
		});
	}

	get PassowrdRules() {
		return [
			(v: string) => !!v || this.Terms.FieldRequiredMessage,
			(v: string) => (v && v.length > 6) || this.Terms.PasswordLengthError,
			(v: string) => /^[а-яА-Яa-zA-Z0-9ёЁ!№;%:?*()_+=\-<>.,/\\'`@#$%^&{}\[\]|""~ ]{0,50}$/.test(v) || this.Terms.PasswordNotAvailableCharacters,
		];
	}

	get ConfirmPassowrdRules() {
		return [(v: string) => !!v || this.Terms.FieldRequiredMessage, (v: string) => v == this.Password || this.Terms.ComparePasswordError];
	}

	get EmailRules() {
		return [(v: string) => !!v || this.Terms.FieldRequiredMessage, (v: string) => /.+@.+\..+/.test(v) || this.Terms.EmailRequiredMessage];
	}

	CodeConfirmed(code: any): void {
		this.CodeId = code.CodeId;
		this.RegistrationStep = 2;
	}

	async RegistrationFormSubmit(e: any): Promise<void> {
		e.preventDefault();
		await this.Registration();
	}

	async Registration(): Promise<void> {
		if (!this.RegistrationForm.validate() || !this.Password || !this.ConfirmPassword) return;

		this.RegistrationLoading = true;
		let organizationId = this.Settings.CurrentSite != null ? this.Settings.CurrentSite.OrganizationId : 0;

		let _identityAccountPorvider: IdentityAccountProvider = new IdentityAccountProvider();
		let registrationResult = await _identityAccountPorvider.Registration(this.Email, this.Password, this.ConfirmPassword, organizationId);

		if (!registrationResult.Success) {
			this.RegistrationLoading = false;

			if (registrationResult.ErrorType == ErrorTypes.InvalidData)
				return this._notificationService.Error('', registrationResult.ErrorMessage ?? this.Terms.InternalError);

			if (registrationResult.ErrorType == ErrorTypes.UserAlreadyExists) {
				this.dialogUsedEmail = true;
				return;
			}

			this._notificationService.Error('', registrationResult.ErrorMessage ?? this.Terms.InternalError);

			if (registrationResult.ErrorType == ErrorTypes.NotFoundData) {
				this.RegistrationForm.reset();
				this.RegistrationStep = 1;
			}

			return;
		}

		this._notificationService.Success('', this.Terms.SuccessRegistrationMessage);
		this.RegistrationForm.reset();
		this.RegistrationStep = 1;
		this.RegistrationLoading = false;
		this.Back();
	}

	Oferta(): void {
		try {
			openInBrowser('https://moipass.ru/oferta');
		} catch {
			window.open('https://moipass.ru/oferta', '_blank');
		}
	}

	Back(): void {
		this.$emit('Back');
	}
}

export class WpRegistrationFormDictionary extends BaseUILocalizationDictionary {
	SuccessRegistrationMessage: string = '';
	EmailRequiredMessage: string = '';
	FieldRequiredMessage: string = '';
	PasswordLengthError: string = '';
	PasswordNotAvailableCharacters: string = '';
	ComparePasswordError: string = '';
	RegistrationTitle: string = '';
	EmailLblText: string = '';
	PasswordLblText: string = '';
	RepeatPasswordLblText: string = '';
	AgreementError: string = '';
	BeforeAgreementLinkText: string = '';
	AgreementLink: string = '';
	AfterAgreementLinkText: string = '';
	RegistrationBtnText: string = '';
	EmailExistError: string = '';

	// https://redmine.moipass.ru/issues/13893
	dialogErrorEmailUsedTitle = 'E-mail уже используется';
	dialogErrorEmailUsedParagraph = 'Этот почтовый адрес уже используется';
	dialogErrorEmailUsedReset = 'Восстановить пароль?';
}
