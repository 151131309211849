import Site from './site';
import MarketClient from '../Market/market-client';
import { Localization } from '../Localization';
import { WidgetType } from './widget-type';
import { SaleTermsType } from './sale-terms-type';
import { createGuid } from '../Utility/guid';
import { ArenaCalendarType } from './arena-calendar-type';
import { TariffsCalendarType } from './tariffs-calendar-type';
import { CustomSeanceTitle } from '../Tariffs';
import UtmLabel from '../Sale/utm-label';
import OrdersFilter from '../Order/orders-filter';

export class AppSettings {
	// #region Properties
	protected _hashParams: any;
	protected _administrationParams: any;
	private _cartmaxitems = 6;

	public BuildVersion: number = 0; // 📱 MobileApp setting - ОПИСЫВАТЬ НЕ НУЖНО
	BundlesId: Array<number> = [];
	BundlesLessonsTariffsId: Array<number> = [];
	Categories: Array<string> = [];
	ClubSiteId: number = 0;
	CoachId?: number;
	CurrentMarketClient?: MarketClient; // ТРЕБУЕТСЯ УТОЧНЕНИЕ
	CurrentSite?: Site;
	CustomSeanceTitles: CustomSeanceTitle[] = []; // https://redmine.moipass.ru/issues/12916
	public DeviceId: string = ''; // 📱 MobileApp setting
	Disableddates: Array<string> = [];
	DisabledDays: Array<number> = []; // https://redmine.moipass.ru/issues/13030
	DisableMultipleSeances: boolean = false;
	GroupId?: number;
	GUID: string = ''; // НУЖНО СКРЫВАТЬ?
	Forcedate?: string;
	ForceSeanceId?: number;
	ForceSectorId?: number;
	ForceMedia: string = '';
	ForceTab: string = '';
	GroupLessonsSkillsTariffs: any = {};
	// Фильтр тариффов для инструкторов (Например id:206 = Роза Хутор). Используется в WpInstructors
	// Для каждой вкладки - отдельный параметр. Некоторые добавлены в рамках http://redmine.isd.su/issues/8410
	GroupLessonsTariffsId: Array<number> = [];
	IsMobile: boolean = false;
	LessonsLocation?: number;
	LessonsTariffsId: Array<number> = [];
	Locale: string = '';
	LoyaltyId: number = 0; // BUG?
	MarketBillId?: number;
	MarketEmployeeId?: number;
	Maxdate?: string;
	Mindate?: string;
	Minimap: boolean = true;
	MultiDaysLessonsSkillsTariffs: any = {};
	NspkEnabled: boolean = true;
	Nocalendar: boolean = false;
	NoCategories: boolean = false;
	NoCoach: boolean = false;
	NoLocations: boolean = false;
	NoPromo: boolean = false;
	NoScroll: boolean = false;
	NoSkills: boolean = false;
	NoTagFilter: boolean = false;
	NoTariffs: boolean = false;
	NfcSupported: boolean = false;
	OnlyTab: Array<string> = [];
	OrderComment: string = '';
	PersonalLessonsSkillsTariffs: any = {};
	PlaceId?: number;
	public Platform: string = ''; // 📱 MobileApp setting - ОПИСЫВАТЬ НЕ НУЖНО
	public PushToken: string = ''; // 📱 MobileApp setting - ОПИСЫВАТЬ НЕ НУЖНО
	RequiredLoyalty: boolean = false;
	ResetCart: boolean = false;
	RewriteTariffsId: Array<number> = [];
	SaleDepth?: number;
	public ServerUrl = ''; // 📱 MobileApp setting
	SessionId: string = '';
	ShowDisclaimer: boolean = false;
	ShowNoTicketsSeances: boolean = false;
	ShowOverSeances: boolean = false;
	SibId: string = '';
	SingleGuest: boolean = false;
	SiteId: number = 0;
	Skills: Array<number> = [];
	TariffsId: Array<number> = [];
	TariffsSeancesFilter: boolean = false;
	VKClientId: string = '';
	UtmLabels: Array<UtmLabel> = [];
	// #endregion

	// These are private settings, that are NOT publically available.
	// These are basically a substitute for a simple state manager.
	PrivateSettings: privateSettings = new privateSettings();

	public HandleBaseParams(paramsHash: string, ignoreWindowName: boolean = false): boolean {
		try {
			this._hashParams = JSON.parse(decodeURIComponent(paramsHash));

			this.SiteId = this._hashParams.id;
			this.GUID = this._hashParams.GUID;
			this.SessionId = this._hashParams.SessionId;
			if ('ver' in this._hashParams) this.BuildVersion = this._hashParams.ver;

			if ((this.SessionId == null || this.SessionId.trim() == '' || !window.name.includes(this.GUID)) && !ignoreWindowName)
				this.SessionId = createGuid();

			return true;
		} catch (ex) {
			console.error(ex);
			return false;
		}
	}

	public SetJsonParameters(jsonParameters: any) {
		try {
			if ('bundlesid' in jsonParameters) {
				if (jsonParameters.bundlesid === false || jsonParameters.bundlesid === 'false') this.BundlesId = [-1];
				else this.BundlesId = jsonParameters.bundlesid;
			}
			if ('bundleslessonstariffsid' in jsonParameters) this.BundlesLessonsTariffsId = jsonParameters.bundleslessonstariffsid;
			if ('categories' in jsonParameters) this.Categories = jsonParameters.categories;
			if ('clubsiteid' in jsonParameters) this.ClubSiteId = jsonParameters.clubsiteid;

			if ('placeid' in jsonParameters) this.PlaceId = jsonParameters.placeid;
			if ('singleguest' in jsonParameters) this.SingleGuest = jsonParameters.singleguest; // Временный фикс для лужников, чтоб нельзя було выбрать двух гостей в инструкторах
			if ('skills' in jsonParameters) this.Skills = jsonParameters.skills;
			if ('cartmaxitems' in jsonParameters) this.CartMaxItems = jsonParameters.cartmaxitems;
			if ('categories' in jsonParameters) this.Categories = jsonParameters.categories;
			if ('customseancetitles' in jsonParameters) this.CustomSeanceTitles = jsonParameters.customseancetitles; // https://redmine.moipass.ru/issues/12916
			if ('noscroll' in jsonParameters) this.NoScroll = jsonParameters.noscroll;
			if ('noskills' in jsonParameters) this.NoSkills = jsonParameters.noskills;
			if ('notagfilter' in jsonParameters) this.NoTagFilter = jsonParameters.notagfilter;
			if ('nocategories' in jsonParameters) this.NoCategories = jsonParameters.nocategories;
			if ('notariffs' in jsonParameters) this.NoTariffs = jsonParameters.notariffs;
			if ('nolocation' in jsonParameters) this.NoLocations = jsonParameters.nolocation;
			if ('nopromo' in jsonParameters) this.NoPromo = jsonParameters.nopromo;
			if ('nocoach' in jsonParameters) this.NoCoach = jsonParameters.nocoach;
			if ('nocalendar' in jsonParameters) this.Nocalendar = jsonParameters.nocalendar;
			if ('resetcart' in jsonParameters) this.ResetCart = jsonParameters.resetcart;
			if ('mindate' in jsonParameters) this.Mindate = jsonParameters.mindate;
			if ('maxdate' in jsonParameters) this.Maxdate = jsonParameters.maxdate;
			if ('forcedate' in jsonParameters) this.Forcedate = jsonParameters.forcedate;
			if ('disableddates' in jsonParameters) {
				if (!Array.isArray(jsonParameters.disableddates)) {
					this.Disableddates = [jsonParameters.disableddates];
				} else {
					this.Disableddates = jsonParameters.disableddates;
				}
			}
			if ('disableddays' in jsonParameters) this.DisabledDays = jsonParameters.disableddays;
			if ('forcemedia' in jsonParameters) this.ForceMedia = jsonParameters.forcemedia;
			if ('saledepth' in jsonParameters) this.SaleDepth = parseInt(jsonParameters.saledepth);
			if ('showoverseances' in jsonParameters) {
				this.ShowOverSeances =
					typeof jsonParameters.showoverseances === 'boolean' ? jsonParameters.showoverseances : jsonParameters.showoverseances === 'true';
			}
			if ('showdisclaimer' in jsonParameters) this.ShowDisclaimer = jsonParameters.showdisclaimer;
			if ('shownoticketsseances' in jsonParameters) this.ShowNoTicketsSeances = jsonParameters.shownoticketsseances === 'true';

			if ('tariffsseancesfilter' in jsonParameters) {
				this.TariffsSeancesFilter =
					typeof jsonParameters.tariffsseancesfilter == 'string'
						? jsonParameters.tariffsseancesfilter.toLocaleLowerCase() === 'true'
						: jsonParameters.tariffsseancesfilter;
			}
			if ('groupid' in jsonParameters && jsonParameters.groupid != null) this.GroupId = jsonParameters.groupid;
			if ('rtariffsid' in jsonParameters) this.RewriteTariffsId = jsonParameters.rtariffsid;
			if ('rewritetariffsid' in jsonParameters) this.RewriteTariffsId = jsonParameters.rewritetariffsid;
			if ('lessonstariffsid' in jsonParameters) this.LessonsTariffsId = jsonParameters.lessonstariffsid;

			// ������� ������� ��� ������������
			if ('grouplessonstariffsid' in jsonParameters) this.GroupLessonsTariffsId = jsonParameters.grouplessonstariffsid;

			if ('grouplessonsskillstariffs' in jsonParameters) this.GroupLessonsSkillsTariffs = jsonParameters.grouplessonsskillstariffs;
			if ('multidayslessonsskillstariffs' in jsonParameters) this.MultiDaysLessonsSkillsTariffs = jsonParameters.multidayslessonsskillstariffs;
			if ('personallessonsskillstariffs' in jsonParameters) this.PersonalLessonsSkillsTariffs = jsonParameters.personallessonsskillstariffs;
			if ('coachid' in jsonParameters && jsonParameters.coachid != null && jsonParameters.coachid != '') this.CoachId = jsonParameters.coachid;
			if ('forcetab' in jsonParameters) this.ForceTab = jsonParameters.forcetab;
			if ('ordercomment' in jsonParameters) this.OrderComment = jsonParameters.ordercomment;
			if ('marketemployeeid' in jsonParameters) this.MarketEmployeeId = jsonParameters.marketemployeeid;
			if ('marketbillid' in jsonParameters) this.MarketBillId = jsonParameters.marketbillid;
			if ('locale' in jsonParameters) this.Locale = jsonParameters.locale;
			if ('disablemultipleseances' in jsonParameters) this.DisableMultipleSeances = jsonParameters.disablemultipleseances;
			if ('ismobile' in jsonParameters) this.IsMobile = jsonParameters.ismobile;
			if ('minimap' in jsonParameters) this.Minimap = jsonParameters.minimap;
			if ('requiredloyalty' in jsonParameters) this.RequiredLoyalty = jsonParameters.requiredloyalty;

			// http://redmine.isd.su/issues/12394

			if ('tariffsid' in jsonParameters) {
				if (jsonParameters.tariffsid === false || jsonParameters.tariffsid === 'false') this.TariffsId = [-1];
				else {
					if (typeof jsonParameters.tariffsid === 'string') {
						let byComa = new String(jsonParameters.tariffsid).split(',');
						if (byComa.length) this.TariffsId = byComa.map((t) => parseInt(t.trim()));
					} else this.TariffsId = jsonParameters.tariffsid;
				}
			}

			if ('lessonslocations' in jsonParameters && jsonParameters.lessonslocations != null && jsonParameters.lessonslocations != '')
				this.LessonsLocation = jsonParameters.lessonslocations;

			if ('onlytab' in jsonParameters) {
				if (typeof jsonParameters.onlytab == 'string') {
					let byComa = new String(jsonParameters.onlytab).split(',');
					if (byComa.length) this.OnlyTab = byComa.map((t) => t.trim());
					else this.OnlyTab = [jsonParameters.onlytab];
				} else {
					this.OnlyTab = jsonParameters.onlytab;
				}
			}

			if ('nspk' in jsonParameters) this.NspkEnabled = jsonParameters.nspk.toLowerCase() === 'true';

			// http://redmine.isd.su/issues/7107
			if ('forceseanceid' in jsonParameters) this.ForceSeanceId = jsonParameters.forceseanceid;
			if ('forcesectorid' in jsonParameters) this.ForceSectorId = jsonParameters.forcesectorid;

			//MobileApp settings
			if ('platform' in jsonParameters) this.Platform = jsonParameters.platform;
			if ('deviceid' in jsonParameters) this.DeviceId = jsonParameters.deviceid;
			if ('pushtoken' in jsonParameters) this.PushToken = jsonParameters.pushtoken;
			if ('nfc' in jsonParameters) this.NfcSupported = jsonParameters.nfc.toLowerCase() === 'true';
			if ('utm' in jsonParameters) this.UtmLabels = jsonParameters.utm;

			return true;
		} catch (ex) {
			console.error(ex);
			return false;
		}
	}

	public ParseHashParams(ignoreWindowName: boolean = false): boolean {
		let paramsHash = decodeURI(
			location.hash.replace('#', '') // remove leading # charater so JSON can be parsed
			//.replace(/%2C/g, ',') // In case random %2c comas sneak into url - replace them with proper comas so JSON can be parsed
		);
		return this.HandleBaseParams(paramsHash, ignoreWindowName);
	}

	public HandleJson(administrationParameters: string | null): boolean {
		if (administrationParameters != null && administrationParameters != '') {
			const adminJsonParams = JSON.parse(administrationParameters);

			if (
				this._hashParams != null &&
				!('servicecalendar' in this._hashParams) &&
				adminJsonParams != null &&
				'servicecalendar' in adminJsonParams
			)
				this._hashParams.servicecalendar = adminJsonParams.servicecalendar;

			this._administrationParams = adminJsonParams;

			return this.SetJsonParameters(adminJsonParams);
		} else if (this._hashParams) return this.SetJsonParameters(this._hashParams);
		else return true;
	}

	// #region Getters

	get CartMaxItems(): number {
		if (this.CartMinItems > this._cartmaxitems) {
			console.error('Максимальное кол-во товаров в корзине ниже минимального. Проверьте параметры виджета');
			this._cartmaxitems = this.CartMinItems;
		}

		return this._cartmaxitems;
	}

	set CartMaxItems(n: number) {
		this._cartmaxitems = n;
	}

	get CartMinItems(): number {
		let cartminitems = 1;
		if (this._hashParams && 'cartminitems' in this._hashParams) cartminitems = Math.abs(parseInt(this._hashParams.cartminitems)) || 1;
		return cartminitems;
	}

	get MinimapAspect(): number {
		let minimapaspect = 0.2;
		if (this._hashParams && 'minimapaspect' in this._hashParams) minimapaspect = Math.abs(parseInt(this._hashParams.minimapaspect)) || 0.2;
		return minimapaspect;
	}

	get SaleTermsType(): SaleTermsType {
		if (this._hashParams != null && 'saletermstype' in this._hashParams) {
			let type = (this._hashParams.saletermstype as string).toLocaleLowerCase();
			try {
				return <SaleTermsType>type;
			} catch {
				return SaleTermsType.Default;
			}
		} else if (this._administrationParams != null && 'saletermstype' in this._administrationParams) {
			let adminType = (this._administrationParams.saletermstype as string).toLocaleLowerCase();

			try {
				return <SaleTermsType>adminType;
			} catch {
				return SaleTermsType.Default;
			}
		} else {
			return SaleTermsType.Default;
		}
	}

	get WidgetType(): WidgetType {
		if (this._hashParams != null && 'type' in this._hashParams) {
			let type = (this._hashParams.type as string).toLocaleLowerCase();
			try {
				return <WidgetType>type;
			} catch {
				return WidgetType.Instant;
			}
		} else {
			return WidgetType.Instant;
		}
	}

	get Localization(): Localization {
		if (this._hashParams != null && 'locale' in this._hashParams) {
			let locale = (this._hashParams.locale as string).toLocaleLowerCase();
			try {
				return <Localization>locale;
			} catch {
				return Localization.RU;
			}
		} else {
			return Localization.RU;
		}
	}

	get ArenaCalendar(): ArenaCalendarType {
		if (this._hashParams != null && 'arenacalendar' in this._hashParams) {
			let type = (this._hashParams.arenacalendar as string).toLocaleLowerCase();
			try {
				return <ArenaCalendarType>type;
			} catch {
				return ArenaCalendarType.None;
			}
		} else {
			return ArenaCalendarType.None;
		}
	}

	get TariffsCalendar(): TariffsCalendarType {
		if (this._hashParams != null && 'servicecalendar' in this._hashParams) {
			let type = (this._hashParams.servicecalendar as string).toLocaleLowerCase();
			try {
				return <TariffsCalendarType>type;
			} catch {
				return TariffsCalendarType.Field;
			}
		} else {
			return TariffsCalendarType.Field;
		}
	}

	get IdentityServerUrl(): string {
		if (typeof window === 'undefined') return 'https://idsrv-test.moipass.ru/';

		if (window.location.host.toLowerCase().indexOf('test') == -1 && window.location.host.toLowerCase().indexOf('localhost') == -1) {
			return 'https://idsrv.moipass.ru/';
		} else {
			return 'https://idsrv-test.moipass.ru/';
		}
	}

	get IsDevVersion() {
		if (typeof window === 'undefined') return true;
		const HOSTNAME = window.location.host.toLowerCase();
		return HOSTNAME.indexOf('test') != -1 || HOSTNAME.indexOf('localhost') != -1;
	}

	// #endregion

	public CheckSettings(): boolean {
		let result = true;
		let error = '';

		if (this.Disableddates && this.Forcedate) {
			if (this.Disableddates.includes(this.Forcedate)) {
				console.warn(`[WARNING] Array of disabled dates includes ${this.Forcedate}. Forced date has been removed from disabled dates...`);
				this.Disableddates.splice(this.Disableddates.indexOf(this.Forcedate), 1);
			}
		}

		if (!Number.isInteger(this.CartMinItems)) {
			error += "Invalid 'cartminitems'; \n";
			result = false;
		}

		if (this.CartMinItems < 0 || this.CartMinItems > 59) {
			error += "'cartminitems' must be between the range of 0 and 59; \n";
			result = false;
		}

		if (!Number.isInteger(this.CartMaxItems)) {
			error += "Invalid 'cartmaxitems'; \n";
			result = false;
		}

		if (this.CartMaxItems < 0 || this.CartMaxItems > 60) {
			error += "'cartmaxitems' must be between the range of 1 and 60; \n'";
			result = false;
		}

		if (this.CartMinItems > this.CartMaxItems) {
			error += "'cartminitems' can't be more than cartmaxitems; \n";
			result = false;
		}

		if (this._hashParams && 'arenacalendar' in this._hashParams) {
			let type = this._hashParams.arenacalendar as string;
			if (type != 'none' && type != 'page' && type != 'field') {
				error += "'arenacalendar' can be 'none', 'page' or 'field'; \n";
				result = false;
			}
		}

		if (this._hashParams && 'servicecalendar' in this._hashParams) {
			let type = this._hashParams.servicecalendar as string;
			if (type != 'page' && type != 'field') {
				error += "'servicecalendar' can be 'page' or 'field'; \n";
				result = false;
			}
		}

		if (this._hashParams && 'showoverseances' in this._hashParams) {
			let showoverseances = this._hashParams.showoverseances;
			if (typeof showoverseances !== 'boolean') {
				let value = showoverseances as string;
				if (value != 'true' && value != 'false') {
					error += "'showoverseances' can be 'true' or 'false' of string type, or boolean type; \n";
					result = false;
				}
			}
		}

		if (this._hashParams && 'shownoticketsseances' in this._hashParams) {
			let shownoticketsseances = this._hashParams.shownoticketsseances;
			if (typeof shownoticketsseances !== 'boolean') {
				let value = shownoticketsseances as string;
				if (value != 'true' && value != 'false') {
					error += "'shownoticketsseances' can be 'true' or 'false' of string type, or boolean type; \n";
					result = false;
				}
			}
		}

		if (error != '') console.error('Error in input parameters: \n' + error);
		return result;
	}
}

class privateSettings {
	ordersFilter = new OrdersFilter();
}
