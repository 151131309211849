export class ServiceRegisteredRecord {
	Key: symbol;
	Value: any;
	IsSingelton: boolean;

	constructor(key: symbol, value: any, isSingelton?: boolean) {
		this.Key = key;
		this.Value = value;
		this.IsSingelton = isSingelton != null ? isSingelton : true;
	}
}
