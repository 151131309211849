
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from 'web-platform-vuetify';
import GlobalService, {
	IContext,
	IdentitySymbols,
	IdentityChangedEventArgs,
	INotificationService,
	NotificationSymbols,
	Initializer,
	ILoyaltyProvider,
	LoyaltySymbols,
	ISaleService,
	SaleSymbols,
	AppSettings,
	LuzhnikFeedbackProvider,
} from 'web-platform-core-ui';

@Component
export default class LuzhnikiLKApp extends mixins(Vue, WpBaseComponent) {
	protected _saleService!: ISaleService;
	protected _notificationService!: INotificationService;
	protected _loyaltyProvider!: ILoyaltyProvider;

	Context!: IContext;
	IsHaveLoyalty: boolean = false;
	IsInitializedError: boolean = true;
	IsLoaded: boolean = false;
	NeedAuth: boolean = true;
	Drawer: boolean = true;
	IsAuthentificated: boolean = false;
	Dialog: boolean = false;
	UserName: string = '';
	snackbar: boolean = true;
	Settings!: AppSettings;
	RemoveDialog: boolean = false;

	Social: Array<{ icon: string; href: string }> = [
		{
			icon: 'facebook',
			href: 'https://www.facebook.com/Luzhniki?_rdc=2&_rdr',
		},
		{
			icon: 'vk',
			href: 'https://vk.com/luznikiofficial',
		},
		{
			icon: 'instagram',
			href: 'https://www.instagram.com/luzhniki_official/',
		},
	];

	IsOffline: boolean = false;
	OnlineStatusChange(isOnline: boolean): void {
		this.IsOffline = !isOnline;
	}

	ToggleDrawer(): void {
		this.Drawer = !this.Drawer;
	}

	created(): void {
		this._saleService = this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
		this.Context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);

		this.Settings = GlobalService.GetSettings();
	}

	async mounted(): Promise<void> {
		this.Context.IdentityChanged.Subscribe(async (sender: any, e: IdentityChangedEventArgs) => {
			if (!this.IsLoaded) return;

			if (this.Context.CurrentIdentity.IsAuthentificated) {
				this.GetLoyalty();
			} else {
				this.NeedAuth = true;
				this.IsAuthentificated = false;
				this.Context.SignIn();
			}
		});

		let initResult = await Initializer.InitializeAsync(this.GlobalService);

		if (!initResult.Success) {
			this.IsInitializedError = true;
			Error(`Initilization error: ${initResult.ErrorMessage}`);
		}

		if (this.Context.CurrentIdentity.IsAuthentificated) {
			this.NeedAuth = false;
			this.IsAuthentificated = true;
			await this.GetLoyalty();
		} else {
			if (this.$route.meta.requiresAuth === true) {
				this.Context.SignIn();
			} else {
				this.NeedAuth = false;
			}
		}

		this.IsInitializedError = false;
		this.IsLoaded = true;
		removeMainLoadingWrapper();
	}

	SignOut() {
		let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		context.SignOut();

		if (this.$route.meta.requiresAuth === true) {
			this.NeedAuth = true;
			this.IsHaveLoyalty = false;
		}
	}

	async GetLoyalty(): Promise<void> {
		try {
			let result = await this._loyaltyProvider.GetIsdUserLoyaltyAsync();

			if (result.Success) {
				if (result.Data == null) {
					this.Dialog = true;
				} else {
					this.IsHaveLoyalty = true;
					this.IsAuthentificated = true;
					this.NeedAuth = false;
				}
			} else {
				this._notificationService.Error('', result?.ErrorMessage ?? 'Произошла непредвиденная ошибка');
			}
		} catch {}
	}

	async RegisteredLoyalty(success: boolean) {
		if (success) {
			this.Dialog = false;
			this.IsHaveLoyalty = true;

			let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
			let result = await loyaltyProvider.GetIsdUserLoyaltyAsync();

			if (result.Success && result.Data != null) {
				this.NeedAuth = false;
				this.IsAuthentificated = true;
			}
			this.$router.push({ name: 'wp-isd-loyalty' });
		}
	}

	CancelRegistration() {
		let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		context.SignOut();
		this.Dialog = false;
		this.NeedAuth = true;
	}

	async RemoveAccount() {
		this.RemoveDialog = false;
		let provider = new LuzhnikFeedbackProvider(this.Context);
		let result = await provider.SendAsync(
			'Удаление аккаунта',
			'Email',
			'Заявка на удаление учетной записи и связанной с ней программы лояльности',
			this.Context.CurrentIdentity.Username,
			this.Context.CurrentIdentity.Phone ?? ''
		);

		if (result.Success) {
			this._notificationService.Success('Отправка', 'Ваша заявка отправлена и будет рассмотрена в кратчайшие сроки');
		} else {
			this._notificationService.Error('Ошибка', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');
		}
	}

	get isAccountRemovalPage() {
		return this.$route.name === 'luzh-lk-account-removal';
	}
}
