import { IEcomData } from './i-ecom-data';
import { MetricsSymbols } from './symbols';
import { inject, injectable } from 'inversify';
import MetricsService from './metrics-service';
import IMetricsProvider from './i-metrics-provider';

@injectable()
export default class EcomMetricsService extends MetricsService {
	protected _yandexEcommerce: boolean = false;

	constructor(@inject(MetricsSymbols.MetricsProvider) provider: IMetricsProvider) {
		super(provider);
	}

	async RegistrateEvent(eventId: symbol, arg?: any, ecomData?: IEcomData): Promise<void> {
		super.RegistrateEvent(eventId, arg);

		if (this._yandexEcommerce && ecomData != null) {
			let data = ecomData.GetEcomData(eventId);
			console.log(data);
			if (data != null) (window as any).dataLayer.push({ ecommerce: data });
		}
	}

	async LoadCountersAsync(): Promise<void> {
		await super.LoadCountersAsync();

		if (this._countersIsLoaded && this._counters.some((x) => x.Ecommerce)) {
			this._yandexEcommerce = true;
			(window as any).dataLayer = (window as any).dataLayer ?? [];
		}
	}
}
