import { inject, injectable } from 'inversify';

// WARNING! Importing symbols from not specific path (like '..' will cause errors) on runtime
import { TagSymbols } from './symbols';

import { ITagProvider, ITagService, Tag } from '..';
import { ActionResult, ErrorTypes, Tariff } from '..';

@injectable()
export default class TagService implements ITagService {
	protected readonly _provider: ITagProvider;
	private _cache: Map<number, Tag[]> = new Map();
	private _allTags: Tag[] = [];

	constructor(@inject(TagSymbols.TagProvider) provider: ITagProvider) {
		this._provider = provider;
	}

	async GetTag(id: number): Promise<ActionResult> {
		let find = this._allTags.find((tag) => tag.Id == id);
		if (find) return ActionResult.SuccessData(find);

		let get = await this._provider.GetTag(id);
		if (!get.Success || !get.Data) return ActionResult.FailedData(get?.ErrorType || ErrorTypes.InternalError, get.Data, get.ErrorMessage);

		this._allTags.push(get.Data);
		return ActionResult.SuccessData(get.Data);
	}

	async GetTagsByTariff(tariffId: number): Promise<ActionResult> {
		let filter = this._allTags.filter((tag) => {
			return tag.TariffIds.find((tariff: Tariff) => tariff.Id == tariffId);
		});

		//if (filter.length) this._cache.set(tariffId, filter); // Uncomment this after you've figured out the implementation of GetTagsByTariffs() method
		this._cache.set(tariffId, filter);

		let isCached = this._cache.get(tariffId);
		if (isCached) return ActionResult.SuccessData(isCached);

		let tags = await this._provider.GetTagsByTariff(tariffId);
		if (!tags.Success || !tags.Data) return ActionResult.FailedData(tags?.ErrorType || ErrorTypes.InternalError, tags.Data, tags.ErrorMessage);

		this._cache.set(tariffId, tags.Data);

		tags.Data.forEach((tag) => {
			if (!this._allTags.find((t) => tag.Id == t.Id)) this._allTags.push(tag);
		});
		return ActionResult.SuccessData(tags.Data);
	}

	async GetTagsByTariffs(tariffsIds: Array<number>): Promise<ActionResult> {
		let tags = await this._provider.GetTagsByTariffs(tariffsIds);
		if (!tags.Success || !tags.Data) return ActionResult.FailedData(tags?.ErrorType || ErrorTypes.InternalError, tags.Data, tags.ErrorMessage);

		tags.Data.forEach((tag) => {
			if (!this._allTags.find((t) => tag.Id == t.Id)) this._allTags.push(tag);
		});

		return ActionResult.SuccessData(tags.Data);
	}
}
