import { inject, injectable } from 'inversify';
import { RestClient } from 'typed-rest-client';
import ActionResult from '../Common/action-result';
import ITagProvider from './i-tag-provider';
import Tag from './tag';
import { AppSettings } from '../Global/app-settings';
import IContext from '../Identity/i-context';
import { ErrorTypes } from '../Common/error-types';
import GlobalService from '../Global/global-service';
import { IdentitySymbols } from '../Identity';

@injectable()
export default class DefaultTagProvider implements ITagProvider {
	protected _settings: AppSettings;
	protected _context: IContext;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this._context = context;
		this._settings = GlobalService.GetSettings();
	}

	async GetTag(id: number): Promise<ActionResult> {
		let client = this.createClient();
		let request = await client.get<AjaxResult<Tag>>(`/websale/api/tags/${id}`);

		if (request.statusCode != 200) return ActionResult.FailedData(ErrorTypes.InternalError, 'Запрос не прошёл успешно');
		if (request.result == null) return ActionResult.FailedData(ErrorTypes.NotFoundData, 'Пустой ответ');

		return ActionResult.SuccessData(request.result.Data);
	}

	async GetTagsByTariff(tariffId: number): Promise<ActionResult> {
		let client = this.createClient();
		let request = await client.get<AjaxResult<Tag>>(`/websale/api/tags/by/tariff/${tariffId}`);

		if (request.statusCode != 200) return ActionResult.FailedData(ErrorTypes.InternalError, 'Запрос не прошёл успешно');
		if (request.result == null) return ActionResult.FailedData(ErrorTypes.NotFoundData, 'Пустой ответ');

		return ActionResult.SuccessData(request.result.Data);
	}

	async GetTagsByTariffs(tariffsIds: Array<number>): Promise<ActionResult> {
		let client = this.createClient();

		let request = await client.create<AjaxResult<Tag>>(`/websale/api/tags/by/tariffs/`, {
			OrganizationId: this._settings.CurrentSite?.OrganizationId,
			TariffIds: tariffsIds,
		});

		if (request.statusCode != 200) return ActionResult.FailedData(ErrorTypes.InternalError, 'Запрос не прошёл успешно');
		if (request.result == null) return ActionResult.FailedData(ErrorTypes.NotFoundData, 'Пустой ответ');

		return ActionResult.SuccessData(request.result.Data);
	}

	private createClient() {
		let authHeaders = this.AuthorizationHeaders;
		// Remove SiteId from auth headers (hope it doesn't delete it for all instances)
		delete authHeaders.headers.SiteId;

		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? authHeaders : this.NotAuthorizationHeaders
		);

		return client;
	}

	get NotAuthorizationHeaders(): any {
		return {
			headers: {
				SiteId: this._settings.SiteId,
				SessionId: this._settings.SessionId,
				User: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		};
	}

	get AuthorizationHeaders(): any {
		return {
			headers: {
				SiteId: this._settings.SiteId,
				SessionId: this._settings.SessionId,
				User: this._context.CurrentIdentity.UserId,
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				moduleId: this._settings.SiteId,
			},
		};
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
