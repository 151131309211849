import Vue from 'vue';
import Router from 'vue-router';

import { WpNewsArticle, WpNews, WpOrders, WpOrder, WpChangePassword } from 'web-platform-vuetify';
import { LuzhLoyalty, LuzhFeedback, LuzhBio } from 'web-platform-vuetify/src/Luzhniki';
import { LuzhSettings } from './LuzhSettings/luzh-settings';
import LuzhAccountRemoval from './LuzhAccountRemoval/luzh-account-removal.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/account/removal',
			name: 'luzh-lk-account-removal',
			component: LuzhAccountRemoval,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: '/profile',
			name: 'wp-isd-loyalty',
			component: LuzhLoyalty,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/bio',
			name: 'wp-isd-bio',
			component: LuzhBio,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/orders',
			name: 'wp-orders',
			component: WpOrders,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/order/:OrderId',
			name: 'wp-order',
			component: WpOrder,
			props: true,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/offers',
			name: 'wp-news',
			component: WpNews,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/article/:ArticleId',
			name: 'wp-news-article',
			component: WpNewsArticle,
			props: true,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/feedback',
			name: 'luzh-feedback',
			component: LuzhFeedback,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: '/settings/',
			name: 'luzh-settings',
			component: LuzhSettings,
			props: true,
			meta: {
				requiresAuth: true,
				title: 'Настройки',
			},
		},
		{
			path: '/changepassword/',
			name: 'wp-change-password',
			component: WpChangePassword,
			props: true,
			meta: {
				requiresAuth: true,
				title: 'Смена пароля',
				routeback: 'luzh-settings',
			},
		},
	],
});
