import IProfileProvider from './i-profile-provider';
import UserProfile from './user-profile';
import { ActionResult, ErrorTypes } from './../Common';
import { RestClient } from 'typed-rest-client';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import { inject, injectable } from 'inversify';
import GlobalService from './..';
import { AppSettings } from './../Global/app-settings';

@injectable()
export default class DefaultProfileProvider implements IProfileProvider {
	private _context: IContext;
	protected _settings: AppSettings;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this._context = context;
		this._settings = GlobalService.GetSettings();
	}

	async GetProfileAsync(): Promise<ActionResult<UserProfile>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				SessionId: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		});

		try {
			let response = await client.get<AjaxResult<UserProfile>>(`/account/api/profile/`);

			if (response.result != null && response.result.Result != 0) return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);

			return ActionResult.SuccessData<UserProfile>(response.result?.Data);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);
		}
	}

	async EditProfileAsync(profile: UserProfile): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				SessionId: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		});

		try {
			let response = await client.replace<AjaxResult<UserProfile>>(`/account/api/profile/`, profile);

			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);
		}

		return ActionResult.Success();
	}

	async ChangeEmailAsync(email: string): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				SessionId: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		});

		try {
			let response = await client.replace<AjaxResult<number>>(`/account/api/profile/email/`, { Email: email });

			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);
		}

		return ActionResult.Success();
	}

	async SetPhoneAsync(phone: string): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				SessionId: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		});

		try {
			let response = await client.replace<AjaxResult<number>>(`/account/api/profile/phone/`, { Phone: phone });

			if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError);
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);
		}

		return ActionResult.Success();
	}

	async ChangePasswordAsync(oldPassword: string, newPassword: string, confirmPassword: string): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				SessionId: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		});

		try {
			let response = await client.replace<AjaxResult<number>>(`/account/api/profile/changepassword/`, {
				OldPassword: oldPassword,
				NewPassword: newPassword,
				ConfirmPassword: confirmPassword,
			});

			if (response.result != null && response.result.Result != 0) {
				if (response.result.Result == 8) return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);

				return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
			}
		} catch (e) {
			console.error(e);
			return ActionResult.FailedData<UserProfile>(ErrorTypes.InternalError);
		}

		return ActionResult.Success();
	}

	async SendAsync(
		topic: string,
		method: string,
		message: string,
		email: string | null = null,
		phone: string | null = null,
		name: string | null = null
	): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
			headers: {
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				moduleId: this._settings.SiteId,
			},
		});

		let response = await client.create(`api/lk/feedback/`, {
			Phone: phone ?? this._context.CurrentIdentity.Phone,
			Email: email ?? this._context.CurrentIdentity.Username,
			Category: topic,
			ContactWay: method,
			Message: message,
			Name: name,
		});

		if (response.statusCode != 200) return ActionResult.Failed(ErrorTypes.InternalError);

		return ActionResult.Success();
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
}
