import { EcomAddToCart } from './../Metrics/i-ecom-data';
import { CartItem, CartItemTypes } from '../Cart';
import { ActionResult, ErrorTypes } from '../Common';
import { ReservationItem, ReservationItemTypes } from '../Sale';

export default class NewContractCartItem extends CartItem {
	private _id: number = 0;
	private _code: number = 0;
	private _date: string = '';
	private _price: number = 0;
	private _type: CartItemTypes = CartItemTypes.NewClubContract;
	private _name: string = '';

	get Info(): string {
		return `<strong>${this._name}</strong>`;
	}
	get Type(): CartItemTypes {
		return this._type;
	}
	get Price(): number {
		return this._price;
	}
	get Count(): number {
		return 1;
	}
	public get Dates(): Array<string> {
		let result = new Array<string>();

		if (this._date !== undefined) {
			result.push(this._date);
		}

		return result;
	}
	public get AdditionalInfo(): any {
		return { Id: this._id, Name: this._name, Code: this._code, Price: this._price };
	}
	get MetricsId(): any {
		return this._id;
	}
	get IsCanIncreaseCount(): boolean {
		return false;
	}
	get IsCanReduceCount(): boolean {
		return false;
	}
	get IsAvailable(): boolean {
		return true;
	}

	constructor(id: number, date: string, name: string, price: number, code: number) {
		super();
		this._id = id;
		this._code = code;
		this._name = name;
		this._price = price;
		this._date = date;
	}

	IncreaseCount(): ActionResult {
		return ActionResult.Failed(ErrorTypes.CartError, 'Превышено допустимое количество для данного типа услуг');
	}
	ReduceCount(): ActionResult {
		return ActionResult.Failed(ErrorTypes.CartError, 'Достигнуто минимальное возможное количество');
	}
	IsConflictItem(item: CartItem): boolean {
		return false;
	}
	IsEqualsItem(item: CartItem): boolean {
		return false;
	}
	CombineItem(item: CartItem): ActionResult {
		return ActionResult.Failed(ErrorTypes.CartError, 'Превышено допустимое количество для данного типа услуг');
	}
	GetReservationItem(): ReservationItem[] {
		return [
			new ReservationItem(1, ReservationItemTypes.NewClubContract, null, this._date, null, null, null, null, null, null, null, null, this._id),
		];
	}
	async RemoveItem(): Promise<ActionResult> {
		return ActionResult.Success();
	}

	IsAssociatedReservationItem(item: ReservationItem): boolean {
		return item.ItemType == ReservationItemTypes.NewClubContract && item.Count == 1 && this._date == item.Date && this._id == item.ClubContractId;
	}

	GetEcomData(eventId: Symbol): EcomAddToCart {
		throw new Error('Not implemented');
	}
}
