import 'reflect-metadata';
import Vue from 'vue';
import LuzhnikiLKApp from './LuzhnikiLKApp.vue';
import GlobalService, {
	GlobalSymbols,
	INotificationService,
	NotificationSymbols,
	AppSettings,
	ModifiedWebSaleUserIdentity,
	IDateHandler,
	DateHandlerSymbols,
	LocalizationSymbols,
	ILocalizationService,
	LocaleUserStorage,
	ServiceRegisteredRecord,
	OrderServiceSymbols,
	LuzhnikiOrderProvider,
	LuzhnikiOrderService,
} from 'web-platform-core-ui';
import vuetify from './vuetify';
import router from './router';
import VueTheMask from 'vue-the-mask';
const Inputmask = require('inputmask');

import * as moment from 'moment';
import 'moment/locale/ru';

import loadStaticLocaliztionDictionaries from 'web-platform-vuetify/src/Localization';

moment.locale('ru');

Vue.use(VueTheMask);

Vue.component('VOffline', require('v-offline'));
Vue.directive('input-mask', {
	bind: function (el) {
		if (el.tagName == 'INPUT') new Inputmask().mask(el);
		else {
			let inp = el.getElementsByTagName('input')[0];
			new Inputmask().mask(inp);
		}
	},
	unbind: function (el) {
		if (el.tagName == 'INPUT') Inputmask.remove(el);
		else {
			let inp = el.getElementsByTagName('input')[0];
			Inputmask.remove(inp);
		}
	},
});

const settings = new AppSettings();
GlobalService.SetSettings<AppSettings>(settings);

if (settings.IsDevVersion) {
	Vue.config.productionTip = false;
	Vue.config.devtools = true;
	settings.ServerUrl = 'https://lk-luzh-test.moipass.ru';

	// здесь мы установили коснтанты для сайта и программлояльности т.к. это для личного кабинета Лужники
	settings.SiteId = 112;
	settings.LoyaltyId = 8;
} else {
	Vue.config.productionTip = true;
	Vue.config.devtools = false;

	settings.ServerUrl = 'https://lk.luzhniki.ru';
	settings.SiteId = 190;
	settings.LoyaltyId = 5;
}

let redifined = [];

redifined.push(new ServiceRegisteredRecord(OrderServiceSymbols.OrderProvider, LuzhnikiOrderProvider, true));
redifined.push(new ServiceRegisteredRecord(OrderServiceSymbols.OrderService, LuzhnikiOrderService, true));

const store: any = new LocaleUserStorage();
const globalService = new GlobalService();
const context = new ModifiedWebSaleUserIdentity(store);

globalService.LoadWebSaleConfiguration(context, redifined);
globalService.Get<IDateHandler>(DateHandlerSymbols.DateHandlerService).Locale('ru');
let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
loadStaticLocaliztionDictionaries(localizationService);
localizationService.ChangeLocalizationAsync(settings.Localization);

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth && context.IsInitialized) {
		if (!context.CurrentIdentity.IsAuthentificated) context.SignIn();
		else next();
	} else {
		next();
	}
});

new Vue({
	vuetify,
	router,
	render: (h) => h(LuzhnikiLKApp),
	provide: {
		[GlobalSymbols.GlobalService]: globalService,
	},
}).$mount('#app');

function _errorEventHandler(errorMsg: string, url: string, lineNumber: string): void {
	let notificationService = globalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	notificationService.Error('Ошибка', errorMsg);

	console.log(`error: ${errorMsg}, url: ${url}, lineNumber: ${lineNumber}`);
}

const _global = (window || global) as any;
_global.onerror = _errorEventHandler;
