import './wp-rules.scss';

import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { OrganizationSymbols } from 'web-platform-core-ui/src/Organizations';
import { Watch, Prop } from 'vue-property-decorator';

import { IdentitySymbols, IdentityChangedEventArgs, BaseSystemLocalizationDictionary, IOrganizationService } from 'web-platform-core-ui';
import { IContext } from 'web-platform-core-ui';

@Component({
	template: require('./wp-rules.html'),
})
export default class WpRules extends mixins<WpBaseComponent<WpRulesDictionary>>(WpBaseComponent, Vue) {
	@Prop(Boolean) public RulesTrigger!: boolean;

	@Watch('RulesTrigger')
	OnRulesTrigger(val: boolean, _oldVal: boolean) {
		this.RulesModal = val;
	}

	protected _service!: IOrganizationService;
	protected _context!: IContext;

	Rules: string = '';
	RulesModal: boolean = false;
	RulesTab: number = 0;

	Email: string = '';

	created() {
		this._service = this.GlobalService.Get<IOrganizationService>(OrganizationSymbols.OrganizationProvider);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
	}

	async mounted() {
		this.Email = this._context.CurrentIdentity.Username;
		this._context.IdentityChanged.Subscribe(this.IdentityChanged);

		let rules = await this._service.GetOrganizationRulesAsync();
		if (rules.Data != null && rules.Data != '') {
			this.Rules = rules.Data;
		} else {
			this.RulesTab = 1;
		}
	}

	IdentityChanged(e: any, args: IdentityChangedEventArgs) {
		this.Email = args.Identity.Username;
	}

	RulesConfirm(): void {
		if (this.RulesTab == 0) {
			this.RulesTab = 1;
		} else {
			this.RulesTab = 0;
			this.RulesModal = false;
			this.$emit('RulesConfirm', true);
		}
	}
	RulesDecline(): void {
		this.RulesTab = this.Rules != null && this.Rules != '' ? 0 : 1;
		this.RulesModal = false;
		this.$emit('RulesDecline');
	}
}

export class WpRulesDictionary extends BaseSystemLocalizationDictionary {
	GenericTerms: string = 'Условия';
	GenericTermsISDS: string = 'Условия ISDS';
	GenericIAgree: string = 'Подтверждаю и ';
	GenericPay: string = 'оплатить';
}
