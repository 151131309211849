import './wp-market-rules.scss';

import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';

import GlobalService, {
	IMarketService,
	MarketSymbols,
	AppSettings,
	INotificationService,
	Cart,
	CartItem,
	MarketRule,
	MarketDateTimeRange,
	IdentitySymbols,
	UserProfile,
	IProfileService,
	ProfileSymbols,
	NotificationSymbols,
	MarketTariffService,
	MarketBillCartItem,
	MarketBillStatus,
	BaseSystemLocalizationDictionary,
} from 'web-platform-core-ui';
import { IContext } from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';

export const wpMarketRulesProp = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
	},
});

@Component({
	template: require('./wp-market-rules.html'),
})
export default class WpMarketRules extends mixins<WpBaseComponent<WpMarketRulesDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpMarketRulesProp,
	Vue
) {
	private static Initialized: boolean = false;
	private _marketService!: IMarketService;
	protected _notificationService!: INotificationService;
	private _context!: IContext;

	TariffServices: Array<MarketTariffService> = [];
	MarketRules: Array<MarketRule> = [];
	Settings?: AppSettings;

	SelectedItemId: string = '';
	Items: Array<MarketRuleItem> = [];
	Loading: boolean = true;
	Dialog: boolean = false;
	Date: string = '';

	DatepickerSettings: any = {};
	Datepicker: boolean = false;
	StartDatepicker(): void {
		this.DatepickerSettings = this.Settings;
		this.Datepicker = true;
	}

	AddToCart(cartItem: CartItem): void {
		let result = this.Cart.AddItem(cartItem);

		if (!result.Success) {
			this._notificationService.Error('Ошибка', result.ErrorMessage ? result.ErrorMessage : 'Непредвиденная ошибка');
		}
	}

	async mounted() {
		this.Settings = GlobalService.GetSettings<AppSettings>();

		this._marketService = this.GlobalService.Get<IMarketService>(MarketSymbols.MarketService);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

		if (this.Settings.MarketBillId != null) {
			if (WpMarketRules.Initialized) {
				parent.postMessage({ status: 'wp-close-widget' }, '*');
				return;
			}

			let billResult = await this._marketService.InitializeByBillAsync(this.Settings.MarketBillId);

			if (billResult.Success && billResult.Data != null) {
				if (billResult.Data.Status[0] == MarketBillStatus.New) {
					let marketBillCartItem = new MarketBillCartItem(billResult.Data);
					this.Cart.AddItem(marketBillCartItem);

					this.$emit('ReservateBill');
				} else {
					window.console.error('Bill not new status');
					parent.postMessage({ status: 'wp-close-widget' }, '*');
				}

				return;
			}

			WpMarketRules.Initialized = true;
			return;
		}

		let resultRules = await this._marketService.GetRulesAsync();

		this.StartDatepicker();
		if (resultRules.Success && resultRules.Data != undefined) {
			this.MarketRules = resultRules.Data.Rule;
			this.TariffServices = resultRules.Data.Service;
			await this.LoadSchedule();
		}

		if (this._context.CurrentIdentity.IsAuthentificated) {
			if (
				this._context.CurrentIdentity.Phone == undefined ||
				this._context.CurrentIdentity.Phone == null ||
				this._context.CurrentIdentity.Phone == ''
			) {
				this.Dialog = true;
			} else {
				let client = await this._marketService.GetClientAsync(this._context.CurrentIdentity.Phone);
				if (!client.Success) this._notificationService.Error('Ошибка', client.ErrorMessage ? client.ErrorMessage : 'Непредвиденная ошибка');

				if (this.Settings) this.Settings.CurrentMarketClient = client.Data;
			}
		}

		this._context.IdentityChanged.Subscribe(async (sender, e) => {
			if (e.Identity.Phone == undefined || e.Identity.Phone == null || e.Identity.Phone == '') {
				this.Dialog = true;
				return;
			}

			let client = await this._marketService.GetClientAsync(e.Identity.Phone);
			if (!client.Success) this._notificationService.Error('Ошибка', client.ErrorMessage ? client.ErrorMessage : 'Непредвиденная ошибка');

			if (this.Settings) this.Settings.CurrentMarketClient = client.Data;

			if (this.SelectedItemId !== '') this._notificationService.SendSystemNotification('MarketClientGetted', this.SelectedItemId);
		});
	}

	async CodeConfirmed(code: any): Promise<void> {
		if (this._context.CurrentIdentity) this._context.CurrentIdentity.Phone = code.Phone;

		let userProfile = new UserProfile();
		userProfile = code.Phone;
		let service = this.GlobalService.Get<IProfileService>(ProfileSymbols.ProfileService);
		let savingResult = await service.EditProfileAsync(userProfile);

		if (!savingResult.Success)
			this._notificationService.Error('Ошибка', savingResult.ErrorMessage ? savingResult.ErrorMessage : 'Непредвиденная ошибка');

		let client = await this._marketService.GetClientAsync(code.Phone);
		if (!client.Success) this._notificationService.Error('Ошибка', client.ErrorMessage ? client.ErrorMessage : 'Непредвиденная ошибка');

		if (this.Settings) this.Settings.CurrentMarketClient = client.Data;

		if (this.SelectedItemId !== '') this._notificationService.SendSystemNotification<string>('MarketClientGetted', this.SelectedItemId);
	}

	ItemSelected(id: string) {
		this.SelectedItemId = id;
	}

	async DateSet(date: string): Promise<void> {
		this.Date = date;
		await this.LoadSchedule();
	}

	async LoadSchedule() {
		this.Loading = true;
		this.Items = [];

		for (let i = 0; i < this.MarketRules.length; i++) {
			let x = this.MarketRules[i];

			let tariffService = this.TariffServices?.find((t) => x.Service != null && x.Service.length > 0 && t.id == x.Service[0]?.id);
			if (
				this.Settings?.MarketEmployeeId != null &&
				tariffService != null &&
				(tariffService.User == null || !tariffService.User.some((x) => x.id == this.Settings?.MarketEmployeeId))
			) {
				continue;
			}

			let resultSchedule = await this._marketService.GetRuleScheduleAsync(x.id, this.Date);

			if (resultSchedule.Success && resultSchedule.Data) {
				resultSchedule.Data.AllowedDateTimeRange.forEach((schedule) => {
					this.Items.push({
						id: `${x.id}_${schedule.StartDateTime[0]}_${schedule.EndDateTime[0]}`,
						Data: x,
						TariffService: tariffService,
						Schedule: schedule,
					});
				});
			}
		}
		this.Loading = false;
	}

	async CartTimeout() {
		this.Cart.OrderId = undefined;
		this.Cart.Clear();
	}
}

class MarketRuleItem {
	id: string = '';
	Data?: MarketRule;
	TariffService?: MarketTariffService;
	Schedule?: MarketDateTimeRange;
}

export class WpMarketRulesDictionary extends BaseSystemLocalizationDictionary {
	NoServicesAvailableOnThisDate: string = 'Нет доступных услуг на эту дату';
	DataRequired: string = 'Необходимо заполнить данные';
}
