import './wp-order.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { OrderDetails, IOrderService, OrderServiceSymbols, IMetricsService, MetricsSymbols } from 'web-platform-core-ui';
import { MetricsEvents } from '../metrics-events-symbols';

export const wpOrderItemProps = Vue.extend({
	props: {
		OrderId: [String, Number],
	},
});

@Component({
	template: require('./wp-order.html'),
})
export class WpOrder extends mixins(WpPageBaseComponent, wpOrderItemProps, Vue) {
	_metricsService!: IMetricsService;
	Order: OrderDetails = new OrderDetails();
	Dialog: boolean = false;
	OrderItemsHeaders: Array<string> = ['Описание', 'Сумма', 'НДС'];
	OrderHistoryHeaders: Array<string> = ['Операция', 'Дата', 'Результат', 'Описание'];

	BarcodeType: string = 'qrcode';
	get BarcodeStyle() {
		return this.BarcodeType != 'qrcode' ? 'code128-img' : '';
	}

	BarcodeTypeChange(e: boolean) {
		if (e) {
			this.BarcodeType = 'code128b';
		} else {
			this.BarcodeType = 'qrcode';
		}
	}

	get HaveTickets() {
		return this.Order.Items?.some((x) => x.Barcode != null && x.Barcode != '');
	}

	async mounted() {
		this.PageLoading = true;
		let service = this.GlobalService.Get<IOrderService>(OrderServiceSymbols.OrderService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);

		let result = await service.GetOrderAsync(Number.parseInt(this.$props.OrderId));

		if (result.Success && result.Data != null) {
			this.Order = result.Data;
		}

		this._metricsService.RegistrateEvent(MetricsEvents.OrderDetalization, this.$props.OrderId);
		this.PageLoading = false;
	}

	FormatStatus(Status: number): string {
		return Object.values(WpOrder.OrderStatus)[Status - 1];
	}
	FormatTax(TaxType: number): string {
		return Object.values(WpOrder.TaxType)[TaxType - 1];
	}
	FormatTransactionResult(TransactionResult: number): string {
		return Object.values(WpOrder.TransactionResult)[TransactionResult];
	}
	FormatPrice(Price: number): string {
		return parseFloat(Price.toString()).toFixed(2).toString().replace(',', '.');
	}
	FormatDate(Date: string): string {
		return this.DateHandler.Parse(Date).Format('DD.MM.YYYY');
	}
	FormatDateTime(d: string): string {
		return this.DateHandler.Parse(new Date(d)).Format('DD.MM.YYYY HH:mm');
	}

	TicketsOpen() {
		this.Dialog = true;
		this._metricsService.RegistrateEvent(MetricsEvents.OrderTickets, this.$props.OrderId);
	}
}

export namespace WpOrder {
	export enum OrderStatus {
		Reserved = 'Зарезирвирован',
		Locked = 'Заблокирован',
		Paid = 'Оплачен',
		Canceled = 'Отменён',
		Refunded = 'Возврат',
		Error = 'Ошибка',
		PartialRefund = 'Частичный возврат',
	}

	export enum TaxType {
		None = 'Нет НДС',
		VAT_0 = 'НДС 0%',
		VAT_10 = 'НДС 10%',
		VAT_18 = 'НДС 18%',
		VAT_10_110 = 'НДС 10/110',
		VAT_18_118 = 'НДС 18/118',
		VAT_20 = 'НДС 20%',
		VAT_20_120 = 'НДС 20/120',
	}

	export enum TransactionResult {
		Failed = 'Отказ',
		Success = 'Успех',
	}
}
export default WpOrder;
